import { GetApiMarketerListApiResponse } from "src/app/services/api.generated";
import { ColumnModes, ColumnType } from "src/types/genericTable.types";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { ActionButtonModes } from "src/types/actionButton.types";
import { ActionModes } from "src/types/actionItems.types";
import { RejectConfirmDialog } from "src/components/molecule/dialogs/RejectConfirmDialog";
import { CommissionDialog } from "src/components/molecule/dialogs/CommissionDialog";

const colors = new Map<string, string>([
  ["تائید", "#00c853"],
  ["عدم تائید", "#ef5350"],
]);

export const marketersColumns: ColumnType<
  GetApiMarketerListApiResponse[number]
>[] = [
  {
    type: ColumnModes.DATA,
    id: "firstName",
    label: "نام",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "lastName",
    label: "نام خانوادگی",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "nationalId",
    label: "کد ملی",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "state",
    label: "استان",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "city",
    label: "شهر",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "businessPhone",
    label: "تلفن",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "marketerRequestStatus",
    label: "وضعیت",
    chip: true,
    color: colors,
  },
  {
    type: ColumnModes.ACTION,
    label: "عملیات",
    actions: [
      {
        icon: MoreVertIcon,
        type: ActionButtonModes.DROPDOWN,
        items: [
          {
            label: "رد/تایید",
            type: ActionModes.DIALOG,
            props: ["id"],
            dialogComponent: RejectConfirmDialog,
          },
          {
            label: "کمیسیون",
            type: ActionModes.DIALOG,
            props: ["id"],
            dialogComponent: CommissionDialog,
          },
        ],
      },
    ],
  },
];
