import type { FC } from "react";
import { CircularProgress, SxProps, Theme } from "@mui/material";
import { Stack } from "@mui/material";

const wrapperFullScreenStyle: SxProps<Theme> = {
  position: "absolute",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  backdropFilter: "blur(7px)",
};
const wrapperStyle: SxProps<Theme> = {};

type LoadingPropsType = {
  fullScreen?: boolean;
};

const Loading: FC<LoadingPropsType> = ({ fullScreen = true }) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={fullScreen ? wrapperFullScreenStyle : wrapperStyle}
    >
      <CircularProgress />
    </Stack>
  );
};

export default Loading;
