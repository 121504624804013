import type { FC, MouseEvent } from "react";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { ColumnModes, ColumnType } from "src/types/genericTable.types";

type Order = "asc" | "desc";

interface EnhancedTableProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof any) => void;
  order: Order;
  orderBy: string;
  columns: ColumnType<any>[];
}

const EnhancedTableHead: FC<EnhancedTableProps> = ({
  order,
  orderBy,
  onRequestSort,
  columns,
}) => {
  const createSortHandler =
    (property: keyof any) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => {
          return (
            <TableCell
              align="left"
              key={index}
              padding="normal"
              sortDirection={
                column.type === ColumnModes.DATA && orderBy === column.id
                  ? order
                  : false
              }
            >
              {column.type === ColumnModes.DATA && (
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : "asc"}
                  onClick={createSortHandler(column.id)}
                  sx={{
                    color: "rgba(0,0,0,.6)",
                    fontSize: "0.75rem",
                    "&:hover": { color: "black" },
                    width: "100%",
                  }}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
              {column.type === ColumnModes.ACTION && (
                <Box
                  sx={{
                    color: "rgba(0,0,0,.6)",
                    fontSize: "0.75rem",
                    "&:hover": { color: "black" },
                    width: "100%",
                  }}
                >
                  {column.label}
                </Box>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
