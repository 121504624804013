import type { FC } from "react";
import { useState } from "react";
import { Box, Container, Toolbar } from "@mui/material";
import TemporaryDrawer from "src/components/organization/layout/sidebar/TemporaryDrawer";
import Navbar from "src/components/organization/layout/navbar/Navbar";

type MainTemplatePropsType = {
  children?: any;
};

export const drawerWidth = 240;

const MainTemplate: FC<MainTemplatePropsType> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Navbar handleDrawerToggle={handleDrawerToggle} />
      <TemporaryDrawer
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
      />
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          ml: { lg: mobileOpen ? `${drawerWidth}px` : 0 },
          overflow: "hidden",
        }}>
        <Toolbar />
        <Container sx={{ pt: 2, pb: 3 }}>{children}</Container>
      </Box>
    </Box>
  );
};

export default MainTemplate;
