import type { FC } from "react";
import { useGetApiMarketerListQuery } from "src/app/services/api.generated";
import TableSkeletonRow from "src/components/molecule/TableSkeletonRow";
import DataTable from "src/components/organization/DataTable";
import { marketersColumns } from "src/sampleData/marketers";
import BreadCrumbs from "../components/organization/layout/breadcrumbs/BreadCrumbs";

const Marketers: FC = () => {
  const { data, isLoading } = useGetApiMarketerListQuery();

  if (isLoading) {
    return <TableSkeletonRow title='بازاریابان' cellCount={7} />;
  } else {
    return (
      <>
        <BreadCrumbs />
        <DataTable
          rows={data || []}
          columns={marketersColumns}
          title="بازاریابان"
        />
      </>
    );
  }
};

export default Marketers;
