import type { FC, Dispatch, SetStateAction } from "react";
import { useState, useCallback } from "react";
import { Delete, InsertDriveFile } from "@mui/icons-material";
import { Stack, Box, IconButton, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import uploadSvg from "src/assets/icons/upload.png";
import { grey } from "@mui/material/colors";

const supportedSize = 500;

type DropzonePropsType = {
  onChange: Dispatch<SetStateAction<any>>;
};

const Dropzone: FC<DropzonePropsType> = ({ onChange }) => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setSelectedFiles(acceptedFiles);
      onChange(acceptedFiles[0]);
    },
    [onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    // accept: { "application/pdf": [".pdf"] },
    maxSize: 1024 * 1024 * 0.5,
  });

  const removeFileHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedIndex: number
  ) => {
    e.stopPropagation();
    setSelectedFiles((prevState) =>
      prevState.filter((_, index) => index !== selectedIndex)
    );
    onChange(null);
  };

  const dropzoneWithContent = (
    <Stack direction="column">
      {selectedFiles.map(({ name }, index) => (
        <div key={index}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" columnGap={1} alignItems="center">
              <InsertDriveFile />
              <Typography>{name}‍</Typography>
            </Stack>
            <IconButton onClick={(e) => removeFileHandler(e, index)}>
              <Delete color="error" />
            </IconButton>
          </Stack>
          {index < selectedFiles.length - 1 && (
            <Box
              sx={{
                width: "100%",
                height: "1px",
                border: "none",
                borderTop: "1px dashed rgba(0, 0, 0, 0.5)",
              }}
            />
          )}
        </div>
      ))}
    </Stack>
  );

  const dropzoneWithoutContent = (
    <Stack justifyContent="center" alignItems="center" direction="column">
      <img src={uploadSvg} alt="upload_dropzone" />
      <Typography
        sx={{ color: grey[400] }}
        fontWeight="bold"
        align="center"
        lineHeight="2rem"
      >
        فایل گواهی خود را در اینجا آپلود کنید
      </Typography>
      <br />
      <Typography fontWeight="bold" align="center" sx={{ color: grey[400] }}>
        Supported file size is less than {supportedSize} KB
      </Typography>
    </Stack>
  );

  return (
    <Box
      sx={{
        width: "100%",
        border: "1px dashed rgba(0, 0, 0, 0.5)",
        borderRadius: ({ spacing }) => spacing(1),
        padding: ({ spacing }) => spacing(4, 2),
        cursor: "pointer",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {selectedFiles.length > 0 ? dropzoneWithContent : dropzoneWithoutContent}
    </Box>
  );
};

export default Dropzone;
