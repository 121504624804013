import type { FC } from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

export const LockSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      sx={{ fill: "none" }}
      {...props}
    >
      <g clipPath="url(#clip0_394_1154)">
        <path
          opacity="0.5"
          d="M12.5 6H12V4C12 1.794 10.206 0 8 0C5.794 0 4 1.794 4 4V6H3.5C2.67333 6 2 6.67267 2 7.5V14.5C2 15.3273 2.67333 16 3.5 16H12.5C13.3267 16 14 15.3273 14 14.5V7.5C14 6.67267 13.3267 6 12.5 6ZM5.33333 4C5.33333 2.52933 6.52933 1.33333 8 1.33333C9.47067 1.33333 10.6667 2.52933 10.6667 4V6H5.33333V4ZM8.66667 11.148V12.6667C8.66667 13.0347 8.36867 13.3333 8 13.3333C7.63133 13.3333 7.33333 13.0347 7.33333 12.6667V11.148C6.93667 10.9167 6.66667 10.4913 6.66667 10C6.66667 9.26467 7.26467 8.66667 8 8.66667C8.73533 8.66667 9.33333 9.26467 9.33333 10C9.33333 10.4913 9.06333 10.9167 8.66667 11.148Z"
          fill="#403845"
        />
      </g>
      <defs>
        <clipPath id="clip0_394_1154">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
