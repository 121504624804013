import { GetApiWalletListApiResponse } from "src/app/services/api.generated";
import { ColumnModes, ColumnType } from "src/types/genericTable.types";

export const walletsColumns: ColumnType<GetApiWalletListApiResponse[number]>[] =
  [
    {
      type: ColumnModes.DATA,
      id: "id",
      label: "شماره",
      chip: false,
      color: "",
    },
    {
      type: ColumnModes.DATA,
      id: "user",
      label: "نام کاربر",
      chip: false,
      color: "",
    },
    {
      type: ColumnModes.DATA,
      id: "balance",
      label: "مانده",
      chip: false,
      color: "",
      isPrice: true,
    },
    {
      type: ColumnModes.DATA,
      id: "createDate",
      label: "تاریخ ایجاد",
      chip: false,
      color: "",
    },
    {
      type: ColumnModes.ACTION,
      label: "عملیات",
      actions: [],
    },
  ];
