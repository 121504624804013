import { FC, useState } from "react";
import { Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import GenericForm from "../../organization/GenericForm";
import fields from "src/sampleData/dialogs/ResetPassword";
import { FormButtonType } from "src/types/genericFrom.types";
import { usePutApiUserResetPasswordMutation } from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { BaseDialogType } from "src/types/dialog.types";

type ResetPasswordPropsType = {
  id: string;
};

const ResetPassword: FC<ResetPasswordPropsType & BaseDialogType> = ({
  id,
  open,
  handleClose,
}) => {
  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
  });
  const [resetPassword, { isLoading }] = usePutApiUserResetPasswordMutation();

  const onSubmit = () => {
    if (state.password !== state.confirmPassword) {
      toast.error("رمزهای وارد شده مشابه نیستند.");
      return;
    }
    resetPassword({
      resetPasswordModel: {
        id,
        ...state,
      },
    })
      .unwrap()
      .then((res) => {
        toast.success("رمز عبور با موفقیت تغییر کرد.");
        handleClose();
      })
  };

  const buttons: FormButtonType[] = [
    {
      onClick: handleClose,
      text: "انصراف",
      variant: "outlined",
      color: "primary",
      checkRequiredField: false,
    },
    {
      onClick: onSubmit,
      text: "ذخیره",
      variant: "contained",
      color: "primary",
      checkRequiredField: true,
      isLoading: isLoading,
    },
  ];

  return (
    <Dialog
      maxWidth="xs"
      PaperProps={{
        sx: { width: "100%" },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>بازنشانی رمز عبور</DialogTitle>
      <DialogContent>
        <Box sx={{ paddingTop: 1 }}>
          <GenericForm
            state={state}
            setState={setState}
            fields={fields}
            buttons={buttons}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPassword;
