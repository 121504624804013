import type { FC } from "react";
import { Breadcrumbs, SxProps, Theme, Typography } from "@mui/material";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useAppSelector } from "src/app/reduxCustomHooks";
import { grey } from "@mui/material/colors";

const pathNames: any = {
  "/": "خانه",
  orders: "سفارشات",
  products: "محصولات",
  users: "کاربران",
  marketers: "بازاریابان",
  support: "پشتیبانی",
  invoice: "گزارش فاکتورها",
  walletWithdraw: "مدیریت عودت وجه",
  walletDeposit: "مدیریت واریز وجه",
  walletTransaction: "گزارش کیف پول",
  wallet: "کیف پول",
  payment: "گزارش پرداخت آنلاین",
  supportSubject: "موضوعات پشتیبانی",
  changePassword: "تغییر گذرواژه",
  profile: "حساب کاربری",
  notification: "اعلان‌ها",
  orderDetails: "جزئیات سفارش",
};

type previousPathPropsType = {
  title: string;
  href: string;
  index?: number;
};

const previousPath = ({ title, href, index }: previousPathPropsType) => (
  <Link to={href} key={index} style={{ textDecoration: "none" }}>
    <Typography color="primary" fontWeight={600}>
      {title}
    </Typography>
  </Link>
);

const lastPath = ({ title, index }: previousPathPropsType) => (
  <Typography key={index} color={grey[400]} sx={{ cursor: "default" }}>
    {title}
  </Typography>
);
type BreadCrumbsPropsType = {
  sx?: SxProps<Theme>;
  spaceBottom?: boolean;
};

const BreadCrumbs: FC<BreadCrumbsPropsType> = ({ sx, spaceBottom = true }) => {
  const { pathname } = useLocation(),
    auth = useAppSelector((state) => state.auth);

  let showItems = [{ title: "صفحه اصلی", href: "/" }];
  if (pathname === "/") {
    showItems = [{ title: "خوش آمدید " + auth?.userTitle, href: "/" }];
  } else {
    let paths = "";
    pathname
      .replace("/", "")
      .split("/")
      .forEach((path) => {
        paths += `/${path}`;
        showItems.push({ title: pathNames[path] || path, href: paths });
      });
  }

  return (
    <Breadcrumbs
      sx={{ mb: spaceBottom ? 2 : undefined, ...sx }}
      separator={<Typography color={grey[400]}>/</Typography>}
    >
      {showItems.map(({ title, href }, index) => {
        if (showItems.length === 1) {
          return lastPath({ title, href, index });
        } else {
          if (index === showItems.length - 1) {
            return lastPath({ title, href, index });
          } else {
            return previousPath({ title, href, index });
          }
        }
      })}
    </Breadcrumbs>
  );
};

export default BreadCrumbs;
