import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { useLazyGetApiOrderDownloadByOrderIdQuery } from "src/app/services/api";
import {
  ActionModes,
  DownloadApisEnum,
  DropdownActionItemType,
} from "src/types/actionItems.types";
import Loading from "./Loading";

export type downloadType = {
  file: string;
  extension: string;
};

type ActionItemPropsType = {
  ItemComponent: FC;
  itemComponentProps?: any;
  item: DropdownActionItemType<any>;
  row: {
    [x: string]: string | number;
  };
};

export const ActionItem: FC<ActionItemPropsType> = ({
  ItemComponent,
  itemComponentProps,
  item,
  row,
}) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [getFile, { isLoading }] = useLazyGetApiOrderDownloadByOrderIdQuery();

  const props = Object.keys(row)
    .filter((key) => item.props.includes(key))
    .reduce((obj, key) => {
      obj[key] = row[key];
      return obj;
    }, {} as any);

  const downloadFile = ({ file, extension }: downloadType) => {
    const linkSource = `data:application/${extension};base64,${file}`;
    const downloadLink = document.createElement("a");
    const fileName = `myCertificate.${extension}`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadHandler = () => {
    if ((item as any).api === DownloadApisEnum.DOWNLOAD_INSURANCE_POLICY) {
      getFile({ orderId: Number(props.id) })
        .unwrap()
        .then((res) => {
          if (res && res.file) {
            downloadFile(res as downloadType);
          }
        });
    }
  };

  const itemOnClickHandler = () => {
    switch (item.type) {
      case ActionModes.REDIRECT:
        navigate(item.to(props));
        return;
      case ActionModes.DOWNLOAD:
        downloadHandler();
        return;
      case ActionModes.DIALOG:
        setOpen(true);
        return;
      default:
        return;
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      <ItemComponent
        sx={{ px: 0.5, color: item.color }}
        {...itemComponentProps}
        onClick={itemOnClickHandler}
      >
        {item.icon && <item.icon sx={{ color: `${item.color}` }} />}
        {item.label}
      </ItemComponent>
      {item.type === ActionModes.DIALOG && open && (
        <item.dialogComponent
          open={open}
          handleClose={() => setOpen(false)}
          {...props}
        />
      )}
    </>
  );
};
