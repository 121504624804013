import { FC, useMemo, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
  IconButton,
} from "@mui/material";
import GenericForm from "../../organization/GenericForm";
import fields from "src/sampleData/dialogs/EditUserInformation";
import { FieldType, FormButtonType } from "src/types/genericFrom.types";
import {
  useGetApiInsuranceCompanyListQuery,
  useGetApiRoleListQuery,
  usePostApiUserCreateMutation,
} from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { listItemsEnhancer } from "src/utils/ListItemsEnhancer";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const AddUserDialog: FC<DialogProps> = (props) => {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    nationalId: "",
    birthDate: "",
    phoneNumber: "",
    email: "",
    password: "",
    roleId: "",
    insuranceCompanyId: "",
    allowedIp: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const { data: roleList } = useGetApiRoleListQuery();
  const { data: insuranceCompanyList } = useGetApiInsuranceCompanyListQuery();

  const [createUser, { isLoading: createUserLoading }] =
    usePostApiUserCreateMutation();

  const onSubmit = () => {
    const {
      firstName,
      lastName,
      nationalId,
      birthDate,
      phoneNumber,
      email,
      password,
      roleId,
      insuranceCompanyId,
      allowedIp,
    } = state;

    createUser({
      createUserModel: {
        firstName,
        lastName,
        nationalId,
        birthDate,
        phoneNumber,
        email,
        password,
        roleId: Number(roleId),
        insuranceCompanyId: Number(insuranceCompanyId),
        allowedIp,
      },
    })
      .unwrap()
      .then(() => {
        toast.success("کاربر جدید با موفقیت ایجاد شد");
        props.onClose!({}, "escapeKeyDown");
      })
  };

  const buttons: FormButtonType[] = [
    {
      onClick: () => props.onClose!({}, "escapeKeyDown"),
      text: "انصراف",
      variant: "outlined",
      color: "primary",
      checkRequiredField: false,
    },
    {
      onClick: onSubmit,
      text: "ایجاد",
      variant: "contained",
      color: "primary",
      checkRequiredField: true,
      isLoading: createUserLoading,
    },
  ];

  const enhancedFields = useMemo(() => {
    const passwordField: FieldType = {
      component: "Text",
      name: "password",
      props: {
        label: "گذرواژه",
        required: true,
        width: "half",
        type: showPassword ? "text" : "password",
        InputProps: {
          endAdornment: (
            <IconButton
              onClick={() => setShowPassword((prevState) => !prevState)}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          ),
        },
      },
    };

    const insuranceCompanyIdField: FieldType = {
      component: "DropDown",
      name: "insuranceCompanyId",
      props: {
        label: "شرکت بیمه",
        required: true,
        items: listItemsEnhancer(insuranceCompanyList) || [],
        width: "half",
      },
    };

    let result = [...fields];
    result.forEach((item) => {
      if (item.name === "roleId" && roleList) {
        item.props.items = listItemsEnhancer(roleList);
      }
    });
    if (state.roleId === "4") {
      result.splice(fields.length - 1, 0, insuranceCompanyIdField);
    }
    result.splice(fields.length - 1, 0, passwordField);
    return result;
  }, [insuranceCompanyList, roleList, showPassword, state.roleId]);

  return (
    <Dialog {...props}>
      <DialogTitle>ایجاد کاربر جدید</DialogTitle>
      <DialogContent>
        <Box sx={{ paddingTop: 1 }}>
          <GenericForm
            state={state}
            setState={setState}
            fields={enhancedFields}
            buttons={buttons}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
