import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "src/app/services/baseQuery";
export const api = createApi({
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (build) => ({
    postApiAccountLogin: build.mutation<
      PostApiAccountLoginApiResponse,
      PostApiAccountLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/account/login`,
        method: "POST",
        body: queryArg.loginModel,
      }),
    }),
    postApiAccountLogout: build.mutation<
      PostApiAccountLogoutApiResponse,
      PostApiAccountLogoutApiArg
    >({
      query: () => ({ url: `/api/account/logout`, method: "POST" }),
    }),
    getApiAgeRangeGenerateLinkForCompanyCategoryByCategoryIdAndInsuranceCompanyId:
      build.query<
        GetApiAgeRangeGenerateLinkForCompanyCategoryByCategoryIdAndInsuranceCompanyIdApiResponse,
        GetApiAgeRangeGenerateLinkForCompanyCategoryByCategoryIdAndInsuranceCompanyIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/age-range/generate-link-for-company-category/${queryArg.categoryId}/${queryArg.insuranceCompanyId}`,
        }),
      }),
    getApiAgeRangeList: build.query<
      GetApiAgeRangeListApiResponse,
      GetApiAgeRangeListApiArg
    >({
      query: () => ({ url: `/api/age-range/list` }),
    }),
    getApiBaseInsurerList: build.query<
      GetApiBaseInsurerListApiResponse,
      GetApiBaseInsurerListApiArg
    >({
      query: () => ({ url: `/api/base-insurer/list` }),
    }),
    getApiBusinessUnitList: build.query<
      GetApiBusinessUnitListApiResponse,
      GetApiBusinessUnitListApiArg
    >({
      query: () => ({ url: `/api/business-unit/list` }),
    }),
    getApiCityByStateIdList: build.query<
      GetApiCityByStateIdListApiResponse,
      GetApiCityByStateIdListApiArg
    >({
      query: (queryArg) => ({ url: `/api/city/${queryArg.stateId}/list` }),
    }),
    getApiGatewayGetByOrderId: build.query<
      GetApiGatewayGetByOrderIdApiResponse,
      GetApiGatewayGetByOrderIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/gateway/get/${queryArg.orderId}` }),
    }),
    postApiGatewayOrder: build.mutation<
      PostApiGatewayOrderApiResponse,
      PostApiGatewayOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/gateway/order`,
        method: "POST",
        body: queryArg.createGatewayOrderModel,
      }),
    }),
    postApiGatewayConfirmByOrderId: build.mutation<
      PostApiGatewayConfirmByOrderIdApiResponse,
      PostApiGatewayConfirmByOrderIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/gateway/confirm/${queryArg.orderId}`,
        method: "POST",
      }),
    }),
    postApiGatewayDownload: build.mutation<
      PostApiGatewayDownloadApiResponse,
      PostApiGatewayDownloadApiArg
    >({
      query: () => ({ url: `/api/gateway/download`, method: "POST" }),
    }),
    getApiGatewaySalesPlan: build.query<
      GetApiGatewaySalesPlanApiResponse,
      GetApiGatewaySalesPlanApiArg
    >({
      query: () => ({ url: `/api/gateway/sales-plan` }),
    }),
    postApiGatewayCalculate: build.mutation<
      PostApiGatewayCalculateApiResponse,
      PostApiGatewayCalculateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/gateway/calculate`,
        method: "POST",
        body: queryArg.calculateGatewayModel,
      }),
    }),
    get: build.query<GetApiResponse, GetApiArg>({
      query: () => ({ url: `/` }),
    }),
    getApiInsuranceCompanyList: build.query<
      GetApiInsuranceCompanyListApiResponse,
      GetApiInsuranceCompanyListApiArg
    >({
      query: () => ({ url: `/api/insurance-company/list` }),
    }),
    getApiInsuranceDurationsList: build.query<
      GetApiInsuranceDurationsListApiResponse,
      GetApiInsuranceDurationsListApiArg
    >({
      query: () => ({ url: `/api/insurance-durations/list` }),
    }),
    getApiInsurerList: build.query<
      GetApiInsurerListApiResponse,
      GetApiInsurerListApiArg
    >({
      query: () => ({ url: `/api/insurer/list` }),
    }),
    postApiInsurerIssueStatusOrder: build.mutation<
      PostApiInsurerIssueStatusOrderApiResponse,
      PostApiInsurerIssueStatusOrderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/insurer/issue-status-order`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getApiInsurerModifyStatusOrderByOrderId: build.query<
      GetApiInsurerModifyStatusOrderByOrderIdApiResponse,
      GetApiInsurerModifyStatusOrderByOrderIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/insurer/modify-status-order/${queryArg.orderId}`,
      }),
    }),
    getApiInvoiceListByUserIdAndInvoiceStatusId: build.query<
      GetApiInvoiceListByUserIdAndInvoiceStatusIdApiResponse,
      GetApiInvoiceListByUserIdAndInvoiceStatusIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/invoice/list/${queryArg.userId}/${queryArg.invoiceStatusId}`,
      }),
    }),
    getApiInvoiceGetById: build.query<
      GetApiInvoiceGetByIdApiResponse,
      GetApiInvoiceGetByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/invoice/get/${queryArg.id}` }),
    }),
    getApiMarketerList: build.query<
      GetApiMarketerListApiResponse,
      GetApiMarketerListApiArg
    >({
      query: () => ({ url: `/api/marketer/list` }),
    }),
    getApiMarketerGetByMarketerId: build.query<
      GetApiMarketerGetByMarketerIdApiResponse,
      GetApiMarketerGetByMarketerIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/marketer/get/${queryArg.marketerId}`,
      }),
    }),
    getApiMarketerConfirmByMarketerId: build.query<
      GetApiMarketerConfirmByMarketerIdApiResponse,
      GetApiMarketerConfirmByMarketerIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/marketer/confirm/${queryArg.marketerId}`,
      }),
    }),
    getApiMarketerRejectByMarketerIdAndReason: build.query<
      GetApiMarketerRejectByMarketerIdAndReasonApiResponse,
      GetApiMarketerRejectByMarketerIdAndReasonApiArg
    >({
      query: (queryArg) => ({
        url: `/api/marketer/reject/${queryArg.marketerId}/${queryArg.reason}`,
      }),
    }),
    getApiMarketerCancelByMarketerIdAndReason: build.query<
      GetApiMarketerCancelByMarketerIdAndReasonApiResponse,
      GetApiMarketerCancelByMarketerIdAndReasonApiArg
    >({
      query: (queryArg) => ({
        url: `/api/marketer/cancel/${queryArg.marketerId}/${queryArg.reason}`,
      }),
    }),
    getApiMarketerGetUserCommissionsByMarketerId: build.query<
      GetApiMarketerGetUserCommissionsByMarketerIdApiResponse,
      GetApiMarketerGetUserCommissionsByMarketerIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/marketer/get-user-commissions/${queryArg.marketerId}`,
      }),
    }),
    postApiMarketerAddUserCommissions: build.mutation<
      PostApiMarketerAddUserCommissionsApiResponse,
      PostApiMarketerAddUserCommissionsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/marketer/add-user-commissions`,
        method: "POST",
        body: queryArg.marketerCommissionModel,
      }),
    }),
    getApiMarketerDisableUserCommissionsByProductIdAndUserCommissionId:
      build.query<
        GetApiMarketerDisableUserCommissionsByProductIdAndUserCommissionIdApiResponse,
        GetApiMarketerDisableUserCommissionsByProductIdAndUserCommissionIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/marketer/disable-user-commissions/${queryArg.productId}/${queryArg.userCommissionId}`,
        }),
      }),
    getApiMarketerActivateUserCommissionsByProductIdAndUserCommissionId:
      build.query<
        GetApiMarketerActivateUserCommissionsByProductIdAndUserCommissionIdApiResponse,
        GetApiMarketerActivateUserCommissionsByProductIdAndUserCommissionIdApiArg
      >({
        query: (queryArg) => ({
          url: `/api/marketer/activate-user-commissions/${queryArg.productId}/${queryArg.userCommissionId}`,
        }),
      }),
    postApiMarketerEditUserCommission: build.mutation<
      PostApiMarketerEditUserCommissionApiResponse,
      PostApiMarketerEditUserCommissionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/marketer/edit-user-commission`,
        method: "POST",
        body: queryArg.editMarketerCommissionModel,
      }),
    }),
    postApiMarketerSendMarketerLink: build.mutation<
      PostApiMarketerSendMarketerLinkApiResponse,
      PostApiMarketerSendMarketerLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/api/marketer/send-marketer-link`,
        method: "POST",
        body: queryArg.sendMarketerLinkModel,
      }),
    }),
    getApiMarketerCommissionList: build.query<
      GetApiMarketerCommissionListApiResponse,
      GetApiMarketerCommissionListApiArg
    >({
      query: () => ({ url: `/api/marketer-commission/list` }),
    }),
    getApiNotificationList: build.query<
      GetApiNotificationListApiResponse,
      GetApiNotificationListApiArg
    >({
      query: () => ({ url: `/api/notification/list` }),
    }),
    getApiNotificationShortList: build.query<
      GetApiNotificationShortListApiResponse,
      GetApiNotificationShortListApiArg
    >({
      query: () => ({ url: `/api/notification/short-list` }),
    }),
    postApiOrderList: build.mutation<
      PostApiOrderListApiResponse,
      PostApiOrderListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/order/list`,
        method: "POST",
        body: queryArg.orderListModel,
      }),
    }),
    getApiOrderOrderStatusList: build.query<
      GetApiOrderOrderStatusListApiResponse,
      GetApiOrderOrderStatusListApiArg
    >({
      query: () => ({ url: `/api/order/order-status-list` }),
    }),
    getApiOrderProductCategoryList: build.query<
      GetApiOrderProductCategoryListApiResponse,
      GetApiOrderProductCategoryListApiArg
    >({
      query: () => ({ url: `/api/order/product-category-list` }),
    }),
    getApiOrderInsuranceCompanyList: build.query<
      GetApiOrderInsuranceCompanyListApiResponse,
      GetApiOrderInsuranceCompanyListApiArg
    >({
      query: () => ({ url: `/api/order/insurance-company-list` }),
    }),
    getApiOrderGetByOrderId: build.query<
      GetApiOrderGetByOrderIdApiResponse,
      GetApiOrderGetByOrderIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/order/get/${queryArg.orderId}` }),
    }),
    getApiOrderGetOrderDetailByOrderId: build.query<
      GetApiOrderGetOrderDetailByOrderIdApiResponse,
      GetApiOrderGetOrderDetailByOrderIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/order/get-order-detail/${queryArg.orderId}`,
      }),
    }),
    getApiOrderDownloadOrderAttachmentByAttachmentId: build.query<
      GetApiOrderDownloadOrderAttachmentByAttachmentIdApiResponse,
      GetApiOrderDownloadOrderAttachmentByAttachmentIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/order/download-order-attachment/${queryArg.attachmentId}`,
      }),
    }),
    postApiOrderVerifyId: build.mutation<
      PostApiOrderVerifyIdApiResponse,
      PostApiOrderVerifyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/order/verify-id`,
        method: "POST",
        body: queryArg.verifyIdModel,
      }),
    }),
    postApiOrderVerifyAddress: build.mutation<
      PostApiOrderVerifyAddressApiResponse,
      PostApiOrderVerifyAddressApiArg
    >({
      query: (queryArg) => ({
        url: `/api/order/verify-address`,
        method: "POST",
        body: queryArg.verifyAddressModel,
      }),
    }),
    postApiOrderVerifyVoucher: build.mutation<
      PostApiOrderVerifyVoucherApiResponse,
      PostApiOrderVerifyVoucherApiArg
    >({
      query: (queryArg) => ({
        url: `/api/order/verify-voucher`,
        method: "POST",
        body: queryArg.verifyVoucherModel,
      }),
    }),
    putApiOrderCancelByOrderId: build.mutation<
      PutApiOrderCancelByOrderIdApiResponse,
      PutApiOrderCancelByOrderIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/order/cancel/${queryArg.orderId}`,
        method: "PUT",
      }),
    }),
    getApiOrderDownloadByOrderId: build.query<
      GetApiOrderDownloadByOrderIdApiResponse,
      GetApiOrderDownloadByOrderIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/order/download/${queryArg.orderId}` }),
    }),
    getApiOrderFireGetByOrderId: build.query<
      GetApiOrderFireGetByOrderIdApiResponse,
      GetApiOrderFireGetByOrderIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/order-fire/get/${queryArg.orderId}` }),
    }),
    postApiOrderFireVerify: build.mutation<
      PostApiOrderFireVerifyApiResponse,
      PostApiOrderFireVerifyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/order-fire/verify`,
        method: "POST",
        body: queryArg.verifyFireModel,
      }),
    }),
    getApiOrderIndividualEventsGetByOrderId: build.query<
      GetApiOrderIndividualEventsGetByOrderIdApiResponse,
      GetApiOrderIndividualEventsGetByOrderIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/order-individual-events/get/${queryArg.orderId}`,
      }),
    }),
    postApiOrderIndividualEventsVerify: build.mutation<
      PostApiOrderIndividualEventsVerifyApiResponse,
      PostApiOrderIndividualEventsVerifyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/order-individual-events/verify`,
        method: "POST",
        body: queryArg.verifyIndividualEventsModel,
      }),
    }),
    getApiOrderIndividualEventsJobs: build.query<
      GetApiOrderIndividualEventsJobsApiResponse,
      GetApiOrderIndividualEventsJobsApiArg
    >({
      query: () => ({ url: `/api/order-individual-events/jobs` }),
    }),
    getGetByOrderId: build.query<
      GetGetByOrderIdApiResponse,
      GetGetByOrderIdApiArg
    >({
      query: (queryArg) => ({ url: `/get/${queryArg.orderId}` }),
    }),
    postCreate: build.mutation<PostCreateApiResponse, PostCreateApiArg>({
      query: (queryArg) => ({
        url: `/create`,
        method: "POST",
        body: queryArg.createOrderModel,
      }),
    }),
    postVerify: build.mutation<PostVerifyApiResponse, PostVerifyApiArg>({
      query: (queryArg) => ({
        url: `/verify`,
        method: "POST",
        body: queryArg.verifyPhoneModel,
      }),
    }),
    getApiPaymentListByUserId: build.query<
      GetApiPaymentListByUserIdApiResponse,
      GetApiPaymentListByUserIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/payment/list/${queryArg.userId}` }),
    }),
    getApiPaymentGetById: build.query<
      GetApiPaymentGetByIdApiResponse,
      GetApiPaymentGetByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/payment/get/${queryArg.id}` }),
    }),
    postApiPdfCertificateTemplateCreate: build.mutation<
      PostApiPdfCertificateTemplateCreateApiResponse,
      PostApiPdfCertificateTemplateCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdf-certificate-template/create`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postApiPdfCertificateTemplateList: build.mutation<
      PostApiPdfCertificateTemplateListApiResponse,
      PostApiPdfCertificateTemplateListApiArg
    >({
      query: () => ({
        url: `/api/pdf-certificate-template/list`,
        method: "POST",
      }),
    }),
    postApiPdfCertificateTemplateGetByPdfCertificateTemplateId: build.mutation<
      PostApiPdfCertificateTemplateGetByPdfCertificateTemplateIdApiResponse,
      PostApiPdfCertificateTemplateGetByPdfCertificateTemplateIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/pdf-certificate-template/get/${queryArg.pdfCertificateTemplateId}`,
        method: "POST",
      }),
    }),
    getApiPhoneBrandList: build.query<
      GetApiPhoneBrandListApiResponse,
      GetApiPhoneBrandListApiArg
    >({
      query: () => ({ url: `/api/phone-brand/list` }),
    }),
    getApiPhoneModelByBrandIdList: build.query<
      GetApiPhoneModelByBrandIdListApiResponse,
      GetApiPhoneModelByBrandIdListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/phone-model/${queryArg.brandId}/list`,
      }),
    }),
    getApiProductList: build.query<
      GetApiProductListApiResponse,
      GetApiProductListApiArg
    >({
      query: () => ({ url: `/api/product/list` }),
    }),
    getApiProductGetById: build.query<
      GetApiProductGetByIdApiResponse,
      GetApiProductGetByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/product/get/${queryArg.id}` }),
    }),
    postApiProductCreate: build.mutation<
      PostApiProductCreateApiResponse,
      PostApiProductCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/product/create`,
        method: "POST",
        body: queryArg.createProductModel,
      }),
    }),
    putApiProductEdit: build.mutation<
      PutApiProductEditApiResponse,
      PutApiProductEditApiArg
    >({
      query: (queryArg) => ({
        url: `/api/product/edit`,
        method: "PUT",
        body: queryArg.editProductModel,
      }),
    }),
    deleteApiProductDeleteById: build.mutation<
      DeleteApiProductDeleteByIdApiResponse,
      DeleteApiProductDeleteByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/product/delete/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiProductCategoryList: build.query<
      GetApiProductCategoryListApiResponse,
      GetApiProductCategoryListApiArg
    >({
      query: () => ({ url: `/api/product-category/list` }),
    }),
    getApiProductItemListByProductId: build.query<
      GetApiProductItemListByProductIdApiResponse,
      GetApiProductItemListByProductIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/product-item/list/${queryArg.productId}`,
      }),
    }),
    deleteApiProductItemDeleteByProductId: build.mutation<
      DeleteApiProductItemDeleteByProductIdApiResponse,
      DeleteApiProductItemDeleteByProductIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/product-item/delete/${queryArg.productId}`,
        method: "DELETE",
      }),
    }),
    postApiProductItemEdit: build.mutation<
      PostApiProductItemEditApiResponse,
      PostApiProductItemEditApiArg
    >({
      query: (queryArg) => ({
        url: `/api/product-item/edit`,
        method: "POST",
        body: queryArg.editProductItemsModel,
      }),
    }),
    getApiProductPriceListByProductId: build.query<
      GetApiProductPriceListByProductIdApiResponse,
      GetApiProductPriceListByProductIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/product-price/list/${queryArg.productId}`,
      }),
    }),
    putApiProductPriceEdit: build.mutation<
      PutApiProductPriceEditApiResponse,
      PutApiProductPriceEditApiArg
    >({
      query: (queryArg) => ({
        url: `/api/product-price/edit`,
        method: "PUT",
        body: queryArg.editPriceModel,
      }),
    }),
    getApiProfileGet: build.query<
      GetApiProfileGetApiResponse,
      GetApiProfileGetApiArg
    >({
      query: () => ({ url: `/api/profile/get` }),
    }),
    putApiProfileEdit: build.mutation<
      PutApiProfileEditApiResponse,
      PutApiProfileEditApiArg
    >({
      query: (queryArg) => ({
        url: `/api/profile/edit`,
        method: "PUT",
        body: queryArg.editModel,
      }),
    }),
    postApiProfileChangePassword: build.mutation<
      PostApiProfileChangePasswordApiResponse,
      PostApiProfileChangePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/profile/change-password`,
        method: "POST",
        body: queryArg.changePasswordModel,
      }),
    }),
    postApiReportList: build.mutation<
      PostApiReportListApiResponse,
      PostApiReportListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/report/list`,
        method: "POST",
        body: queryArg.filterReportModel,
      }),
    }),
    getApiRoleList: build.query<
      GetApiRoleListApiResponse,
      GetApiRoleListApiArg
    >({
      query: () => ({ url: `/api/role/list` }),
    }),
    getApiStateList: build.query<
      GetApiStateListApiResponse,
      GetApiStateListApiArg
    >({
      query: () => ({ url: `/api/state/list` }),
    }),
    getApiSupportListBySupportStatusIdAndUserId: build.query<
      GetApiSupportListBySupportStatusIdAndUserIdApiResponse,
      GetApiSupportListBySupportStatusIdAndUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/support/list/${queryArg.supportStatusId}/${queryArg.userId}`,
      }),
    }),
    postApiSupportCreate: build.mutation<
      PostApiSupportCreateApiResponse,
      PostApiSupportCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/support/create`,
        method: "POST",
        body: queryArg.createSupportModel,
      }),
    }),
    putApiSupportCloseById: build.mutation<
      PutApiSupportCloseByIdApiResponse,
      PutApiSupportCloseByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/support/close/${queryArg.id}`,
        method: "PUT",
      }),
    }),
    getApiSupportItemListBySupportId: build.query<
      GetApiSupportItemListBySupportIdApiResponse,
      GetApiSupportItemListBySupportIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/support-item/list/${queryArg.supportId}`,
      }),
    }),
    postApiSupportItemCreate: build.mutation<
      PostApiSupportItemCreateApiResponse,
      PostApiSupportItemCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/support-item/create`,
        method: "POST",
        body: queryArg.createSupportItemModel,
      }),
    }),
    getApiSupportSubjectList: build.query<
      GetApiSupportSubjectListApiResponse,
      GetApiSupportSubjectListApiArg
    >({
      query: () => ({ url: `/api/support-subject/list` }),
    }),
    getApiSupportSubjectSelectListByBusinessUnitId: build.query<
      GetApiSupportSubjectSelectListByBusinessUnitIdApiResponse,
      GetApiSupportSubjectSelectListByBusinessUnitIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/support-subject/select-list/${queryArg.businessUnitId}`,
      }),
    }),
    postApiSupportSubjectCreate: build.mutation<
      PostApiSupportSubjectCreateApiResponse,
      PostApiSupportSubjectCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/support-subject/create`,
        method: "POST",
        body: queryArg.createSupportSubjectModel,
      }),
    }),
    putApiSupportSubjectEdit: build.mutation<
      PutApiSupportSubjectEditApiResponse,
      PutApiSupportSubjectEditApiArg
    >({
      query: (queryArg) => ({
        url: `/api/support-subject/edit`,
        method: "PUT",
        body: queryArg.editSupportSubjectModel,
      }),
    }),
    deleteApiSupportSubjectDeleteById: build.mutation<
      DeleteApiSupportSubjectDeleteByIdApiResponse,
      DeleteApiSupportSubjectDeleteByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/support-subject/delete/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getApiUserList: build.query<
      GetApiUserListApiResponse,
      GetApiUserListApiArg
    >({
      query: () => ({ url: `/api/user/list` }),
    }),
    getApiUserShortList: build.query<
      GetApiUserShortListApiResponse,
      GetApiUserShortListApiArg
    >({
      query: () => ({ url: `/api/user/short-list` }),
    }),
    getApiUserGetById: build.query<
      GetApiUserGetByIdApiResponse,
      GetApiUserGetByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/user/get/${queryArg.id}` }),
    }),
    postApiUserCreate: build.mutation<
      PostApiUserCreateApiResponse,
      PostApiUserCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/user/create`,
        method: "POST",
        body: queryArg.createUserModel,
      }),
    }),
    putApiUserEdit: build.mutation<
      PutApiUserEditApiResponse,
      PutApiUserEditApiArg
    >({
      query: (queryArg) => ({
        url: `/api/user/edit`,
        method: "PUT",
        body: queryArg.editUserModel,
      }),
    }),
    deleteApiUserDeleteById: build.mutation<
      DeleteApiUserDeleteByIdApiResponse,
      DeleteApiUserDeleteByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/user/delete/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    putApiUserEnableById: build.mutation<
      PutApiUserEnableByIdApiResponse,
      PutApiUserEnableByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/user/enable/${queryArg.id}`,
        method: "PUT",
      }),
    }),
    putApiUserDisableById: build.mutation<
      PutApiUserDisableByIdApiResponse,
      PutApiUserDisableByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/user/disable/${queryArg.id}`,
        method: "PUT",
      }),
    }),
    putApiUserResetPassword: build.mutation<
      PutApiUserResetPasswordApiResponse,
      PutApiUserResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/user/reset-password`,
        method: "PUT",
        body: queryArg.resetPasswordModel,
      }),
    }),
    getApiUserReferralGet: build.query<
      GetApiUserReferralGetApiResponse,
      GetApiUserReferralGetApiArg
    >({
      query: () => ({ url: `/api/user-referral/get` }),
    }),
    getApiUserReferralJoinList: build.query<
      GetApiUserReferralJoinListApiResponse,
      GetApiUserReferralJoinListApiArg
    >({
      query: () => ({ url: `/api/user-referral-join/list` }),
    }),
    postApiVehicleInfoGeneratorAddVehicleInfo: build.mutation<
      PostApiVehicleInfoGeneratorAddVehicleInfoApiResponse,
      PostApiVehicleInfoGeneratorAddVehicleInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/api/vehicle-info-generator/add-vehicle-info`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getApiWalletList: build.query<
      GetApiWalletListApiResponse,
      GetApiWalletListApiArg
    >({
      query: () => ({ url: `/api/wallet/list` }),
    }),
    getApiWalletDepositListByUserId: build.query<
      GetApiWalletDepositListByUserIdApiResponse,
      GetApiWalletDepositListByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/wallet-deposit/list/${queryArg.userId}`,
      }),
    }),
    postApiWalletDepositCreate: build.mutation<
      PostApiWalletDepositCreateApiResponse,
      PostApiWalletDepositCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/wallet-deposit/create`,
        method: "POST",
        body: queryArg.createWalletDepositModel,
      }),
    }),
    getApiWalletTransactionListByUserId: build.query<
      GetApiWalletTransactionListByUserIdApiResponse,
      GetApiWalletTransactionListByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/wallet-transaction/list/${queryArg.userId}`,
      }),
    }),
    getApiWalletWithdrawList: build.query<
      GetApiWalletWithdrawListApiResponse,
      GetApiWalletWithdrawListApiArg
    >({
      query: () => ({ url: `/api/wallet-withdraw/list` }),
    }),
  }),
});
export type PostApiAccountLoginApiResponse =
  /** status 200 Success */ LoginResponse;
export type PostApiAccountLoginApiArg = {
  loginModel: LoginModel;
};
export type PostApiAccountLogoutApiResponse = unknown;
export type PostApiAccountLogoutApiArg = void;
export type GetApiAgeRangeGenerateLinkForCompanyCategoryByCategoryIdAndInsuranceCompanyIdApiResponse =
  /** status 200 Success */ GetAdvertisementResponse;
export type GetApiAgeRangeGenerateLinkForCompanyCategoryByCategoryIdAndInsuranceCompanyIdApiArg =
  {
    categoryId: number;
    insuranceCompanyId: number;
  };
export type GetApiAgeRangeListApiResponse =
  /** status 200 Success */ AgeRangeListResponse[];
export type GetApiAgeRangeListApiArg = void;
export type GetApiBaseInsurerListApiResponse =
  /** status 200 Success */ BaseInsurerListResponse[];
export type GetApiBaseInsurerListApiArg = void;
export type GetApiBusinessUnitListApiResponse =
  /** status 200 Success */ BusinessUnitListResponse[];
export type GetApiBusinessUnitListApiArg = void;
export type GetApiCityByStateIdListApiResponse =
  /** status 200 Success */ CityListResponse[];
export type GetApiCityByStateIdListApiArg = {
  stateId: number;
};
export type GetApiGatewayGetByOrderIdApiResponse =
  /** status 200 Success */ GetGatewayListResponse[];
export type GetApiGatewayGetByOrderIdApiArg = {
  orderId: number;
};
export type PostApiGatewayOrderApiResponse =
  /** status 200 Success */ GatewayOrderListResponse[];
export type PostApiGatewayOrderApiArg = {
  createGatewayOrderModel: CreateGatewayOrderModel;
};
export type PostApiGatewayConfirmByOrderIdApiResponse =
  /** status 200 Success */ GatewayConfirmListResponse[];
export type PostApiGatewayConfirmByOrderIdApiArg = {
  orderId: number;
};
export type PostApiGatewayDownloadApiResponse =
  /** status 200 Success */ GatewayDownloadListResponse[];
export type PostApiGatewayDownloadApiArg = void;
export type GetApiGatewaySalesPlanApiResponse =
  /** status 200 Success */ GatewaySalesPlanListResponse[];
export type GetApiGatewaySalesPlanApiArg = void;
export type PostApiGatewayCalculateApiResponse = unknown;
export type PostApiGatewayCalculateApiArg = {
  calculateGatewayModel: CalculateGatewayModel;
};
export type GetApiResponse = unknown;
export type GetApiArg = void;
export type GetApiInsuranceCompanyListApiResponse =
  /** status 200 Success */ InsuranceCompanyListResponse[];
export type GetApiInsuranceCompanyListApiArg = void;
export type GetApiInsuranceDurationsListApiResponse =
  /** status 200 Success */ InsuranceDurationListResponse[];
export type GetApiInsuranceDurationsListApiArg = void;
export type GetApiInsurerListApiResponse =
  /** status 200 Success */ GetInsurerListResponse[];
export type GetApiInsurerListApiArg = void;
export type PostApiInsurerIssueStatusOrderApiResponse = unknown;
export type PostApiInsurerIssueStatusOrderApiArg = {
  body: {
    OrderId: number;
    CertificateCode: string;
    CertificateFile?: Blob;
  };
};
export type GetApiInsurerModifyStatusOrderByOrderIdApiResponse = unknown;
export type GetApiInsurerModifyStatusOrderByOrderIdApiArg = {
  orderId: number;
};
export type GetApiInvoiceListByUserIdAndInvoiceStatusIdApiResponse =
  /** status 200 Success */ InvoiceListResponse[];
export type GetApiInvoiceListByUserIdAndInvoiceStatusIdApiArg = {
  userId: string;
  invoiceStatusId: number;
};
export type GetApiInvoiceGetByIdApiResponse =
  /** status 200 Success */ GetInvoiceListResponse;
export type GetApiInvoiceGetByIdApiArg = {
  id: number;
};
export type GetApiMarketerListApiResponse =
  /** status 200 Success */ MarketerListResponse[];
export type GetApiMarketerListApiArg = void;
export type GetApiMarketerGetByMarketerIdApiResponse =
  /** status 200 Success */ GetMarketerByIdListResponse[];
export type GetApiMarketerGetByMarketerIdApiArg = {
  marketerId: number;
};
export type GetApiMarketerConfirmByMarketerIdApiResponse = unknown;
export type GetApiMarketerConfirmByMarketerIdApiArg = {
  marketerId: number;
};
export type GetApiMarketerRejectByMarketerIdAndReasonApiResponse = unknown;
export type GetApiMarketerRejectByMarketerIdAndReasonApiArg = {
  marketerId: number;
  reason: string;
};
export type GetApiMarketerCancelByMarketerIdAndReasonApiResponse = unknown;
export type GetApiMarketerCancelByMarketerIdAndReasonApiArg = {
  marketerId: number;
  reason: string;
};
export type GetApiMarketerGetUserCommissionsByMarketerIdApiResponse =
  /** status 200 Success */ MarketerResponse;
export type GetApiMarketerGetUserCommissionsByMarketerIdApiArg = {
  marketerId: number;
};
export type PostApiMarketerAddUserCommissionsApiResponse = unknown;
export type PostApiMarketerAddUserCommissionsApiArg = {
  marketerCommissionModel: MarketerCommissionModel;
};
export type GetApiMarketerDisableUserCommissionsByProductIdAndUserCommissionIdApiResponse =
  unknown;
export type GetApiMarketerDisableUserCommissionsByProductIdAndUserCommissionIdApiArg =
  {
    productId: number;
    userCommissionId: number;
  };
export type GetApiMarketerActivateUserCommissionsByProductIdAndUserCommissionIdApiResponse =
  unknown;
export type GetApiMarketerActivateUserCommissionsByProductIdAndUserCommissionIdApiArg =
  {
    productId: number;
    userCommissionId: number;
  };
export type PostApiMarketerEditUserCommissionApiResponse = unknown;
export type PostApiMarketerEditUserCommissionApiArg = {
  editMarketerCommissionModel: EditMarketerCommissionModel;
};
export type PostApiMarketerSendMarketerLinkApiResponse = unknown;
export type PostApiMarketerSendMarketerLinkApiArg = {
  sendMarketerLinkModel: SendMarketerLinkModel;
};
export type GetApiMarketerCommissionListApiResponse =
  /** status 200 Success */ MarketerCommissionListResponse[];
export type GetApiMarketerCommissionListApiArg = void;
export type GetApiNotificationListApiResponse =
  /** status 200 Success */ NotificationListResponse[];
export type GetApiNotificationListApiArg = void;
export type GetApiNotificationShortListApiResponse =
  /** status 200 Success */ NotificationShortListResponse[];
export type GetApiNotificationShortListApiArg = void;
export type PostApiOrderListApiResponse =
  /** status 200 Success */ OrderListResponse[];
export type PostApiOrderListApiArg = {
  orderListModel: OrderListModel;
};
export type GetApiOrderOrderStatusListApiResponse =
  /** status 200 Success */ OrderStatusListResponse[];
export type GetApiOrderOrderStatusListApiArg = void;
export type GetApiOrderProductCategoryListApiResponse =
  /** status 200 Success */ OrderProductCategoryListResponse[];
export type GetApiOrderProductCategoryListApiArg = void;
export type GetApiOrderInsuranceCompanyListApiResponse =
  /** status 200 Success */ OrderInsurCompanyListResponse[];
export type GetApiOrderInsuranceCompanyListApiArg = void;
export type GetApiOrderGetByOrderIdApiResponse =
  /** status 200 Success */ GetOrdersByIdResponse;
export type GetApiOrderGetByOrderIdApiArg = {
  orderId: number;
};
export type GetApiOrderGetOrderDetailByOrderIdApiResponse = unknown;
export type GetApiOrderGetOrderDetailByOrderIdApiArg = {
  orderId: number;
};
export type GetApiOrderDownloadOrderAttachmentByAttachmentIdApiResponse =
  /** status 200 Success */ GetOrderAttachResponse;
export type GetApiOrderDownloadOrderAttachmentByAttachmentIdApiArg = {
  attachmentId: number;
};
export type PostApiOrderVerifyIdApiResponse = unknown;
export type PostApiOrderVerifyIdApiArg = {
  verifyIdModel: VerifyIdModel;
};
export type PostApiOrderVerifyAddressApiResponse = unknown;
export type PostApiOrderVerifyAddressApiArg = {
  verifyAddressModel: VerifyAddressModel;
};
export type PostApiOrderVerifyVoucherApiResponse = unknown;
export type PostApiOrderVerifyVoucherApiArg = {
  verifyVoucherModel: VerifyVoucherModel;
};
export type PutApiOrderCancelByOrderIdApiResponse = unknown;
export type PutApiOrderCancelByOrderIdApiArg = {
  orderId: number;
};
export type GetApiOrderDownloadByOrderIdApiResponse =
  /** status 200 Success */ GetOrderDownloadResponse;
export type GetApiOrderDownloadByOrderIdApiArg = {
  orderId: number;
};
export type GetApiOrderFireGetByOrderIdApiResponse =
  /** status 200 Success */ FireOrderResponseModel;
export type GetApiOrderFireGetByOrderIdApiArg = {
  orderId: number;
};
export type PostApiOrderFireVerifyApiResponse = unknown;
export type PostApiOrderFireVerifyApiArg = {
  verifyFireModel: VerifyFireModel;
};
export type GetApiOrderIndividualEventsGetByOrderIdApiResponse =
  /** status 200 Success */ IndividualEventsOrderResponseModel;
export type GetApiOrderIndividualEventsGetByOrderIdApiArg = {
  orderId: number;
};
export type PostApiOrderIndividualEventsVerifyApiResponse = unknown;
export type PostApiOrderIndividualEventsVerifyApiArg = {
  verifyIndividualEventsModel: VerifyIndividualEventsModel;
};
export type GetApiOrderIndividualEventsJobsApiResponse =
  /** status 200 Success */ IndividualEventsOrderListResponse[];
export type GetApiOrderIndividualEventsJobsApiArg = void;
export type GetGetByOrderIdApiResponse =
  /** status 200 Success */ OrderPhoneResponseModel;
export type GetGetByOrderIdApiArg = {
  orderId: number;
};
export type PostCreateApiResponse = unknown;
export type PostCreateApiArg = {
  createOrderModel: CreateOrderModel;
};
export type PostVerifyApiResponse = unknown;
export type PostVerifyApiArg = {
  verifyPhoneModel: VerifyPhoneModel;
};
export type GetApiPaymentListByUserIdApiResponse =
  /** status 200 Success */ GetPaymentListResponse[];
export type GetApiPaymentListByUserIdApiArg = {
  userId: string;
};
export type GetApiPaymentGetByIdApiResponse =
  /** status 200 Success */ GetPaymentByIdListResponse[];
export type GetApiPaymentGetByIdApiArg = {
  id: number;
};
export type PostApiPdfCertificateTemplateCreateApiResponse = unknown;
export type PostApiPdfCertificateTemplateCreateApiArg = {
  body: {
    InsuranceCompanyId: number;
    ProductCategoryId: number;
    PdfFile?: Blob;
  };
};
export type PostApiPdfCertificateTemplateListApiResponse =
  /** status 200 Success */ PdfCertificateTemplateListRespnose[];
export type PostApiPdfCertificateTemplateListApiArg = void;
export type PostApiPdfCertificateTemplateGetByPdfCertificateTemplateIdApiResponse =
  /** status 200 Success */ PdfCertificateTemplateListRespnose[];
export type PostApiPdfCertificateTemplateGetByPdfCertificateTemplateIdApiArg = {
  pdfCertificateTemplateId: number;
};
export type GetApiPhoneBrandListApiResponse =
  /** status 200 Success */ GetPhoneBrandListResponse[];
export type GetApiPhoneBrandListApiArg = void;
export type GetApiPhoneModelByBrandIdListApiResponse =
  /** status 200 Success */ GetPhoneModelListResponse[];
export type GetApiPhoneModelByBrandIdListApiArg = {
  brandId: number;
};
export type GetApiProductListApiResponse =
  /** status 200 Success */ GetProductListResponse[];
export type GetApiProductListApiArg = void;
export type GetApiProductGetByIdApiResponse =
  /** status 200 Success */ GetProductByIdResponse[];
export type GetApiProductGetByIdApiArg = {
  id: number;
};
export type PostApiProductCreateApiResponse =
  /** status 200 Success */ CreateProductResponse;
export type PostApiProductCreateApiArg = {
  createProductModel: CreateProductModel;
};
export type PutApiProductEditApiResponse = unknown;
export type PutApiProductEditApiArg = {
  editProductModel: EditProductModel;
};
export type DeleteApiProductDeleteByIdApiResponse = unknown;
export type DeleteApiProductDeleteByIdApiArg = {
  id: number;
};
export type GetApiProductCategoryListApiResponse =
  /** status 200 Success */ GetProductCategoryListResponse[];
export type GetApiProductCategoryListApiArg = void;
export type GetApiProductItemListByProductIdApiResponse =
  /** status 200 Success */ GetProductItemListResponse[];
export type GetApiProductItemListByProductIdApiArg = {
  productId: number;
};
export type DeleteApiProductItemDeleteByProductIdApiResponse = unknown;
export type DeleteApiProductItemDeleteByProductIdApiArg = {
  productId: number;
};
export type PostApiProductItemEditApiResponse = unknown;
export type PostApiProductItemEditApiArg = {
  editProductItemsModel: EditProductItemsModel;
};
export type GetApiProductPriceListByProductIdApiResponse =
  /** status 200 Success */ GetProductPriceListResponse[];
export type GetApiProductPriceListByProductIdApiArg = {
  productId: number;
};
export type PutApiProductPriceEditApiResponse = unknown;
export type PutApiProductPriceEditApiArg = {
  editPriceModel: EditPriceModel;
};
export type GetApiProfileGetApiResponse = {
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  email?: string | null;
  emailConfirmed?: boolean;
  firstName?: string | null;
  lastName?: string | null;
  nationalId?: string | null;
  birthDate?: string | null;
};
/** status 200 Success */
export type GetApiProfileGetApiArg = void;
export type PutApiProfileEditApiResponse = unknown;
export type PutApiProfileEditApiArg = {
  editModel: EditModel;
};
export type PostApiProfileChangePasswordApiResponse = unknown;
export type PostApiProfileChangePasswordApiArg = {
  changePasswordModel: ChangePasswordModel;
};
export type PostApiReportListApiResponse =
  /** status 200 Success */ GetReportResponse;
export type PostApiReportListApiArg = {
  filterReportModel: FilterReportModel;
};
export type GetApiRoleListApiResponse =
  /** status 200 Success */ GetRoleListResponse[];
export type GetApiRoleListApiArg = void;
export type GetApiStateListApiResponse =
  /** status 200 Success */ GetStateListResponse[];
export type GetApiStateListApiArg = void;
export type GetApiSupportListBySupportStatusIdAndUserIdApiResponse =
  /** status 200 Success */ GetSupportListResponse[];
export type GetApiSupportListBySupportStatusIdAndUserIdApiArg = {
  supportStatusId: number;
  userId: string;
};
export type PostApiSupportCreateApiResponse = {
  businessUnitId: number;
  content: string;
  supportSubjectId: number;
  userId: string;
};
export type PostApiSupportCreateApiArg = {
  createSupportModel: CreateSupportModel;
};
export type PutApiSupportCloseByIdApiResponse = unknown;
export type PutApiSupportCloseByIdApiArg = {
  id: number;
};
export type GetApiSupportItemListBySupportIdApiResponse =
  /** status 200 Success */ GetSupportItemListResponse[];
export type GetApiSupportItemListBySupportIdApiArg = {
  supportId: number;
};
export type PostApiSupportItemCreateApiResponse = unknown;
export type PostApiSupportItemCreateApiArg = {
  createSupportItemModel: CreateSupportItemModel;
};
export type GetApiSupportSubjectListApiResponse =
  /** status 200 Success */ GetSupportSubjectListResponse[];
export type GetApiSupportSubjectListApiArg = void;
export type GetApiSupportSubjectSelectListByBusinessUnitIdApiResponse =
  /** status 200 Success */ GetSupportSubjectByIdListResponse[];
export type GetApiSupportSubjectSelectListByBusinessUnitIdApiArg = {
  businessUnitId: number;
};
export type PostApiSupportSubjectCreateApiResponse = unknown;
export type PostApiSupportSubjectCreateApiArg = {
  createSupportSubjectModel: CreateSupportSubjectModel;
};
export type PutApiSupportSubjectEditApiResponse = unknown;
export type PutApiSupportSubjectEditApiArg = {
  editSupportSubjectModel: EditSupportSubjectModel;
};
export type DeleteApiSupportSubjectDeleteByIdApiResponse = unknown;
export type DeleteApiSupportSubjectDeleteByIdApiArg = {
  id: number;
};
export type GetApiUserListApiResponse =
  /** status 200 Success */ GetUserListResponse[];
export type GetApiUserListApiArg = void;
export type GetApiUserShortListApiResponse =
  /** status 200 Success */ GetUserShortListResponse[];
export type GetApiUserShortListApiArg = void;
export type GetApiUserGetByIdApiResponse =
  /** status 200 Success */ GetUserByIdListResponse[];
export type GetApiUserGetByIdApiArg = {
  id: string;
};
export type PostApiUserCreateApiResponse =
  /** status 200 Success */ UserCreateResponse;
export type PostApiUserCreateApiArg = {
  createUserModel: CreateUserModel;
};
export type PutApiUserEditApiResponse = unknown;
export type PutApiUserEditApiArg = {
  editUserModel: EditUserModel;
};
export type DeleteApiUserDeleteByIdApiResponse = unknown;
export type DeleteApiUserDeleteByIdApiArg = {
  id: string;
};
export type PutApiUserEnableByIdApiResponse = unknown;
export type PutApiUserEnableByIdApiArg = {
  id: string;
};
export type PutApiUserDisableByIdApiResponse = unknown;
export type PutApiUserDisableByIdApiArg = {
  id: string;
};
export type PutApiUserResetPasswordApiResponse = unknown;
export type PutApiUserResetPasswordApiArg = {
  resetPasswordModel: ResetPasswordModel;
};
export type GetApiUserReferralGetApiResponse =
  /** status 200 Success */ GetUserReferralResponse;
export type GetApiUserReferralGetApiArg = void;
export type GetApiUserReferralJoinListApiResponse =
  /** status 200 Success */ GetUserReferralJoinListResponse[];
export type GetApiUserReferralJoinListApiArg = void;
export type PostApiVehicleInfoGeneratorAddVehicleInfoApiResponse = unknown;
export type PostApiVehicleInfoGeneratorAddVehicleInfoApiArg = {
  body: VType[];
};
export type GetApiWalletListApiResponse =
  /** status 200 Success */ GetWalletListResponse[];
export type GetApiWalletListApiArg = void;
export type GetApiWalletDepositListByUserIdApiResponse =
  /** status 200 Success */ GetWalletDepositListResponse[];
export type GetApiWalletDepositListByUserIdApiArg = {
  userId: string;
};
export type PostApiWalletDepositCreateApiResponse = unknown;
export type PostApiWalletDepositCreateApiArg = {
  createWalletDepositModel: CreateWalletDepositModel;
};
export type GetApiWalletTransactionListByUserIdApiResponse =
  /** status 200 Success */ GetWalletTransactionListResponse[];
export type GetApiWalletTransactionListByUserIdApiArg = {
  userId: string;
};
export type GetApiWalletWithdrawListApiResponse =
  /** status 200 Success */ GetWalletWithdrawListResponse[];
export type GetApiWalletWithdrawListApiArg = {
  userId: string;
};
export type LoginResponse = {
  accessToken?: string | null;
  expiration?: string;
  userTitle?: string | null;
  roleId?: number;
};
export type LoginModel = {
  email: string;
  password: string;
};
export type GetAdvertisementResponse = {
  link?: string | null;
};
export type AgeRangeListResponse = {
  id?: number;
  text?: string | null;
};
export type BaseInsurerListResponse = {
  id?: number;
  name?: string | null;
};
export type BusinessUnitListResponse = {
  id?: number;
  name?: string | null;
};
export type CityListResponse = {
  id?: number;
  name?: string | null;
};
export type GetGatewayListResponse = {
  id?: number;
};
export type GatewayOrderListResponse = {
  id?: number;
};
export type CreateGatewayOrderModel = {
  productId?: number;
  price?: number;
  brandName?: string | null;
  modelName?: string | null;
  nationalCode?: string | null;
  mobile?: string | null;
  cityId?: number;
  birthDate?: string | null;
  imei?: string | null;
  buyerName?: string | null;
  buyerEmail?: string | null;
  gender?: number;
  address?: string | null;
  fatherName?: string | null;
  postalCode?: string | null;
  identityNo?: string | null;
  issuePlace?: string | null;
};
export type GatewayConfirmListResponse = {
  id?: number;
};
export type GatewayDownloadListResponse = {
  id?: number;
};
export type GatewaySalesPlanListResponse = {
  id?: number;
  name?: string | null;
  description?: string | null;
};
export type CalculateGatewayModel = {
  insuranceType: number;
  insurancePrice: number;
  productId?: number;
};
export type InsuranceCompanyListResponse = {
  id?: number;
  name?: string | null;
};
export type InsuranceDurationListResponse = {
  id?: number;
  text?: string | null;
};
export type AttachmentEntity = {
  attachmentEntityId?: number;
  name?: string | null;
  attachments?: Attachment[] | null;
};
export type Role = {
  roleId?: number;
  name?: string | null;
  users?: User[] | null;
};
export type InvoiceStatus = {
  invoiceStatusId?: number;
  name?: string | null;
  invoices?: Invoice[] | null;
};
export type InvoiceItem = {
  invoiceItemId?: number;
  invoiceId?: number;
  productId?: number;
  quantity?: number;
  price?: number;
  totalPrice?: number;
  invoice?: Invoice;
  product?: Product;
};
export type Invoice = {
  invoiceId?: number;
  userId?: string;
  invoiceStatusId?: number;
  orderId?: number;
  invoiceDate?: string;
  price?: number;
  discount?: number;
  vat?: number;
  invoicePrice?: number;
  certificateNumber?: string | null;
  invoiceStatus?: InvoiceStatus;
  order?: Order;
  user?: User;
  invoiceItems?: InvoiceItem[] | null;
};
export type MarketerCommission = {
  marketerCommissionId?: number;
  userId?: string;
  orderId?: number;
  totalPrice?: number;
  commissionPrice?: number;
  commissionDate?: string;
  order?: Order;
  user?: User;
};
export type SupportSubject = {
  supportSubjectId?: number;
  businessUnitId?: number;
  name?: string | null;
  businessUnit?: BusinessUnit;
  supports?: Support[] | null;
};
export type UserBussinesUnit = {
  userBussinesUnitId?: number;
  userId?: string;
  bussinesUnitId?: number;
  bussinesUnit?: BusinessUnit;
  user?: User;
};
export type BusinessUnit = {
  businessUnitId?: number;
  name?: string | null;
  supportSubjects?: SupportSubject[] | null;
  supports?: Support[] | null;
  userBussinesUnits?: UserBussinesUnit[] | null;
};
export type SupportStatus = {
  supportStatusId?: number;
  name?: string | null;
  supports?: Support[] | null;
};
export type Support = {
  supportId?: number;
  userId?: string;
  businessUnitId?: number;
  supportSubjectId?: number;
  supportStatusId?: number;
  supportDate?: string;
  businessUnit?: BusinessUnit;
  supportStatus?: SupportStatus;
  supportSubject?: SupportSubject;
  user?: User;
  supportItems?: SupportItem[] | null;
};
export type SupportItem = {
  supportItemId?: number;
  userId?: string;
  supportId?: number;
  supportDate?: string;
  content?: string | null;
  support?: Support;
  user?: User;
};
export type UserCommission = {
  userCommissionId?: number;
  userId?: string;
  productId?: number;
  isPercent?: boolean;
  name?: string | null;
  value?: number;
  isDisabled?: boolean;
  product?: Product;
  user?: User;
};
export type UserCompany = {
  userCompanyId?: number;
  userId?: string;
  userCompanyTypeId?: number;
  name?: string | null;
  nationalId?: string | null;
  registrationNumber?: string | null;
  registrationDate?: string | null;
  economicNumber?: string | null;
  businessPhone?: string | null;
  address?: string | null;
  postalCode?: string | null;
  createDate?: string;
  modifyDate?: string;
  user?: User;
};
export type UserInsuranceCompany = {
  userInsuranceCompanyId?: number;
  userId?: string;
  insuranceCompanyId?: number;
  insuranceCompany?: InsuranceCompany;
  user?: User;
};
export type UserReferral = {
  userReferralId?: number;
  userId?: string;
  discountPrice?: number;
  commissionPercent?: number;
  referralCode?: string | null;
  user?: User;
  userReferralJoins?: UserReferralJoin[] | null;
};
export type UserReferralJoin = {
  userReferralJoinId?: number;
  userReferralId?: number;
  userId?: string;
  joinDate?: string;
  user?: User;
  userReferral?: UserReferral;
};
export type PaymentProvider = {
  paymentProviderId?: number;
  name?: string | null;
  payments?: Payment[] | null;
};
export type PaymentStatus = {
  paymentStatusId?: number;
  name?: string | null;
  payments?: Payment[] | null;
};
export type Payment = {
  paymentId?: number;
  walletId?: number;
  orderId?: number | null;
  paymentStatusId?: number;
  paymentProviderId?: number;
  paymentDate?: string;
  amount?: number;
  terminal?: number;
  finalStatus?: boolean;
  phoneNumber?: string | null;
  paymentToken?: string | null;
  rrn?: number | null;
  hashCardNumber?: string | null;
  tokenStatusId?: number | null;
  tokenMessage?: string | null;
  confirmStatusId?: number | null;
  confirmMessage?: string | null;
  reverseStatusId?: number | null;
  reverseMessage?: string | null;
  referenceNumber?: string | null;
  order?: Order;
  paymentProvider?: PaymentProvider;
  paymentStatus?: PaymentStatus;
  wallet?: Wallet;
};
export type WalletDepositStatus = {
  walletDepositStatusId?: number;
  name?: string | null;
  walletDeposits?: WalletDeposit[] | null;
};
export type WalletDepositType = {
  walletDepositTypeId?: number;
  name?: string | null;
  walletDeposits?: WalletDeposit[] | null;
};
export type WalletDeposit = {
  walletDepositId?: number;
  walletId?: number;
  walletDepositStatusId?: number;
  walletDepositTypeId?: number;
  transactionDate?: string;
  amount?: number;
  createDate?: string;
  modifyDate?: string;
  wallet?: Wallet;
  walletDepositStatus?: WalletDepositStatus;
  walletDepositType?: WalletDepositType;
};
export type WalletTransactionType = {
  walletTransactionTypeId?: number;
  name?: string | null;
  isDeposit?: boolean;
  walletTransactions?: WalletTransaction[] | null;
};
export type WalletTransaction = {
  walletTransactionId?: number;
  walletId?: number;
  walletTransactionTypeId?: number;
  transactionDate?: string;
  credit?: number;
  debit?: number;
  balance?: number;
  description?: string | null;
  entityId?: number;
  wallet?: Wallet;
  walletTransactionType?: WalletTransactionType;
};
export type WalletWithdrawStatus = {
  walletWithdrawStatusId?: number;
  name?: string | null;
  walletWithdraws?: WalletWithdraw[] | null;
};
export type WalletWithdraw = {
  walletWithdrawId?: number;
  walletId?: number;
  walletWithdrawStatusId?: number;
  transactionDate?: string;
  amount?: number;
  iban?: string | null;
  createDate?: string;
  modifyDate?: string;
  wallet?: Wallet;
  walletWithdrawStatus?: WalletWithdrawStatus;
};
export type Wallet = {
  walletId?: number;
  userId?: string;
  balance?: number;
  createDate?: string;
  modifyDate?: string;
  user?: User;
  payments?: Payment[] | null;
  walletDeposits?: WalletDeposit[] | null;
  walletTransactions?: WalletTransaction[] | null;
  walletWithdraws?: WalletWithdraw[] | null;
};
export type User = {
  userId?: string;
  phoneNumber?: string | null;
  email?: string | null;
  password?: string | null;
  accessFailedCount?: number;
  lockoutEndDate?: string | null;
  passwordExpirationDate?: string;
  lastLoginDate?: string | null;
  lastLoginIp?: string | null;
  allowedIp?: string | null;
  phoneNumberConfirmed?: boolean;
  phoneNotify?: boolean;
  emailConfirmed?: boolean;
  emailNotify?: boolean;
  isActive?: boolean;
  isCompanyAccount?: boolean;
  firstName?: string | null;
  lastName?: string | null;
  nationalId?: string | null;
  birthDate?: string | null;
  createDate?: string;
  modifyDate?: string;
  roleId?: number;
  role?: Role;
  invoices?: Invoice[] | null;
  marketerCommissions?: MarketerCommission[] | null;
  marketers?: Marketer[] | null;
  orders?: Order[] | null;
  supportItems?: SupportItem[] | null;
  supports?: Support[] | null;
  userAddresses?: UserAddress[] | null;
  userBussinesUnits?: UserBussinesUnit[] | null;
  userCommissions?: UserCommission[] | null;
  userCompanies?: UserCompany[] | null;
  userInsuranceCompanies?: UserInsuranceCompany[] | null;
  userReferralJoins?: UserReferralJoin[] | null;
  userReferrals?: UserReferral[] | null;
  wallets?: Wallet[] | null;
};
export type UserAddress = {
  userAddressId?: number;
  userId?: string;
  stateId?: number;
  cityId?: number;
  address?: string | null;
  postalCode?: string | null;
  tel?: string | null;
  city?: City;
  state?: State;
  user?: User;
  orders?: Order[] | null;
};
export type State = {
  stateId?: number;
  name?: string | null;
  id?: number;
  code?: number | null;
  cities?: City[] | null;
  marketers?: Marketer[] | null;
  userAddresses?: UserAddress[] | null;
};
export type City = {
  cityId?: number;
  stateId?: number;
  name?: string | null;
  id?: number;
  code?: number | null;
  state?: State;
  marketers?: Marketer[] | null;
  userAddresses?: UserAddress[] | null;
};
export type MarketerLicenseStatus = {
  marketerLicenseStatusId?: number;
  name?: string | null;
  marketers?: Marketer[] | null;
};
export type MarketerLicenseSubject = {
  marketerLicenseSubjectId?: number;
  name?: string | null;
  marketers?: Marketer[] | null;
};
export type MarketerRequestStatus = {
  marketerRequestStatusId?: number;
  name?: string | null;
  marketers?: Marketer[] | null;
};
export type Marketer = {
  marketerId?: number;
  userId?: string;
  marketerRequestStatusId?: number;
  firstName?: string | null;
  lastName?: string | null;
  nationalId?: string | null;
  birthDate?: string;
  isMarketplace?: boolean;
  marketerLicenseStatusId?: number;
  marketerLicenseSubjectId?: number | null;
  marketerMarketName?: string | null;
  businessPhone?: string | null;
  emergencyPhone?: string | null;
  stateId?: number;
  cityId?: number;
  postalCode?: string | null;
  address?: string | null;
  marketerLicenseFileId?: number | null;
  nationalCardFileId?: number | null;
  createDate?: string;
  modifyDate?: string;
  city?: City;
  marketerLicenseFile?: Attachment;
  marketerLicenseStatus?: MarketerLicenseStatus;
  marketerLicenseSubject?: MarketerLicenseSubject;
  marketerRequestStatus?: MarketerRequestStatus;
  nationalCardFile?: Attachment;
  state?: State;
  user?: User;
};
export type Attachment = {
  attachmentId?: number;
  attachmentEntityId?: number;
  fileSize?: number;
  fileName?: string | null;
  fileExtension?: string | null;
  fileContent?: string | null;
  attachmentEntity?: AttachmentEntity;
  marketerMarketerLicenseFiles?: Marketer[] | null;
  marketerNationalCardFiles?: Marketer[] | null;
  orders?: Order[] | null;
};
export type IdentityInquiry = {
  identityInquiryId?: number;
  nationalId?: string | null;
  birthDate?: string;
  firstName?: string | null;
  lastName?: string | null;
  fatherName?: string | null;
  gender?: boolean | null;
  identityNo?: string | null;
  issuePlace?: string | null;
  address?: string | null;
  postalCode?: string | null;
  stateId?: number | null;
  cityId?: number | null;
  createDate?: string;
  isConfirm?: boolean;
  orders?: Order[] | null;
};
export type OrderStatus = {
  orderStatusId?: number;
  name?: string | null;
  orders?: Order[] | null;
};
export type OrderPromotion = {
  orderPromotionId?: number;
  orderId?: number;
  voucherId?: number;
  isExpired?: boolean;
  order?: Order;
  voucher?: Voucher;
};
export type Voucher = {
  voucherId?: number;
  code?: string | null;
  isPercent?: boolean;
  isChargeable?: boolean;
  amount?: number;
  maxAmount?: number;
  isOneTime?: boolean;
  quantity?: number;
  isUsed?: boolean;
  createDate?: string;
  expireDate?: string;
  useDate?: string | null;
  orderPromotions?: OrderPromotion[] | null;
  orders?: Order[] | null;
};
export type OrderItem = {
  orderItemId?: number;
  orderId?: number;
  productId?: number;
  quantity?: number;
  fee?: number;
  price?: number;
  order?: Order;
  product?: Product;
};
export type Order = {
  orderId?: number;
  userId?: string;
  marketerUserId?: string | null;
  orderStatusId?: number;
  orderDate?: string;
  productId?: number;
  netPrice?: number;
  markupPrice?: number;
  discount?: number;
  totalPrice?: number;
  vat?: number;
  orderPrice?: number;
  marketerPrice?: number;
  productPrice?: number;
  jsonAttributeValue?: string | null;
  jsonOrderValue?: string | null;
  identityInquiryId?: number | null;
  userAddressId?: number | null;
  voucherId?: number | null;
  certificateId?: number | null;
  certificateCode?: string | null;
  certificateFileId?: number | null;
  expireDate?: string | null;
  certificateFile?: Attachment;
  identityInquiry?: IdentityInquiry;
  orderStatus?: OrderStatus;
  product?: Product;
  user?: User;
  userAddress?: UserAddress;
  voucher?: Voucher;
  invoices?: Invoice[] | null;
  marketerCommissions?: MarketerCommission[] | null;
  orderBatchSettelments?: OrderBatchSettelment[] | null;
  orderItems?: OrderItem[] | null;
  orderPromotions?: OrderPromotion[] | null;
  payments?: Payment[] | null;
};
export type OrderBatchSettelment = {
  orderBatchSettelmentId?: number;
  orderId?: number;
  insuranceSettlementId?: number;
  insuranceSettlement?: InsuranceSettlement;
  order?: Order;
};
export type InsuranceSettlement = {
  insuranceSettlementId?: number;
  insuranceCompanyId?: number;
  settlementDate?: string;
  calcDate?: string | null;
  price?: number;
  vat?: number;
  totalPrice?: number;
  isPaid?: boolean;
  referenceNumber?: string | null;
  insuranceCompany?: InsuranceCompany;
  orderBatchSettelments?: OrderBatchSettelment[] | null;
};
export type ProductAttributeType = {
  productAttributeTypeId?: number;
  name?: string | null;
  productAttributes?: ProductAttribute[] | null;
};
export type ProductAttribute = {
  productAttributeId?: number;
  productCategoryId?: number;
  productAttributeTypeId?: number;
  name?: string | null;
  persianName?: string | null;
  description?: string | null;
  productAttributeType?: ProductAttributeType;
  productCategory?: ProductCategory;
};
export type ProductCategory = {
  productCategoryId?: number;
  name?: string | null;
  pdfCertificateTemplates?: PdfCertificateTemplate[] | null;
  productAttributes?: ProductAttribute[] | null;
  products?: Product[] | null;
};
export type PdfCertificateTemplate = {
  pdfCertificateTemplateId?: number;
  insuranceCompanyId?: number;
  productCategoryId?: number;
  pdfFile?: string | null;
  insuranceCompany?: InsuranceCompany;
  productCategory?: ProductCategory;
};
export type InsuranceCompany = {
  insuranceCompanyId?: number;
  name?: string | null;
  insuranceSettlements?: InsuranceSettlement[] | null;
  pdfCertificateTemplates?: PdfCertificateTemplate[] | null;
  products?: Product[] | null;
  userInsuranceCompanies?: UserInsuranceCompany[] | null;
};
export type ProductCommission = {
  productCommissionId?: number;
  productId?: number;
  isPercent?: boolean;
  name?: string | null;
  value?: number;
  product?: Product;
};
export type ProductPrice = {
  productPriceId?: number;
  productId?: number;
  isPercent?: boolean;
  name?: string | null;
  persianName?: string | null;
  value?: number;
  product?: Product;
};
export type Product = {
  productId?: number;
  productCategoryId?: number;
  insuranceCompanyId?: number;
  name?: string | null;
  isDisabled?: boolean;
  description?: string | null;
  wagePercent?: number;
  markupPrice?: number;
  planId?: number;
  jsonValue?: string | null;
  needConfirm?: boolean;
  insuranceCompany?: InsuranceCompany;
  productCategory?: ProductCategory;
  invoiceItems?: InvoiceItem[] | null;
  orderItems?: OrderItem[] | null;
  orders?: Order[] | null;
  productCommissions?: ProductCommission[] | null;
  productPrices?: ProductPrice[] | null;
  userCommissions?: UserCommission[] | null;
};
export type GetInsurerListResponse = {
  phoneNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  nationalId?: string | null;
  birthDate?: string;
  address?: string | null;
  stateName?: string | null;
  cityName?: string | null;
  orderDate?: string;
  netPrice?: number;
  orderStatusId?: number;
  orderStatusName?: string | null;
  product?: Product;
  jsonOrderValue?: string | null;
};
export type InvoiceListResponse = {
  id?: number;
  emailUser?: string | null;
  invoiceDate?: string;
  price?: number;
  vat?: number;
  discount?: number;
  invoicePrice?: number;
  invoiceStatus?: string | null;
};
export type InvoiceItemListResponse = {
  product?: string | null;
  quantity?: number;
  price?: number;
  totalPrice?: number;
};
export type GetInvoiceListResponse = {
  id?: number;
  invoiceDate?: string;
  price?: number;
  vat?: number;
  discount?: number;
  invoicePrice?: number;
  invoiceStatusId?: number;
  invoiceStatus?: string | null;
  invoiceItems?: InvoiceItemListResponse[] | null;
};
export type MarketerListResponse = {
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  nationalId?: string | null;
  marketerRequestStatus?: string | null;
  businessPhone?: string | null;
  state?: string | null;
  city?: string | null;
};
export type GetMarketerByIdListResponse = {
  id?: number;
  firstName?: string | null;
  lastName?: string | null;
  nationalId?: string | null;
  marketerRequestStatus?: string | null;
  businessPhone?: string | null;
  state?: string | null;
  city?: string | null;
};
export type MarketerResponse = {
  userCommissionId?: number;
  productId?: number;
  isPercent?: boolean;
  value?: number;
  isDisabled?: boolean;
};
export type MarketerCommissionModel = {
  marketerId: number;
  productId: number;
  isPercent: boolean;
  value: number;
};
export type EditMarketerCommissionModel = {
  userCommissionId: number;
  productId: number;
  marketerId: number;
  isPercent: boolean;
  value: number;
};
export type SendMarketerLinkModel = {
  phoneNumber?: string | null;
  email?: string | null;
  productCategoryId: number;
  productId?: number | null;
};
export type MarketerCommissionListResponse = {
  id?: number;
  orderId?: number;
  commissionDate?: string;
  totalPrice?: number;
  commissionPrice?: number;
  user?: string | null;
};
export type NotificationListResponse = {
  id?: number;
  content?: string | null;
  notificationSubject?: string | null;
  notificationDate?: string;
};
export type NotificationShortListResponse = {
  content?: string | null;
  notificationSubject?: string | null;
  notificationDate?: string;
};
export type OrderListResponse = {
  id?: number;
  user?: string | null;
  orderDate?: string;
  netPrice?: number;
  vat?: number;
  totalPrice?: number;
  markupPrice?: number;
  orderPrice?: number;
  productPrice?: number;
  discount?: number;
  orderStatus?: string | null;
  product?: string | null;
  productCategory?: string | null;
  productCategoryId?: number;
  confirmNeeded?: boolean;
};
export type OrderListModel = {
  insuranceCompanyId?: number | null;
  insuranceCategoryId?: number | null;
  orderStatusId?: number | null;
  needConfirm?: boolean | null;
};
export type OrderStatusListResponse = {
  id?: number;
  text?: string | null;
};
export type OrderProductCategoryListResponse = {
  id?: number;
  text?: string | null;
};
export type OrderInsurCompanyListResponse = {
  id?: number;
  text?: string | null;
};
export type GetOrdersByIdResponse = {
  id?: number;
  phone?: string | null;
  netPrice?: number;
  vat?: number;
  orderDate?: string;
  orderPrice?: number;
  totalPrice?: number;
  productPrice?: number;
  discount?: number;
  certificateCode?: string | null;
  certificateFile?: string | null;
  certificateFileExtension?: string | null;
  productName?: string | null;
  productCategoryId?: number;
  orderStatus?: string | null;
  nationalId?: string | null;
  userName?: string | null;
  birthDate?: string | null;
  cityName?: string | null;
  stateName?: string | null;
  postalCode?: string | null;
  address?: string | null;
};
export type GetOrderAttachResponse = {
  file?: string | null;
  extension?: string | null;
};
export type VerifyIdModel = {
  orderId: number;
  nationalId: string;
  birthDate: string;
};
export type VerifyAddressModel = {
  orderId: number;
  cityId: number;
  address: string;
  postalCode: string;
  tel?: string | null;
};
export type VerifyVoucherModel = {
  orderId: number;
  voucherCode: string;
};
export type GetOrderDownloadResponse = {
  file?: string | null;
  extension?: string | null;
};
export type FireOrderResponseModel = {
  id?: number;
  userId?: string;
  productId?: number;
  productPrice?: number;
  price?: number;
  discount?: number;
  vat?: number;
  orderPrice?: number;
  identityInquiryId?: number | null;
  userAddressId?: number | null;
  orderFireId?: number | null;
};
export type VerifyFireModel = {
  orderId: number;
  stateId: number;
  cityId: number;
  estateId: number;
  unit: number;
  buildTypeId: number;
  area: number;
  buildingValue: number;
  earthquake: boolean;
  pipeExplotion: boolean;
  earthSummit: boolean;
  airplanFall: boolean;
  flood: boolean;
  storm: boolean;
  harz: boolean;
  snowRain: boolean;
  costs: number;
  floors?: number | null;
  address?: string | null;
  addressFloor?: number | null;
  addressUnit?: number | null;
  addressNumber?: number | null;
  tel?: string | null;
  postalCode?: string | null;
  oldInsureInfo?: boolean | null;
};
export type IndividualEventsOrderResponseModel = {
  id?: number;
  userId?: string;
  productId?: number;
  productPrice?: number;
  price?: number;
  discount?: number;
  vat?: number;
  orderPrice?: number;
  identityInquiryId?: number | null;
  userAddressId?: number | null;
  orderIndividualEventId?: number | null;
};
export type VerifyIndividualEventsModel = {
  orderId: number;
  personCounts: number;
  insuranceDurationId: number;
  jobId: number;
};
export type IndividualEventsOrderListResponse = {
  id?: number;
  text?: string | null;
};
export type OrderPhoneResponseModel = {
  id?: number;
  productPrice?: number;
  price?: number;
  discount?: number;
  vat?: number;
  orderPrice?: number;
  productId?: number;
  imei?: string | null;
  phoneNumber?: string | null;
  nationalId?: string | null;
  birthDate?: string | null;
  brandName?: string | null;
  cityId?: number | null;
  stateId?: number | null;
  address?: string | null;
  postalCode?: string | null;
};
export type CreateOrderModel = {
  marketerUserId?: string | null;
  userId: string;
  insuranceType: number;
  productPrice: number;
  productId?: number;
};
export type VerifyPhoneModel = {
  orderId: number;
  phoneNumber: string;
  imei: string;
  brandName: string;
};
export type GetPaymentListResponse = {
  id?: number;
  user?: string | null;
  paymentDate?: string;
  amount?: number | null;
  rrn?: number | null;
  cardNumber?: string | null;
  paymentStatus?: string | null;
  paymentProvider?: string | null;
};
export type GetPaymentByIdListResponse = {
  id?: number;
  paymentDate?: string;
  amount?: number | null;
  rrn?: number | null;
  cardNumber?: string | null;
  paymentStatus?: string | null;
  paymentProvider?: string | null;
};
export type PdfCertificateTemplateListRespnose = {
  pdfCertificateTemplateId?: number;
  insuranceCompanyId?: number;
  productCategoryId?: number;
  pdfFile?: string | null;
  insuranceCompany?: string | null;
  productCategory?: string | null;
};
export type GetPhoneBrandListResponse = {
  id?: number;
  name?: string | null;
};
export type GetPhoneModelListResponse = {
  id?: number;
  name?: string | null;
};
export type GetProductListResponse = {
  id?: number;
  name?: string | null;
  wagePercent?: number;
  markupPrice?: number;
  planId?: number;
  productCategory?: string | null;
  productCategoryId?: number;
  insuranceCompany?: string | null;
  insuranceCompanyId?: number;
  description?: string | null;
  isDisabled?: boolean;
  needConfirm?: boolean;
  status?: string | null;
};
export type GetProductByIdResponse = {
  id?: number;
  name?: string | null;
  wagePercent?: number;
  markupPrice?: number;
  planId?: number;
  productCategoryId?: number;
  insuranceCompanyId?: number;
  description?: string | null;
  isDisabled?: boolean;
  needConfirm?: boolean;
  productCommissionIsPercent?: boolean;
  productCommissionValue?: number;
};
export type CreateProductResponse = {
  productId?: number;
};
export type CreateProductModel = {
  name: string;
  productCategoryId: number;
  insuranceCompanyId: number;
  description?: string | null;
  wagePercent: number;
  markupPrice: number;
  planId: number;
  isDisabled: boolean;
  needConfirm: boolean;
  productCommissionIsPercent?: boolean;
  productCommissionValue?: number;
};
export type EditProductModel = {
  id: number;
  name: string;
  insuranceCompanyId: number;
  description?: string | null;
  wagePercent: number;
  markupPrice: number;
  planId: number;
  isDisabled: boolean;
  needConfirm: boolean;
  productCommissionIsPercent?: boolean;
  productCommissionValue?: number;
};
export type GetProductCategoryListResponse = {
  id?: number;
  name?: string | null;
};
export type GetProductItemListResponse = {
  productAttributeId?: number;
  productCategoryId?: number;
  productAttributeTypeId?: number;
  name?: string | null;
  persianName?: string | null;
  value?: number;
};
export type EditProductItemsModel = {
  productId: number;
  jsonProductAttributeStr: string;
};
export type GetProductPriceListResponse = {
  id?: number;
  productId?: number;
  persianName?: string | null;
  isPercent?: boolean;
  value?: number;
};
export type EditPrice = {
  productPriceId: number;
  value: number;
};
export type EditPriceModel = {
  productId: number;
  editPrices?: EditPrice[] | null;
};
export type GetProfileListResponse = {
  phoneNumber?: string | null;
  phoneNumberConfirmed?: boolean;
  email?: string | null;
  emailConfirmed?: boolean;
  firstName?: string | null;
  lastName?: string | null;
  nationalId?: string | null;
  birthDate?: string | null;
};
export type EditModel = {
  firstName: string;
  lastName: string;
  nationalId: string;
  birthDate: string;
};
export type ChangePasswordModel = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};
export type GetReportResponse = {
  user?: number;
  order?: number;
  hamta?: number;
  identity?: number;
  orderPrice?: number;
  insurancePrice?: number;
  marketerPrice?: number;
  markupPrice?: number;
  walletBalance?: number;
  promotionSend?: number;
  promotionClick?: number;
  hamtaPromotion?: number;
  errors?: number;
};
export type FilterReportModel = {
  today?: string;
};
export type GetRoleListResponse = {
  id?: number;
  name?: string | null;
};
export type GetStateListResponse = {
  id?: number;
  name?: string | null;
};
export type GetSupportListResponse = {
  id?: number;
  user?: string | null;
  supportDate?: string;
  supportSubject?: string | null;
  businessUnit?: string | null;
  supportStatus?: string | null;
};
export type CreateSupportModel = {
  userId?: string;
  content: string;
  businessUnitId: number;
  supportSubjectId: number;
};
export type GetSupportItemListResponse = {
  id?: number;
  supportId?: number;
  content?: string | null;
  supportDate?: string | null;
  user?: string | null;
};
export type CreateSupportItemModel = {
  supportId: number;
  content: string;
};
export type GetSupportSubjectListResponse = {
  id?: number;
  businessUnit?: string | null;
  businessUnitId?: number;
  name?: string | null;
};
export type GetSupportSubjectByIdListResponse = {
  id?: number;
  name?: string | null;
};
export type CreateSupportSubjectModel = {
  businessUnitId?: number;
  name: string;
};
export type EditSupportSubjectModel = {
  businessUnitId?: number;
  name: string;
  id?: number;
};
export type GetUserListResponse = {
  id?: string;
  fullName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  isCompanyAccount?: string | null;
  role?: string | null;
  isActive?: string | null;
  lastLoginDate?: string | null;
  createDate?: string;
};
export type GetUserShortListResponse = {
  id?: string;
  name?: string | null;
};
export type GetUserByIdListResponse = {
  id?: string;
  phoneNumber?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  nationalId?: string | null;
  birthDate?: string | null;
  roleId?: number;
};
export type UserCreateResponse = {
  userId?: string;
  phoneNumber?: string | null;
  email?: string | null;
  password?: string | null;
  accessFailedCount?: number;
  allowedIp?: string | null;
  phoneNumberConfirmed?: boolean;
  phoneNotify?: boolean;
  emailConfirmed?: boolean;
  emailNotify?: boolean;
  isActive?: boolean;
  isCompanyAccount?: boolean;
  firstName?: boolean;
  lastName?: string | null;
  nationalId?: string | null;
  birthDate?: string;
  createDate?: string;
  modifyDate?: string;
  roleId?: number;
};
export type CreateUserModel = {
  firstName: string;
  lastName: string;
  nationalId: string;
  birthDate?: string | null;
  phoneNumber: string;
  email: string;
  password: string;
  roleId: number;
  insuranceCompanyId?: number;
  allowedIp?: string | null;
};
export type EditUserModel = {
  id?: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  nationalId: string;
  roleId: number;
  insuranceCompanyId?: number;
  allowedIp?: string | null;
};
export type ResetPasswordModel = {
  id?: string;
  password: string;
  confirmPassword: string;
};
export type GetUserReferralResponse = {
  isJoined?: boolean;
  joinCode?: string | null;
  referralCode?: string | null;
};
export type GetUserReferralJoinListResponse = {
  id?: number;
  joinDate?: string;
  referralCode?: string | null;
};
export type VUsage = {
  id?: number;
  title?: string | null;
};
export type VModel = {
  id?: number;
  title?: string | null;
  priority?: number;
};
export type VBrand = {
  id?: number;
  title?: string | null;
  latinTitle?: string | null;
  priority?: number;
  icon?: string | null;
  models?: VModel[] | null;
};
export type VType = {
  id?: number;
  title?: string | null;
  categoryTitle?: string | null;
  usages?: VUsage[] | null;
  brands?: VBrand[] | null;
};
export type GetWalletListResponse = {
  id?: number;
  user?: string | null;
  userId?: string;
  balance?: number;
  createDate?: string;
};
export type GetWalletDepositListResponse = {
  id?: number;
  user?: string | null;
  transactionDate?: string;
  amount?: number;
  walletDepositStatus?: string | null;
  walletDepositType?: string | null;
};
export type CreateWalletDepositModel = {
  userId?: string;
  price?: number;
  walletDepositTypeId?: number;
};
export type GetWalletTransactionListResponse = {
  id?: number;
  user?: string | null;
  transactionDate?: string;
  credit?: number;
  debit?: number;
  balance?: number;
  description?: string | null;
};
export type GetWalletWithdrawListResponse = {
  id?: number;
  user?: string | null;
  transactionDate?: string;
  amount?: number;
  iban?: string | null;
  walletWithdrawStatus?: string | null;
};
export const {
  usePostApiAccountLoginMutation,
  usePostApiAccountLogoutMutation,
  useGetApiAgeRangeGenerateLinkForCompanyCategoryByCategoryIdAndInsuranceCompanyIdQuery,
  useGetApiAgeRangeListQuery,
  useGetApiBaseInsurerListQuery,
  useGetApiBusinessUnitListQuery,
  useGetApiCityByStateIdListQuery,
  useGetApiGatewayGetByOrderIdQuery,
  usePostApiGatewayOrderMutation,
  usePostApiGatewayConfirmByOrderIdMutation,
  usePostApiGatewayDownloadMutation,
  useGetApiGatewaySalesPlanQuery,
  usePostApiGatewayCalculateMutation,
  useGetQuery,
  useGetApiInsuranceCompanyListQuery,
  useGetApiInsuranceDurationsListQuery,
  useGetApiInsurerListQuery,
  usePostApiInsurerIssueStatusOrderMutation,
  useGetApiInsurerModifyStatusOrderByOrderIdQuery,
  useGetApiInvoiceListByUserIdAndInvoiceStatusIdQuery,
  useGetApiInvoiceGetByIdQuery,
  useGetApiMarketerListQuery,
  useGetApiMarketerGetByMarketerIdQuery,
  useGetApiMarketerConfirmByMarketerIdQuery,
  useGetApiMarketerRejectByMarketerIdAndReasonQuery,
  useGetApiMarketerCancelByMarketerIdAndReasonQuery,
  useGetApiMarketerGetUserCommissionsByMarketerIdQuery,
  usePostApiMarketerAddUserCommissionsMutation,
  useGetApiMarketerDisableUserCommissionsByProductIdAndUserCommissionIdQuery,
  useGetApiMarketerActivateUserCommissionsByProductIdAndUserCommissionIdQuery,
  usePostApiMarketerEditUserCommissionMutation,
  usePostApiMarketerSendMarketerLinkMutation,
  useGetApiMarketerCommissionListQuery,
  useGetApiNotificationListQuery,
  useGetApiNotificationShortListQuery,
  usePostApiOrderListMutation,
  useGetApiOrderOrderStatusListQuery,
  useGetApiOrderProductCategoryListQuery,
  useGetApiOrderInsuranceCompanyListQuery,
  useGetApiOrderGetByOrderIdQuery,
  useGetApiOrderGetOrderDetailByOrderIdQuery,
  useGetApiOrderDownloadOrderAttachmentByAttachmentIdQuery,
  usePostApiOrderVerifyIdMutation,
  usePostApiOrderVerifyAddressMutation,
  usePostApiOrderVerifyVoucherMutation,
  usePutApiOrderCancelByOrderIdMutation,
  useGetApiOrderDownloadByOrderIdQuery,
  useGetApiOrderFireGetByOrderIdQuery,
  usePostApiOrderFireVerifyMutation,
  useGetApiOrderIndividualEventsGetByOrderIdQuery,
  usePostApiOrderIndividualEventsVerifyMutation,
  useGetApiOrderIndividualEventsJobsQuery,
  useGetGetByOrderIdQuery,
  usePostCreateMutation,
  usePostVerifyMutation,
  useGetApiPaymentListByUserIdQuery,
  useGetApiPaymentGetByIdQuery,
  usePostApiPdfCertificateTemplateCreateMutation,
  usePostApiPdfCertificateTemplateListMutation,
  usePostApiPdfCertificateTemplateGetByPdfCertificateTemplateIdMutation,
  useGetApiPhoneBrandListQuery,
  useGetApiPhoneModelByBrandIdListQuery,
  useGetApiProductListQuery,
  useGetApiProductGetByIdQuery,
  usePostApiProductCreateMutation,
  usePutApiProductEditMutation,
  useDeleteApiProductDeleteByIdMutation,
  useGetApiProductCategoryListQuery,
  useGetApiProductItemListByProductIdQuery,
  useDeleteApiProductItemDeleteByProductIdMutation,
  usePostApiProductItemEditMutation,
  useGetApiProductPriceListByProductIdQuery,
  usePutApiProductPriceEditMutation,
  useGetApiProfileGetQuery,
  usePutApiProfileEditMutation,
  usePostApiProfileChangePasswordMutation,
  usePostApiReportListMutation,
  useGetApiRoleListQuery,
  useGetApiStateListQuery,
  useGetApiSupportListBySupportStatusIdAndUserIdQuery,
  usePostApiSupportCreateMutation,
  usePutApiSupportCloseByIdMutation,
  useGetApiSupportItemListBySupportIdQuery,
  usePostApiSupportItemCreateMutation,
  useGetApiSupportSubjectListQuery,
  useGetApiSupportSubjectSelectListByBusinessUnitIdQuery,
  usePostApiSupportSubjectCreateMutation,
  usePutApiSupportSubjectEditMutation,
  useDeleteApiSupportSubjectDeleteByIdMutation,
  useGetApiUserListQuery,
  useGetApiUserShortListQuery,
  useGetApiUserGetByIdQuery,
  usePostApiUserCreateMutation,
  usePutApiUserEditMutation,
  useDeleteApiUserDeleteByIdMutation,
  usePutApiUserEnableByIdMutation,
  usePutApiUserDisableByIdMutation,
  usePutApiUserResetPasswordMutation,
  useGetApiUserReferralGetQuery,
  useGetApiUserReferralJoinListQuery,
  usePostApiVehicleInfoGeneratorAddVehicleInfoMutation,
  useGetApiWalletListQuery,
  useGetApiWalletDepositListByUserIdQuery,
  usePostApiWalletDepositCreateMutation,
  useGetApiWalletTransactionListByUserIdQuery,
  useGetApiWalletWithdrawListQuery,
} = api;
