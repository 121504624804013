import { GetApiWalletWithdrawListApiResponse } from "src/app/services/api.generated";
import theme from "src/configs/theme/theme";
import { ColumnModes, ColumnType } from "src/types/genericTable.types";

const colors = new Map<string, string>([
  ["در انتظار تائید", theme.palette.warning.light],
  ["رد شده", theme.palette.error.main],
  ["تائید شده", theme.palette.success.light],
]);

export const walletWithdrawColumns: ColumnType<
  GetApiWalletWithdrawListApiResponse[number]
>[] = [
  {
    type: ColumnModes.DATA,
    label: "شماره پیگیری",
    id: "id",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "نام کاربر",
    id: "user",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "تاریخ",
    id: "transactionDate",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "مبلغ",
    id: "amount",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    label: "حساب واریزی",
    id: "iban",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "وضعیت",
    id: "walletWithdrawStatus",
    chip: true,
    color: colors,
  },
];
