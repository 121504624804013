import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";

import authReducer from "./slices/authSlice";
import { rtkQueryErrorHandler } from "./services/rtkQueryError";
import { api } from "./services/api";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

const isDevelopment = process.env.NODE_ENV === "development";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  auth: authReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware).concat(rtkQueryErrorHandler),
  devTools: isDevelopment,
});

setupListeners(store.dispatch);

// "ReturnType" set type based on returned value from function
export type AppDispatch = typeof store.dispatch;
export type RootStateType = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootStateType,
  unknown,
  Action<string>
>;
