import { FC, useEffect, useMemo, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
} from "@mui/material";
import GenericForm from "../../organization/GenericForm";
import fields from "src/sampleData/dialogs/AddTicket";
import { FormButtonType } from "src/types/genericFrom.types";
import {
  useGetApiBusinessUnitListQuery,
  useGetApiUserShortListQuery,
  usePostApiSupportCreateMutation,
} from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { listItemsEnhancer } from "src/utils/ListItemsEnhancer";
import { useLazyGetApiSupportSubjectSelectListByBusinessUnitIdQuery } from "src/app/services/api";

export const AddTicketDialog: FC<DialogProps> = (props) => {
  const [state, setState] = useState({
    userId: "",
    content: "",
    businessUnitId: "",
    supportSubjectId: "",
  });

  const [supportSubjectList, setSupportSubjectList] = useState([]);

  const [getSupportSubjectList] =
    useLazyGetApiSupportSubjectSelectListByBusinessUnitIdQuery();

  const { data: businessUnitList } = useGetApiBusinessUnitListQuery();
  const { data: usersList } = useGetApiUserShortListQuery();

  useEffect(() => {
    if (state.businessUnitId) {
      getSupportSubjectList({ businessUnitId: Number(state.businessUnitId) })
        .unwrap()
        .then((res) => {
          if (res) {
            setSupportSubjectList(res as any);
          }
        });
    }
  }, [getSupportSubjectList, state.businessUnitId]);

  const [createTicket, { isLoading: createTicketLoading }] =
    usePostApiSupportCreateMutation();

  const onSubmit = () => {
    const { userId, content, businessUnitId, supportSubjectId } = state;

    createTicket({
      createSupportModel: {
        userId,
        content,
        businessUnitId: Number(businessUnitId),
        supportSubjectId: Number(supportSubjectId),
      },
    })
      .unwrap()
      .then(() => {
        toast.success("درخواست جدید با موفقیت ایجاد شد");
        props.onClose!({}, "escapeKeyDown");
      });
  };

  const buttons: FormButtonType[] = [
    {
      onClick: () => props.onClose!({}, "escapeKeyDown"),
      text: "انصراف",
      variant: "outlined",
      color: "primary",
      checkRequiredField: false,
    },
    {
      onClick: onSubmit,
      text: "ایجاد",
      variant: "contained",
      color: "primary",
      checkRequiredField: true,
      isLoading: createTicketLoading,
    },
  ];

  const enhancedFields = useMemo(() => {
    let result = [...fields];
    result.forEach((item) => {
      if (item.name === "userId" && usersList) {
        item.props.items = listItemsEnhancer(usersList);
      }
      if (item.name === "supportSubjectId" && supportSubjectList) {
        item.props.items = listItemsEnhancer(supportSubjectList);
      }
      if (item.name === "businessUnitId" && businessUnitList) {
        item.props.items = listItemsEnhancer(businessUnitList);
      }
    });
    return result;
  }, [businessUnitList, supportSubjectList, usersList]);

  return (
    <Dialog {...props}>
      <DialogTitle>ایجاد درخواست جدید</DialogTitle>
      <DialogContent>
        <Box sx={{ paddingTop: 1 }}>
          <GenericForm
            state={state}
            setState={setState}
            fields={enhancedFields}
            buttons={buttons}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
