import { Stack } from "@mui/material";
import { FC, useState } from "react";
import { useGetApiOrderListQuery } from "src/app/services/api";
import {
  PostApiOrderListApiArg,
  useGetApiOrderInsuranceCompanyListQuery,
  useGetApiOrderProductCategoryListQuery,
  useGetApiOrderOrderStatusListQuery,
} from "src/app/services/api.generated";
import SearchDropdown from "src/components/molecule/SearchDropdown";
import TableSkeletonRow from "src/components/molecule/TableSkeletonRow";
import DataTable from "src/components/organization/DataTable";
import { orderColumns } from "src/sampleData/orders";
import BreadCrumbs from "../components/organization/layout/breadcrumbs/BreadCrumbs";

const items = [
  { id: true, value: "دارد" },
  { id: false, value: "ندارد" },
];

const Orders: FC = () => {
  const [state, setState] = useState<PostApiOrderListApiArg["orderListModel"]>(
    {}
  );
  const onSelect = (id: string, name: string) => {
    let value = id !== "any" ? id : null;
    setState({ ...state, [name]: value });
  };

  const { data, isLoading } = useGetApiOrderListQuery({
    orderListModel: state,
  });

  const { data: insuranceCompanyList } =
    useGetApiOrderInsuranceCompanyListQuery();
  const { data: insuranceCategoryList } =
    useGetApiOrderProductCategoryListQuery();
  const { data: orderStatusList } = useGetApiOrderOrderStatusListQuery();

  const OrderSearchBar = (
    <Stack
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-around"
      alignItems="center"
      spacing={1}
      sx={{ width: "100%" }}
    >
      <SearchDropdown
        selectedItemId={state?.insuranceCompanyId}
        items={
          insuranceCompanyList?.map((ins) => ({
            id: ins.id,
            value: ins.text,
          })) || []
        }
        onSelect={(id: string) => onSelect(id, "insuranceCompanyId")}
        label={"شرکت بیمه"}
      />
      <SearchDropdown
        selectedItemId={state?.insuranceCategoryId}
        items={
          insuranceCategoryList?.map((ins) => ({
            id: ins.id,
            value: ins.text,
          })) || []
        }
        onSelect={(id: string) => onSelect(id, "insuranceCategoryId")}
        label={"نوع بیمه"}
      />
      <SearchDropdown
        selectedItemId={state?.orderStatusId}
        items={
          orderStatusList?.map((ins) => ({
            id: ins.id,
            value: ins.text,
          })) || []
        }
        onSelect={(id: string) => onSelect(id, "orderStatusId")}
        label={"وضعیت"}
      />
      <SearchDropdown
        selectedItemId={state?.needConfirm}
        items={items}
        onSelect={(id: string) => onSelect(id, "needConfirm")}
        label={"نیاز به تایید"}
      />
    </Stack>
  );

  return (
    <>
      <BreadCrumbs />
      {isLoading ? (
        <TableSkeletonRow title="سفارشات" cellCount={12} />
      ) : (
        <DataTable
          searchBar={OrderSearchBar}
          rows={data || []}
          columns={orderColumns}
          title="سفارشات"
        />
      )}
    </>
  );
};

export default Orders;
