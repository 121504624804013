import type { FC } from "react";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Stack,
  Tooltip,
} from "@mui/material";
import { Logout as LogoutIcon, Menu as MenuIcon } from "@mui/icons-material";
import { useAppDispatch } from "src/app/reduxCustomHooks";
import { logoutAction } from "src/app/slices/authSlice";
import { toast } from "react-toastify";
import NotificationMenu from "./menus/NotificationMenu";
import ProfileMenu from "./menus/ProfileMenu";

type NavbarPropsType = {
  handleDrawerToggle: () => void;
};

const Navbar: FC<NavbarPropsType> = ({ handleDrawerToggle }) => {
  const dispatch = useAppDispatch();

  const logoutHandler = () => {
    dispatch(logoutAction());
    toast.success("شما با موفقیت خارج شدید");
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: "100%",
        zIndex: 1201,
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Typography sx={{ display: { xs: "none", sm: "block" } }}>
          مدیریت کارگزاری آنلاین
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="start"
          sx={{ ml: "auto" }}
        >
          <ProfileMenu />
          <NotificationMenu />
          <Tooltip title="خروج از سامانه" arrow>
            <IconButton color="inherit" onClick={logoutHandler}>
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
