import type { FC } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import theme from "src/configs/theme/theme";
import createCache from "@emotion/cache";
import Router from "src/routes/Router";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { Provider } from "react-redux";
import { store } from "src/app/store";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const App: FC = () => {
  return (
    <CacheProvider
      value={createCache({
        key: "muirtl",
        stylisPlugins: [prefixer, rtlPlugin],
      })}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ToastContainer limit={3} rtl />
          <Router />
        </Provider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
