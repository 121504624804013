import type { FC } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Deposits from "src/pages/Deposits";
import Payments from "src/pages/Payments";
import Receipts from "src/pages/Receipts";
import Support from "src/pages/Support";
import SupportTopics from "src/pages/SupportTopics";
import WalletReports from "src/pages/WalletReports";
import Wallets from "src/pages/Wallets";
import NotFound from "src/pages/NotFound";
import Orders from "src/pages/Orders";
import Products from "src/pages/Products";
import Users from "src/pages/Users";
import Home from "src/pages/Home";
import PrivateRoute from "./PrivateRoute";
import Login from "src/pages/Login";
import ChangePassword from "src/pages/ChangePassword";
import Profile from "src/pages/Profile";
import Marketers from "src/pages/Marketers";
import Notification from "src/pages/Notification";
import WalletWithdraw from "src/pages/WalletWithdraw";
import OrderDetails from "src/pages/OrderDetails";
import SalesInvoice from "src/pages/SalesInvoice";

const Router: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          {/* ================================================== */}
          <Route path="/orders" element={<Orders />} />
          <Route path="/products" element={<Products />} />
          <Route path="/users" element={<Users />} />
          <Route path="/marketers" element={<Marketers />} />
          <Route path="/support" element={<Support />} />
          {/* ================================================== */}
          <Route path="/invoice" element={<Receipts />} />
          <Route path="/invoice/:id" element={<SalesInvoice />} />
          <Route path="/walletWithdraw" element={<WalletWithdraw />} />
          <Route path="/walletDeposit" element={<Deposits />} />
          <Route path="/walletTransaction" element={<WalletReports />} />
          <Route path="/wallet" element={<Wallets />} />
          <Route path="/payment" element={<Payments />} />
          {/* ================================================== */}
          <Route path="/supportSubject" element={<SupportTopics />} />
          {/* ================================================== */}
          <Route path="/profile" element={<Profile />} />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="/notification" element={<Notification />} />
          <Route path="/orders/:id" element={<OrderDetails />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
