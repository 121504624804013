import type { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "src/app/reduxCustomHooks";
import MainTemplate from "src/components/template/MainTemplate";

const PrivateRoute: FC = () => {
  const auth = useAppSelector(({ auth }) => auth);

  return auth?.accessToken ? (
    <MainTemplate>
      <Outlet />
    </MainTemplate>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
