import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query";
import { toast } from "react-toastify";
import { logoutAction } from "../slices/authSlice";
import { RootStateType } from "../store";

const isDevelopment = process.env.NODE_ENV === "development";

const localBaseQuery = fetchBaseQuery({
  baseUrl: isDevelopment
    ? process.env.REACT_APP_DEVELOPMENT_BASE_URL
    : process.env.REACT_APP_PRODUCTION_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const { auth } = getState() as RootStateType;
    if (auth?.accessToken) {
      headers.set("Authorization", `Bearer ${auth.accessToken}`);
    }
    return headers;
  },
});

export type errorType = {
  data: {
    message: string;
  };
  status: number;
};

let defaultErrorMessage =
  "به نظر مشکلی پیش آمده است، لطفاً چند دقیقه دیگر دوباره امتحان کنید";

export const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await localBaseQuery(args, api, extraOptions);
  if (result.error) {
    console.log("Base query error handler => ", result.error);
    const statusCode = result.error.status;
    const errorMessage = result.error.data
      ? (result.error.data as any)[Object.keys(result.error.data as any)[0]][0]
      : "";
    if (statusCode === 401) {
      api.dispatch(logoutAction());
    } else if (statusCode !== 404) {
      toast.error(errorMessage ? errorMessage : defaultErrorMessage);
    }
  }
  return result;
};
