import { ColumnModes, ColumnType } from "src/types/genericTable.types";
import { ActionModes } from "src/types/actionItems.types";
import { ActionButtonModes } from "src/types/actionButton.types";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DeleteProductDialog } from "src/components/molecule/dialogs/DeleteProductDialog";
import ProductAttributesManagement from "src/components/molecule/dialogs/ProductAttributesManagement";
import { ProductPriceManagement } from "src/components/molecule/dialogs/ProductPriceManagement";
import { EditProductDialog } from "src/components/molecule/dialogs/EditProductDialog";
import { GetApiProductListApiResponse } from "src/app/services/api.generated";
import theme from "src/configs/theme/theme";

const colors = new Map<string, string>([
  ["فعال", theme.palette.success.light],
  ["غیرفعال", theme.palette.error.main],
]);

export const productsColumns: ColumnType<
  GetApiProductListApiResponse[number]
>[] = [
  {
    type: ColumnModes.DATA,
    id: "id",
    label: "شناسه",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "name",
    label: "نام",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "productCategory",
    label: "دسته بندی",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "insuranceCompany",
    label: "بیمه گر",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "markupPrice",
    label: "مارکاپ",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    id: "wagePercent",
    label: "درصد کارمزد",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "status",
    label: "وضعیت",
    chip: true,
    color: colors,
  },
  {
    type: ColumnModes.ACTION,
    label: "عملیات",
    actions: [
      {
        icon: ViewListOutlinedIcon,
        color: theme.palette.info.dark,
        type: ActionButtonModes.SINGLE,
        action: {
          type: ActionModes.DIALOG,
          dialogComponent: ProductAttributesManagement,
          props: ["id"],
        },
      },
      {
        icon: AssignmentOutlinedIcon,
        color: theme.palette.info.light,
        type: ActionButtonModes.SINGLE,
        action: {
          type: ActionModes.DIALOG,
          dialogComponent: ProductPriceManagement,
          props: ["id"],
        },
      },
      {
        icon: EditOutlinedIcon,
        type: ActionButtonModes.SINGLE,
        action: {
          type: ActionModes.DIALOG,
          dialogComponent: EditProductDialog,
          props: ["id"],
        },
      },
      {
        icon: DeleteOutlinedIcon,
        color: theme.palette.error.main,
        type: ActionButtonModes.SINGLE,
        action: {
          type: ActionModes.DIALOG,
          dialogComponent: DeleteProductDialog,
          props: ["id"],
        },
      },
    ],
  },
];
