import type { FC } from "react";
import { useMemo } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useGetApiInvoiceGetByIdQuery } from "src/app/services/api.generated";
import { useLocation, useNavigate } from "react-router";
import BreadCrumbs from "src/components/organization/layout/breadcrumbs/BreadCrumbs";
import SalesInvoiceTable from "src/components/organization/invoice/SalesInvoiceTable";
import { priceToPersian } from "src/utils/priceToPersian";

const SalesInvoice: FC = () => {
  const { pathname } = useLocation(),
    navigate = useNavigate();

  const orderId = useMemo(() => {
    const id = pathname.replace("/invoice/", "");
    if (id === "") return null;
    return Number(id);
  }, [pathname]);

  if (!orderId) navigate("/");

  const { data, isLoading } = useGetApiInvoiceGetByIdQuery({
    id: orderId!,
  });

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <BreadCrumbs spaceBottom={false} />
        <Button variant="contained" onClick={() => navigate(-1)}>
          بازگشت
        </Button>
      </Stack>
      <br />
      <Paper
        component={Stack}
        sx={{
          overflow: "hidden",
          position: "relative",
          p: { xs: 2, md: 4 },
        }}
        rowGap={4}
        divider={<Divider flexItem />}
      >
        {isLoading && (
          <Stack justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        )}
        {data && (
          <>
            <Typography align="center" variant="h4">
              فاکتور فروش
            </Typography>
            <Grid
              container
              gap={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12}>
                <Typography sx={{ mb: 1 }} variant="h6">
                  مشخصات
                </Typography>
              </Grid>
              {[
                { name: "شماره فاکتور", value: data.id },
                { name: "نام", value: "" },
                { name: "تاریخ", value: data.invoiceDate },
                { name: "وضعیت", value: data.invoiceStatus },
              ].map(({ name, value }, index) => (
                <Grid item xs={12} sm={5.8} md={2.8} key={index}>
                  <Stack direction="row" columnGap={1}>
                    <Typography>{name}:</Typography>
                    <Typography color="gray">{value}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
            <Box>
              <Typography sx={{ mb: 1 }} variant="h6">
                مشخصات کالا/خدمات
              </Typography>
              {data.invoiceItems && (
                <SalesInvoiceTable data={data.invoiceItems} />
              )}
              <Grid
                container
                gap={2}
                alignItems="center"
                justifyContent="space-between"
                sx={{ p: 2 }}
              >
                {[
                  { name: "جمع کل", value: priceToPersian(data.price) },
                  { name: "ارزش افزوده", value: priceToPersian(data.vat) },
                  { name: "تخفیف", value: priceToPersian(data.discount) },
                  {
                    name: "مبلغ قابل پرداخت",
                    value: priceToPersian(data.invoicePrice),
                  },
                ].map(({ name, value }, index) => (
                  <Grid item xs={12} sm={5.8} md={2.8} key={index}>
                    <Stack direction="row" columnGap={1}>
                      <Typography>{name}:</Typography>
                      <Typography color="gray">{value}</Typography>
                    </Stack>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Grid
              container
              gap={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={12}>
                <Typography sx={{ mb: 1 }} variant="h6">
                  گزارش پرداخت
                </Typography>
              </Grid>
              {[
                { name: "تاریخ", value: "-" },
                { name: "مبلغ", value: "-" },
                { name: "شماره پیگیری بانک", value: "-" },
                { name: "شماره کارت", value: "-" },
                { name: "وضعیت", value: "-" },
              ].map(({ name, value }, index) => (
                <Grid item xs={12} sm={5.8} md={1.8} key={index}>
                  <Stack direction="row" columnGap={1}>
                    <Typography>{name}:</Typography>
                    <Typography color="gray">{value}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Paper>
    </>
  );
  // }
};

export default SalesInvoice;
