import { FC, useState } from "react";
import {
  useGetApiWalletWithdrawListQuery,
  useGetApiUserShortListQuery,
} from "src/app/services/api.generated";
import TableSkeletonRow from "src/components/molecule/TableSkeletonRow";
import DataTable from "src/components/organization/DataTable";
import { walletWithdrawColumns } from "src/sampleData/walletWithdraw";
import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import BreadCrumbs from "../components/organization/layout/breadcrumbs/BreadCrumbs";
import { nameIdType } from "src/types/general.types";

const WalletWithdraw: FC = () => {
  const [selectedUser, setSelectedUser] = useState<nameIdType>({
    name: "همه",
    id: "00000000-0000-0000-0000-000000000000",
  });

  const handleReset = () => {
    setSelectedUser({
      name: "همه",
      id: "00000000-0000-0000-0000-000000000000",
    });
  };

  const { data, isLoading } = useGetApiWalletWithdrawListQuery({
    userId: selectedUser.id,
  });

  const { data: userList } = useGetApiUserShortListQuery();

  const WalletWithdrawSearchBar = (
    <Stack
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-around"
      alignItems="center"
      gap={1}
      sx={{ p: 2 }}
    >
      {userList && (
        <Autocomplete
          disableClearable
          fullWidth
          size="small"
          disablePortal
          value={selectedUser}
          getOptionLabel={(option: nameIdType) => option.name}
          options={[
            { name: "همه", id: "00000000-0000-0000-0000-000000000000" },
            ...userList?.map((user) => user as nameIdType),
          ]}
          onChange={(_, value: nameIdType | null) =>
            setSelectedUser((prevState) => (value ? value : prevState))
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField {...params} label="کاربر" />}
        />
      )}
      <Stack direction="row" sx={{ width: "100%" }} justifyContent="end">
        <Button
          variant="contained"
          onClick={handleReset}
          sx={{ width: { xs: "100%", sm: 200 }, fontSize: 16 }}
        >
          ریست
        </Button>
      </Stack>
    </Stack>
  );

  return (
    <>
      <BreadCrumbs />
      {isLoading ? (
        <TableSkeletonRow title="مدیریت عودت وجه" cellCount={6} />
      ) : (
        <DataTable
          searchBar={WalletWithdrawSearchBar}
          rows={data || []}
          columns={walletWithdrawColumns}
          title="مدیریت عودت وجه"
        />
      )}
    </>
  );
};

export default WalletWithdraw;
