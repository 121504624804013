import { FC, useEffect, useMemo, useState } from "react";
import { Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import GenericForm from "../../organization/GenericForm";
import fields from "src/sampleData/dialogs/AddProduct";
import { FormButtonType } from "src/types/genericFrom.types";
import {
  GetProductByIdResponse,
  useGetApiInsuranceCompanyListQuery,
  useGetApiProductCategoryListQuery,
  usePutApiProductEditMutation,
} from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { BaseDialogType } from "src/types/dialog.types";
import { useLazyGetApiProductGetByIdQuery } from "src/app/services/api";
import { listItemsEnhancer } from "src/utils/ListItemsEnhancer";

type EditProductDialogPropsType = {
  id: number;
};

export const EditProductDialog: FC<
  EditProductDialogPropsType & BaseDialogType
> = ({ id, open, handleClose }) => {
  const [state, setState] = useState({
    name: "",
    productCategoryId: "",
    insuranceCompanyId: "",
    description: "",
    wagePercent: "",
    markupPrice: "",
    planId: "",
    isDisabled: false,
    needConfirm: false,
    productCommissionIsPercent: false,
    productCommissionValue: "",
  });

  const { data: insuranceCompanyList } = useGetApiInsuranceCompanyListQuery();
  const { data: productCategoryList } = useGetApiProductCategoryListQuery();

  const [ProductInfo] = useLazyGetApiProductGetByIdQuery();

  useEffect(() => {
    if (open) {
      ProductInfo({ id })
        .unwrap()
        .then((res) => {
          if (res) {
            const enhancedData = res as GetProductByIdResponse;

            const categoryId =
              productCategoryList &&
              productCategoryList.find(
                (item) => item.id === enhancedData.productCategoryId
              )?.id;

            setState({
              name: enhancedData.name || "",
              productCategoryId: categoryId ? categoryId.toString() : "",
              insuranceCompanyId:
                enhancedData.insuranceCompanyId?.toString() || "",
              description: enhancedData.description || "",
              wagePercent: enhancedData.wagePercent?.toString() || "",
              markupPrice: enhancedData.markupPrice?.toString() || "",
              planId: enhancedData.planId?.toString() || "",
              isDisabled: !!enhancedData.isDisabled,
              needConfirm: !!enhancedData.needConfirm,
              productCommissionIsPercent:
                !!enhancedData.productCommissionIsPercent,
              productCommissionValue:
                enhancedData.productCommissionValue?.toString() || "",
            });
          }
        });
    }
  }, [ProductInfo, id, open, productCategoryList]);

  const [editProduct, { isLoading: editProductLoading }] =
    usePutApiProductEditMutation();

  const onSubmit = () => {
    const {
      name,
      insuranceCompanyId,
      description,
      wagePercent,
      markupPrice,
      planId,
      isDisabled,
      needConfirm,
      productCommissionIsPercent,
      productCommissionValue,
    } = state;

    editProduct({
      editProductModel: {
        id,
        name,
        insuranceCompanyId: Number(insuranceCompanyId),
        description,
        wagePercent: Number(wagePercent),
        markupPrice: Number(markupPrice),
        planId: Number(planId),
        isDisabled,
        needConfirm,
        productCommissionIsPercent,
        productCommissionValue: Number(productCommissionValue),
      },
    })
      .unwrap()
      .then(() => {
        toast.success("محصول جدید با موفقیت ایجاد شد");
        handleClose();
      });
  };

  const buttons: FormButtonType[] = [
    {
      onClick: handleClose,
      text: "انصراف",
      variant: "outlined",
      color: "primary",
      checkRequiredField: false,
    },
    {
      onClick: onSubmit,
      text: "تایید",
      variant: "contained",
      color: "primary",
      checkRequiredField: true,
      isLoading: editProductLoading,
    },
  ];

  const enhancedFields = useMemo(() => {
    let result = [...fields];
    result.forEach((item) => {
      if (item.name === "insuranceCompanyId" && insuranceCompanyList) {
        item.props.items = listItemsEnhancer(insuranceCompanyList);
      }
      if (item.name === "productCategoryId" && productCategoryList) {
        item.props.items = listItemsEnhancer(productCategoryList);
        item.props.disabled = true;
        item.props.required = false;
      }
      if (item.name === "productCommissionValue") {
        item.props = {
          ...item.props,
          InputProps: {
            endAdornment: state.productCommissionIsPercent ? "درصد" : "ریال",
          },
        };
      }
    });
    return result;
  }, [
    insuranceCompanyList,
    productCategoryList,
    state.productCommissionIsPercent,
  ]);

  return (
    <Dialog
      maxWidth="md"
      PaperProps={{
        sx: { width: "100%" },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>ویرایش محصول</DialogTitle>
      <DialogContent>
        <Box sx={{ paddingTop: 1 }}>
          <GenericForm
            state={state}
            setState={setState}
            fields={enhancedFields}
            buttons={buttons}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
