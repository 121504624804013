import { createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1344,
    },
  },
  direction: "rtl",
  palette: {
    primary: { main: "#0277bd" },
  },
  typography: {
    fontFamily: "iransansxv",
    button: {
      textTransform: "capitalize",
    },
  },
  components: {
    MuiSkeleton: {
      defaultProps: {
        animation: "wave",
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
      },
    },
  },
});

export default theme;
