import type { FC } from "react";
import { useState } from "react";
import {
  Toolbar,
  Box,
  Divider,
  SwipeableDrawer,
  List,
  Collapse,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import {
  AccountBalanceWalletOutlined as AccountBalanceWalletOutlinedIcon,
  DashboardOutlined as DashboardOutlinedIcon,
  ReceiptOutlined as ReceiptOutlinedIcon,
  StoreOutlined as StoreOutlinedIcon,
  PermIdentityOutlined as PermIdentityOutlinedIcon,
  HeadsetMic as HeadsetMicIcon,
  CreditCardOutlined as CreditCardOutlinedIcon,
  AccountBalance as AccountBalanceIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { drawerWidth } from "../../../template/MainTemplate";

const array = [
  {
    title: "سفارشات",
    icon: <ReceiptOutlinedIcon fontSize='small' />,
    path: "/orders",
  },
  {
    title: "محصولات",
    icon: <StoreOutlinedIcon fontSize='small' />,
    path: "/products",
  },
  {
    title: "کاربران",
    icon: <PermIdentityOutlinedIcon fontSize='small' />,
    path: "/users",
  },
  {
    title: "بازاریابان",
    icon: <PermIdentityOutlinedIcon fontSize='small' />,
    path: "/marketers",
  },
  {
    title: "پشتیبانی",
    icon: <HeadsetMicIcon fontSize='small' />,
    path: "/support",
  },
  // ================================
  {
    title: "گزارش فاکتورها",
    icon: <ReceiptOutlinedIcon fontSize='small' />,
    path: "/invoice",
  },
  {
    title: "مدیریت عودت وجه",
    icon: <ArrowBackIcon fontSize='small' />,
    path: "/walletWithdraw",
  },
  {
    title: "مدیریت واریز وجه",
    icon: <ArrowForwardIcon fontSize='small' />,
    path: "/walletDeposit",
  },
  {
    title: "گزارش کیف پول",
    icon: <AccountBalanceWalletOutlinedIcon fontSize='small' />,
    path: "/walletTransaction",
  },
  {
    title: "کیف پول",
    icon: <CreditCardOutlinedIcon fontSize='small' />,
    path: "/wallet",
  },
  {
    title: "گزارش پرداخت آنلاین",
    icon: <AccountBalanceIcon fontSize='small' />,
    path: "/payment",
  },
  // ================================
  {
    title: "موضوعات پشتیبانی",
    icon: <HeadsetMicIcon fontSize='small' />,
    path: "/supportSubject",
  },
];

type TemporaryDrawerPropsType = {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
};

const TemporaryDrawer: FC<TemporaryDrawerPropsType> = ({
  handleDrawerToggle,
  mobileOpen,
}) => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  const [open, setOpen] = useState([false, false, false]);
  const { pathname: appCurrentPath } = useLocation();

  const isSelected = (paths: string[]) => {
    const index = paths.findIndex((path) => appCurrentPath === path);
    if (index >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const isParentSelected = ({
    paths,
    parentIndex,
  }: {
    paths: string[];
    parentIndex: number;
  }) => {
    if (open[parentIndex]) return;
    const idx = paths.findIndex((path) => appCurrentPath === path);
    if (idx >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const listItemClickHandler = (index: number) => {
    setOpen((prevState) => {
      let newState = [false, false, false];
      newState[index] = !prevState[index];
      return newState;
    });
  };

  const navigate = useNavigate();

  const listSubItemClickHandler = (path: string) => {
    !isLg && handleDrawerToggle();
    navigate(path);
  };

  const drawerBoxItems = ({
    listArray,
    title,
    index,
  }: {
    listArray: any[];
    title: string;
    index: number;
  }) => (
    <>
      <ListItemButton
        selected={isParentSelected({
          paths: listArray.map(({ path }) => path),
          parentIndex: index,
        })}
        onClick={() => listItemClickHandler(index)}>
        {open[index] ? <ExpandLess /> : <ExpandMore />}
        <ListItemText
          primaryTypographyProps={{
            sx: {
              color: "black",
              fontWeight: "500",
              fontSize: "0.9rem",
            },
          }}
          sx={{ ml: 2 }}
          primary={title}
        />
      </ListItemButton>
      <Collapse in={open[index]} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {listArray.map(({ icon, path, title }, index) => (
            <ListItemButton
              selected={isSelected([path])}
              onClick={() => listSubItemClickHandler(path)}
              key={index}
              sx={{ pl: 4 }}>
              <ListItemIcon sx={{ minWidth: "auto" }}>{icon}</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontWeight: "400",
                    fontSize: "0.85rem",
                  },
                }}
                sx={{ ml: 1 }}
                primary={title}
              />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );

  const drawer = (
    <Box>
      <Toolbar />
      <Divider />
      <List sx={{ overflow: "auto" }}>
        <ListItemButton
          selected={isSelected(["/"])}
          onClick={() => {
            setOpen([false, false, false]);
            listSubItemClickHandler("/");
          }}>
          <ListItemIcon sx={{ minWidth: "auto" }}>
            <DashboardOutlinedIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              sx: {
                color: "black",
                fontWeight: "500",
                fontSize: "0.9rem",
              },
            }}
            sx={{ ml: 2 }}
            primary='صفحه اصلی'
          />
        </ListItemButton>
        {drawerBoxItems({
          listArray: array.slice(0, 5),
          title: "پورتال",
          index: 0,
        })}
        {drawerBoxItems({
          listArray: array.slice(5, 11),
          title: "مدیریت مالی",
          index: 1,
        })}
        {drawerBoxItems({
          listArray: array.slice(-1),
          title: "تنظیمات",
          index: 2,
        })}
      </List>
    </Box>
  );

  return (
    <SwipeableDrawer
      variant={isLg ? "persistent" : "temporary"}
      open={mobileOpen}
      onOpen={() => {}}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        display: "block",
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: drawerWidth,
        },
      }}>
      {drawer}
    </SwipeableDrawer>
  );
};

export default TemporaryDrawer;
