import { FieldType } from "src/types/genericFrom.types";

const data: FieldType[] = [
  {
    component: "Text",
    name: "name",
    props: {
      label: "عنوان",
      required: true,
      width: "full",
    },
  },
  {
    component: "DropDown",
    name: "businessUnitId",
    props: {
      label: "واحد سازمانی",
      required: true,
      items: [],
      width: "full",
    },
  },
];

export default data;
