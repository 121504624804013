import { FC, useState } from "react";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import {
  ActionButtonModes,
  ActionButtonType,
} from "src/types/actionButton.types";
import { ActionItem } from "./ActionItem";

type ActionButtonPropsType = {
  action: ActionButtonType<any>;
  row: {
    [x: string]: string | number;
  };
};

export const ActionButton: FC<ActionButtonPropsType> = ({ action, row }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const onClick =
    action.type === ActionButtonModes.DROPDOWN ? handleMenu : () => {};

  return (
    <>
      {action.type === ActionButtonModes.DROPDOWN ? (
        <>
          {action.icon ? (
            <IconButton onClick={onClick}>
              <action.icon sx={{ color: `${action.color}` }} />
            </IconButton>
          ) : (
            <Button
              onClick={onClick}
              sx={{
                // color: `${action.color} !important`,
                // borderColor: action.color,
              }}
            >
              {action.label}
            </Button>
          )}
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {action.items.map((item, index) => (
              <ActionItem
                ItemComponent={MenuItem}
                key={index}
                item={item}
                row={row}
              />
            ))}
          </Menu>
        </>
      ) : (
        <ActionItem
          ItemComponent={action.icon ? IconButton : Button}
          item={{
            ...action.action,
            icon: action.icon,
            label: action.label,
            color: action.color,
          }}
          row={row}
        />
      )}
    </>
  );
};
