import type { FC } from "react";
import {
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

type TableSkeletonRowPropsType = {
  rowCount?: number;
  cellCount?: number;
  title: string;
};

const TableSkeletonRow: FC<TableSkeletonRowPropsType> = ({
  rowCount = 5,
  cellCount = 7,
  title,
}) => {
  return (
    <Paper
      sx={{
        mb: 2,
        borderRadius: "4px",
        boxShadow:
          "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        sx={{
          width: "100%",
          backgroundColor: "#7986cb",
          height: "48px",
          borderColor: "#7986cb",
          borderRadius: "4px 4px 0 0",
        }}
      >
        <Typography
          sx={{
            margin: "auto 0",
            fontSize: "1.25rem",
            lineHeight: "1.5",
            color: "#fff",
            fontFamily: "iransansxv",
          }}
        >
          {title}
        </Typography>
      </Stack>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              {[...Array(cellCount)].map((_, index) => (
                <TableCell key={index} sx={{ height: "70px" }}>
                  <Skeleton></Skeleton>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(rowCount)].map((_, index) => (
              <TableRow key={index}>
                {[...Array(cellCount)].map((_, index) => (
                  <TableCell key={index} sx={{ height: "70px" }}>
                    <Skeleton></Skeleton>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableSkeletonRow;
