import { FC, useState } from "react";
import { useGetApiProductListQuery } from "src/app/services/api.generated";
import TableSkeletonRow from "src/components/molecule/TableSkeletonRow";
import DataTable from "src/components/organization/DataTable";
import { productsColumns } from "src/sampleData/products";
import BreadCrumbs from "../components/organization/layout/breadcrumbs/BreadCrumbs";
import { Stack, Button } from "@mui/material";
import { AddProductDialog } from "src/components/molecule/dialogs/AddProductDialog";
import { Add } from "@mui/icons-material";

const Products: FC = () => {
  const [showDialog, setShowDialog] = useState(false);
  const { data, isLoading } = useGetApiProductListQuery();

  return (
    <>
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <BreadCrumbs spaceBottom={false} />
        <Button
          variant="contained"
          onClick={() => setShowDialog(true)}
          startIcon={<Add />}
        >
          افزودن محصول
        </Button>
      </Stack>
      <br />
      {isLoading ? (
        <TableSkeletonRow title="مدیریت محصولات" cellCount={8} />
      ) : (
        <DataTable
          rows={data || []}
          columns={productsColumns}
          title="مدیریت محصولات"
        />
      )}
      {showDialog && (
        <AddProductDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          maxWidth="md"
          PaperProps={{
            sx: { width: "100%" },
          }}
        />
      )}
    </>
  );
};

export default Products;
