import type { FC } from "react";
import { useGetApiWalletListQuery } from "src/app/services/api.generated";
import TableSkeletonRow from "src/components/molecule/TableSkeletonRow";
import DataTable from "src/components/organization/DataTable";
import { walletsColumns } from "src/sampleData/wallets";
import BreadCrumbs from "../components/organization/layout/breadcrumbs/BreadCrumbs";

const Wallets: FC = () => {
  const { data, isLoading } = useGetApiWalletListQuery();

  return (
    <>
      <BreadCrumbs />
      {isLoading ? (
        <TableSkeletonRow title="کیف پول" cellCount={4} />
      ) : (
        <DataTable rows={data || []} columns={walletsColumns} title="کیف پول" />
      )}
    </>
  );
};

export default Wallets;
