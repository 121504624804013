import { FC } from "react";
import { Container, Stack } from "@mui/material";

type LoginTemplatePropsType = {
  children: any;
};

const LoginTemplate: FC<LoginTemplatePropsType> = ({ children }) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "100%",
          height: "100vh",
          position: "relative",
          minHeight: "450px",
          minWidth: "300px",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            height: { xs: "100%", sm: "auto" },
            p: { xs: 0, sm: undefined },
            m: { xs: 0, sm: "auto" },
            borderRadius: ({ shape }) => ({
              xs: 0,
              sm: shape.borderRadius + "px",
            }),
          }}
        >
          {children}
        </Container>
      </Stack>
    </>
  );
};

export default LoginTemplate;
