import { FC, useState } from "react";
import { useGetApiSupportSubjectListQuery } from "src/app/services/api.generated";
import TableSkeletonRow from "src/components/molecule/TableSkeletonRow";
import DataTable from "src/components/organization/DataTable";
import { supportSubjectsColumns } from "src/sampleData/supportSubjects";
import BreadCrumbs from "../components/organization/layout/breadcrumbs/BreadCrumbs";
import { Stack, Button } from "@mui/material";
import { AddSupportSubjectDialog } from "src/components/molecule/dialogs/AddSupportSubjectDialog";
import { Add } from "@mui/icons-material";

const SupportTopics: FC = () => {
  const { data, isLoading } = useGetApiSupportSubjectListQuery();

  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <BreadCrumbs spaceBottom={false} />
        <Button
          startIcon={<Add />}
          variant="contained"
          onClick={() => setShowDialog(true)}
        >
          ایجاد موضوع پشتیبانی
        </Button>
      </Stack>
      <br />
      {isLoading ? (
        <TableSkeletonRow title="موضوعات پشتیبانی" cellCount={4} />
      ) : (
        <DataTable
          rows={data || []}
          columns={supportSubjectsColumns}
          title="موضوعات پشتیبانی"
        />
      )}
      {showDialog && (
        <AddSupportSubjectDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          maxWidth="sm"
          PaperProps={{
            sx: { width: "100%" },
          }}
        />
      )}
    </>
  );
};

export default SupportTopics;
