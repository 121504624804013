import { FieldType } from "src/types/genericFrom.types";

const data: FieldType[] = [
  {
    component: "AutoComplete",
    name: "userId",
    props: {
      label: "کاربر",
      required: false,
      items: [],
      width: "full",
    },
  },
  {
    component: "DropDown",
    name: "walletDepositTypeId",
    props: {
      label: "نوع درخواست",
      required: false,
      items: [
        { value: "1", text: "شارژ توسط مدیر" },
        { value: "2", text: "واریز در حساب بانکی" },
      ],
      width: "full",
      value: "1",
    },
  },
  {
    component: "Text",
    name: "price",
    props: {
      label: "مبلغ",
      required: false,
      width: "full",
      endAdornment: "ریال",
      isPrice: true,
    },
  },
];

export default data;
