import type { SvgIconComponent } from "@mui/icons-material";
import type { FC } from "react";
import { BaseDialogType } from "./dialog.types";

export enum ActionModes {
  REDIRECT,
  DIALOG,
  DOWNLOAD,
}

export enum DownloadApisEnum {
  DOWNLOAD_INSURANCE_POLICY,
}

export type ActionItemType<RowDataType> =
  | {
      type: ActionModes.REDIRECT;
      to: (props: Partial<RowDataType>) => string;
      props: (keyof RowDataType)[];
    }
  | {
      type: ActionModes.DIALOG;
      dialogComponent: FC<any & BaseDialogType>;
      props: (keyof RowDataType)[];
    }
  | {
      type: ActionModes.DOWNLOAD;
      props: (keyof RowDataType)[];
      api: DownloadApisEnum;
    };

export type DropdownActionItemType<RowDataType> =
  ActionItemType<RowDataType> & {
    label?: string;
    icon?: SvgIconComponent;
    color?: string;
  };
