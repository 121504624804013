import type { ActionButtonType } from "./actionButton.types";

export enum ColumnModes {
  DATA,
  ACTION,
}

export type BaseColumnType = { label?: string };

export type DataColumnType<RowDataType> = {
  type: ColumnModes.DATA;
  id: keyof RowDataType;
  chip: boolean;
  color: string | Map<string | number, string>;
  isPrice?: boolean;
};

export type ActionsColumnType<RowDataType> = {
  type: ColumnModes.ACTION;
  actions: ActionButtonType<RowDataType>[];
};

export type ColumnType<RowDataType> = BaseColumnType &
  (DataColumnType<RowDataType> | ActionsColumnType<RowDataType>);
