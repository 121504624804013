import type { FC } from "react";
import { useEffect, Fragment } from "react";
import {
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  useGetApiOrderGetByOrderIdQuery,
  useGetApiOrderGetOrderDetailByOrderIdQuery,
} from "src/app/services/api.generated";
import {
  useLazyGetApiCityByStateIdListQuery,
  useLazyGetApiStateListQuery,
} from "src/app/services/api";
import { priceToPersian } from "src/utils/priceToPersian";
import { GetApiOrderGetOrderDetailByOrderIdApiResponse_type2 } from "src/app/services/responses.type";
import { box2 } from "src/pages/OrderDetails";

const estateIdList = [
  {
    value: 1,
    text: "یک واحد آپارتمان",
  },
  {
    value: 2,
    text: "یک ساختمان ویلایی",
  },
  {
    value: 3,
    text: "آپارتمان یا مجتمع",
  },
];

const buildTypeIdList = [
  {
    value: 1,
    text: "آجری",
  },
  {
    value: 2,
    text: "فلزی",
  },
  {
    value: 3,
    text: "بتنی",
  },
];

type FireInsuranceOrderDetailsPropsType = {
  orderId: number;
};

export const FireInsuranceOrderDetails: FC<
  FireInsuranceOrderDetailsPropsType
> = ({ orderId }) => {
  const { data: orderById, isLoading: orderByIdLoading } =
    useGetApiOrderGetByOrderIdQuery({
      orderId: orderId!,
    });

  const { data: orderDetails, isLoading: orderDetailsLoading } =
    useGetApiOrderGetOrderDetailByOrderIdQuery({
      orderId: orderId!,
    });

  const orderDetailsEnhance = orderDetails as
    | GetApiOrderGetOrderDetailByOrderIdApiResponse_type2
    | undefined;

  const [getCityList, { data: cityList, isFetching: cityListLoading }] =
    useLazyGetApiCityByStateIdListQuery();

  const [getStateList, { data: stateList, isFetching: stateListLoading }] =
    useLazyGetApiStateListQuery();

  useEffect(() => {
    if (orderDetailsEnhance?.stateId) {
      getCityList({
        stateId: orderDetailsEnhance?.stateId,
      });
    }

    getStateList();
  }, [getCityList, getStateList, orderDetailsEnhance?.stateId]);

  const insurancePolicyDetailsSection = [
    {
      name: "دارای بیمه گر پایه",
      value: orderDetailsEnhance?.oldInsureInfo ? "بله" : "خیر",
    },
    {
      name: "استان",
      value:
        stateList &&
        stateList.find(({ id }) => id === orderDetailsEnhance?.stateId)?.name,
    },
    {
      name: "شهر",
      value:
        cityList &&
        cityList.find(({ id }) => id === orderDetailsEnhance?.cityId)?.name,
    },
    {
      name: "نشانی",
      value: orderDetailsEnhance?.address,
    },
    {
      name: "طبقه",
      value: orderDetailsEnhance?.addressFloor,
    },
    {
      name: "پلاک",
      value: orderDetailsEnhance?.addressNumber,
    },
    {
      name: "واحد",
      value: orderDetailsEnhance?.addressUnit,
    },
    {
      name: "مساحت",
      value: orderDetailsEnhance?.area + " متر مربع",
    },
    {
      name: "عمر سازه",
      value: orderDetailsEnhance?.buildAge + " سال",
    },
    {
      name: "ارزش بنا",
      value: orderDetailsEnhance?.buildingValue
        ? priceToPersian(orderDetailsEnhance?.buildingValue) + " ریال"
        : "",
    },
    {
      name: "نوع ساختمان",
      value: orderDetailsEnhance?.buildTypeId
        ? buildTypeIdList[orderDetailsEnhance?.buildTypeId].text
        : "",
    },
    {
      name: "نوع مالک",
      value: orderDetailsEnhance?.estateId
        ? estateIdList[orderDetailsEnhance?.estateId].text
        : "",
    },
    {
      name: "کد پستی",
      value: orderDetailsEnhance?.postalCode,
    },
    {
      name: "تلفن",
      value: orderDetailsEnhance?.tel,
    },
    {
      name: "کد پستی",
      value: orderDetailsEnhance?.postalCode,
    },
    {
      name: "تعداد طبقات",
      value: orderDetailsEnhance?.floors + " طبقه",
    },
    {
      name: "تعداد واحد",
      value: orderDetailsEnhance?.unit + " واحد",
    },
    {
      name: "سرمایه پوشش پاکسازی محل",
      value: orderDetailsEnhance?.cleaningTerm
        ? priceToPersian(orderDetailsEnhance?.cleaningTerm) + " ریال"
        : "",
    },
    {
      name: "ارزش اثاثیه",
      value: orderDetailsEnhance?.furnitureValue
        ? priceToPersian(orderDetailsEnhance?.furnitureValue) + " ریال"
        : "",
    },
    {
      name: "سرمایه پوشش مسئولیت مدنی",
      value: orderDetailsEnhance?.thirdPersonTerm
        ? priceToPersian(orderDetailsEnhance?.thirdPersonTerm) + " ریال"
        : "",
    },
    {
      name: "زلزله و آتشفشان",
      value: orderDetailsEnhance?.earthquakeAndVolcano ? "بله" : "خیر",
    },
    {
      name: "سیل",
      value: orderDetailsEnhance?.flood ? "بله" : "خیر",
    },
    {
      name: "ترکیدگی لوله",
      value: orderDetailsEnhance?.pipeBurst ? "بله" : "خیر",
    },
    {
      name: "سقوط هواپیما",
      value: orderDetailsEnhance?.planeCrash ? "بله" : "خیر",
    },
    {
      name: "ضایعات ناشی از برف و باران",
      value: orderDetailsEnhance?.snowAndRainDamage ? "بله" : "خیر",
    },
    {
      name: "سرقت با شکست حرز",
      value: orderDetailsEnhance?.stealByBreak ? "بله" : "خیر",
    },
    {
      name: "طوفان",
      value: orderDetailsEnhance?.storm ? "بله" : "خیر",
    },
    {
      name: "نشست زمین",
      value: orderDetailsEnhance?.subsidence ? "بله" : "خیر",
    },
  ];

  return (
    <>
      {(orderByIdLoading ||
        orderDetailsLoading ||
        cityListLoading ||
        stateListLoading) && (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      <Stack rowGap={5} sx={{ p: { xs: 2, sm: 4 } }}>
        <Typography variant="h6" align="center" fontWeight={600}>
          مشخصات بیمه نامه
        </Typography>
        <Grid container rowGap={{ xs: 0, sx: 2 }}>
          {insurancePolicyDetailsSection.map(({ name, value }, index) => (
            <Fragment key={index}>{box2({ name, value })}</Fragment>
          ))}
        </Grid>
        <Divider />
        <Typography variant="h6" align="center" fontWeight={600}>
          مشخصات کالای بیمه شده
        </Typography>
        <Grid container rowGap={{ xs: 0, sx: 2 }}>
          {box2({
            name: "ارزش کالای بیمه شده",
            value: orderById && priceToPersian(orderById.netPrice),
          })}
        </Grid>
        <Divider />
        <Typography variant="h6" align="center" fontWeight={600}>
          پرداخت
        </Typography>
        <Grid container rowGap={{ xs: 0, sx: 2 }}>
          {box2({
            name: "جمع کل",
            value: orderById && priceToPersian(orderById.totalPrice),
          })}
          {box2({
            name: "تخفیف",
            value: orderById && priceToPersian(orderById.discount),
          })}
          {box2({
            name: "مالیات بر ارزش افزوده",
            value: orderById && priceToPersian(orderById.vat),
          })}
          {box2({
            name: "مبلغ قابل پرداخت",
            value: orderById && priceToPersian(orderById.orderPrice),
          })}
        </Grid>
      </Stack>
    </>
  );
};
