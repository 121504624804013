import { GetApiWalletDepositListByUserIdApiResponse } from "src/app/services/api.generated";
import theme from "src/configs/theme/theme";
import { ColumnModes, ColumnType } from "src/types/genericTable.types";

const colors = new Map<string, string>([
  ["در انتظار تائید", theme.palette.warning.light],
  ["رد شده", theme.palette.error.main],
  ["تائید شده", theme.palette.success.light],
]);

export const walletDepositColumns: ColumnType<
  GetApiWalletDepositListByUserIdApiResponse[number]
>[] = [
  {
    type: ColumnModes.DATA,
    id: "id",
    label: "شماره پیگیری",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "user",
    label: "نام کاربر",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "transactionDate",
    label: "تاریخ",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "amount",
    label: "مبلغ",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    id: "walletDepositType",
    label: "نوع درخواست",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "walletDepositStatus",
    label: "وضعیت",
    chip: true,
    color: colors,
  },
];
