import { GetApiWalletTransactionListByUserIdApiResponse } from "src/app/services/api.generated";
import { ColumnModes, ColumnType } from "src/types/genericTable.types";

export const walletTransactionsColumns: ColumnType<
  GetApiWalletTransactionListByUserIdApiResponse[number]
>[] = [
  {
    type: ColumnModes.DATA,
    id: "id",
    label: "شماره تراکنش",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "user",
    label: "نام کاربر",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "transactionDate",
    label: "تاریخ",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "credit",
    label: "بدهکار",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    id: "debit",
    label: "بستانکار",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    id: "balance",
    label: "مانده",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    id: "description",
    label: "توضیحات",
    chip: false,
    color: "",
  },
];
