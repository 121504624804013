import { FC } from "react";
import { Grid } from "@mui/material";
import { FormField } from "../molecule/FormField";
import { FieldType, FormButtonType } from "src/types/genericFrom.types";
import { LoadingButton } from "@mui/lab";

type GenericFormPropsType = {
  state: any;
  setState: any;
  fields: FieldType[];
  buttons: FormButtonType[];
};

const GenericForm: FC<GenericFormPropsType> = ({
  state,
  setState,
  fields,
  buttons,
}) => {
  const hasRequiredFiles = fields.find((field) =>
    field.props.required &&
    (state?.[field.name] === "" ||
      state?.[field.name] === null ||
      state?.[field.name] === undefined)
      ? false
      : true
  );

  const btnClickHandler = (button: FormButtonType) => {
    let result: any;
    if (button.checkRequiredField) {
      if (hasRequiredFiles) {
        result = button.onClick();
      } else {
        result = undefined;
      }
    } else {
      result = button.onClick();
    }
    return result;
  };

  return (
    <Grid container spacing={2}>
      {fields.map((field, index) => (
        <Grid
          xs={12}
          sm={field?.props?.width === "half" ? 6 : 12}
          item
          key={index}
        >
          <FormField
            setValue={(value: any) =>
              setState({
                ...state,
                [field.name]: value,
              })
            }
            value={(state as any)?.[field.name]}
            component={field.component}
            props={field.props}
          />
        </Grid>
      ))}
      {buttons.length > 0 && (
        <Grid container item xs={12} justifyContent="flex-end" spacing={1}>
          {buttons.map((button, index) => (
            <Grid item key={index}>
              <LoadingButton
                loading={button.isLoading}
                onClick={() => btnClickHandler(button)}
                variant={button.variant}
                color={button.color}
                disabled={!!button.isLoading}
              >
                {button.text}
              </LoadingButton>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default GenericForm;
