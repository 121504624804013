import type { FC } from "react";
import { useState } from "react";
import {
  Container,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { passwordValidationRegex } from "src/utils/regexUtils";
import { Form, Formik } from "formik";
import type { formikOnSubmitType } from "src/types/formTypes";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { usePostApiProfileChangePasswordMutation } from "src/app/services/api.generated";
import BreadCrumbs from "../components/organization/layout/breadcrumbs/BreadCrumbs";
import { LockSvg } from "src/components/atoms/svg/LockSvg";

type inputsTypes = "oldPassword" | "newPassword" | "confirmPassword";

const inputsArray: { name: inputsTypes; label: string }[] = [
  { name: "oldPassword", label: "گذرواژه فعلی" },
  { name: "newPassword", label: "گذرواژه جدید" },
  { name: "confirmPassword", label: "تکرار گذرواژه جدید" },
];

const passValidationHandler = (value: string) =>
  !value ? false : passwordValidationRegex.test(value);

type ChangePasswordPropsType = {};

const ChangePassword: FC<ChangePasswordPropsType> = () => {
  const [showPassword, setShowPassword] = useState([false, false, false]);

  const showPasswordHandler = (index: number) => {
    setShowPassword((prevState) => {
      let result = [...prevState];
      result[index] = !prevState[index];
      return result;
    });
  };

  const [changePassword, { isLoading: changeLoading }] =
    usePostApiProfileChangePasswordMutation();

  const passwordYup = yup
    .string()
    .test(
      "passwordValidation",
      "گذرواژه باید بین 8 تا 20 کلمه و حداقل شامل یک حرف بزرگ، یک حرف کوچک و یک عدد باشد.",
      (value) => passValidationHandler(value as string)
    )
    .required("این بخش الزامی می‌باشد");

  const formValidationSchema = yup.object({
    oldPassword: passwordYup,
    newPassword: passwordYup,
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("newPassword"), undefined],
        "تکرار گذرواژه نادرست می‌باشد"
      )
      .required("این بخش الزامی می‌باشد"),
  });

  const formInitializer = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const formSubmitHandler: formikOnSubmitType<typeof formInitializer> = (
    { oldPassword, newPassword, confirmPassword },
    { setSubmitting, resetForm }
  ) => {
    changePassword({
      changePasswordModel: { oldPassword, newPassword, confirmPassword },
    })
      .unwrap()
      .then(() => {
        toast.success("گذرواژه شما با موفقیت تغییر کرد");
        setSubmitting(false);
        resetForm();
      });
  };

  return (
    <>
      <BreadCrumbs />
      <Container maxWidth="sm">
        <Paper sx={{ overflow: "hidden" }}>
          <Typography
            variant="h6"
            align="center"
            fontWeight={500}
            sx={{
              backgroundColor: "#7986cb",
              color: "white",
              py: 1,
            }}
          >
            تغییر رمز عبور
          </Typography>
          <Formik
            initialValues={formInitializer}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({ errors, touched, getFieldProps }) => (
              <Form autoComplete="off">
                <Stack
                  spacing={3}
                  justifyContent="space-between"
                  sx={{ p: { xs: 2, md: 4 } }}
                >
                  {inputsArray.map(({ name, label }, index) => (
                    <TextField
                      key={index}
                      size="small"
                      label={label + " *"}
                      fullWidth
                      type={showPassword[index] ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => showPasswordHandler(index)}
                              edge="end"
                            >
                              {showPassword[index] ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockSvg fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(errors[name] && touched[name])}
                      helperText={errors[name]}
                      {...getFieldProps(name)}
                    />
                  ))}
                  <LoadingButton
                    loading={changeLoading}
                    variant="contained"
                    type="submit"
                    sx={{ width: "200px" }}
                  >
                    تغییر گذرواژه
                  </LoadingButton>
                </Stack>
              </Form>
            )}
          </Formik>
        </Paper>
      </Container>
    </>
  );
};

export default ChangePassword;
