import { ChangeEvent, FC } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import {
  FieldComponent,
  FieldPropsType,
  textValueType,
} from "src/types/genericFrom.types";
import { onlyNumber, priceToPersian } from "src/utils/priceToPersian";

type FieldComponentPropsType = {
  value: any;
  setValue: any;
  component: FieldComponent;
  props: FieldPropsType;
};

export const FormField: FC<FieldComponentPropsType> = ({
  component,
  props,
  value,
  setValue,
}) => {
  switch (component) {
    case "AutoComplete":
      const options = props.items || [];
      const autoCompleteValue =
        options.find((option) => option.value === value && option) || null;

      return (
        <Autocomplete
          disableClearable={props.required}
          fullWidth
          disablePortal
          disabled={props.disabled}
          value={autoCompleteValue}
          getOptionLabel={(option: textValueType) => option.text}
          options={props.items || []}
          onChange={(_, value: textValueType | null) =>
            value !== null && setValue(value.value)
          }
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => (
            <TextField
              {...params}
              required={props.required}
              error={props.required && value === ""}
              label={props.label}
            />
          )}
        />
      );
    case "DropDown":
      return (
        <FormControl
          error={props.required && value === ""}
          fullWidth
          required={props.required}
          disabled={props.disabled}
        >
          <InputLabel>{props.label}</InputLabel>
          <Select
            value={value}
            onChange={(e) => setValue(e.target.value)}
            label={props.label}
          >
            {props.items?.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case "Switch":
      return (
        <FormControl
          fullWidth
          required={props.required}
          disabled={props.disabled}
        >
          <FormControlLabel
            control={
              <Switch
                checked={!!value}
                onChange={(e) => setValue(e.target.checked)}
              />
            }
            label={props.label}
          />
        </FormControl>
      );
    case "Text":
      const textFieldChangeHandler: (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => void = ({ target: { value: newValue } }) => {
        let result = newValue;
        if (props.type === "number" && !props.isPrice) {
          result = onlyNumber(newValue);
        }
        if (props.isPrice) {
          result = newValue.replaceAll(",", "");
        }
        setValue(result);
      };

      return (
        <FormControl fullWidth>
          <TextField
            disabled={props.disabled}
            required={props.required}
            type={props.type}
            onChange={textFieldChangeHandler}
            value={props.isPrice ? priceToPersian(value) : value}
            label={props.label}
            multiline={props.multiline}
            rows={props.rows}
            error={props.required && value === ""}
            hidden
            helperText={
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  {props.helperText ||
                    (props.required && value === ""
                      ? "این فیلد اجباری است."
                      : null)}
                </span>
                <span>
                  {props.maxLength
                    ? `${value ? value.toString().length : 0}/${
                        props.maxLength
                      }`
                    : ""}
                </span>
              </span>
            }
            inputProps={{ maxLength: props.maxLength }}
            InputProps={
              props.InputProps
                ? props.InputProps
                : {
                    startAdornment: props.startAdornment ? (
                      <InputAdornment position="start">
                        {typeof props.startAdornment === "string" ? (
                          props.startAdornment
                        ) : (
                          <props.startAdornment />
                        )}
                      </InputAdornment>
                    ) : null,
                    endAdornment: props.endAdornment ? (
                      <InputAdornment position="end">
                        {typeof props.endAdornment === "string" ? (
                          props.endAdornment
                        ) : (
                          <props.endAdornment />
                        )}
                      </InputAdornment>
                    ) : null,
                  }
            }
          />
        </FormControl>
      );
    default:
      return <></>;
  }
};
