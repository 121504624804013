import { ColumnModes, ColumnType } from "src/types/genericTable.types";
import { Receipt as ReceiptIcon } from "@mui/icons-material";
import { ActionButtonModes } from "src/types/actionButton.types";
import { ActionModes } from "src/types/actionItems.types";
import { GetApiInvoiceListByUserIdAndInvoiceStatusIdApiResponse } from "src/app/services/api.generated";
import theme from "src/configs/theme/theme";

const colors = new Map<string, string>([
  ["پرداخت نشده", theme.palette.warning.light],
  ["ابطال شده", theme.palette.error.main],
  ["پرداخت شده", theme.palette.success.light],
]);

export const invoicesColumns: ColumnType<
  GetApiInvoiceListByUserIdAndInvoiceStatusIdApiResponse[number]
>[] = [
  {
    type: ColumnModes.DATA,
    id: "id",
    label: "شماره فاکتور",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "emailUser",
    label: "نام کاربر",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "invoiceDate",
    label: "تاریخ",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "price",
    label: "جمع کل",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    id: "vat",
    label: "ارزش افزوده",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    id: "discount",
    label: "تخفیف",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    id: "invoicePrice",
    label: "مبلغ فاکتور",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    id: "invoiceStatus",
    label: "وضعیت",
    chip: true,
    color: colors,
  },
  {
    type: ColumnModes.ACTION,
    label: "عملیات",
    actions: [
      {
        icon: ReceiptIcon,
        type: ActionButtonModes.SINGLE,
        action: {
          type: ActionModes.REDIRECT,
          props: ["id"],
          to: ({ id }) => "/invoice/" + id,
        },
      },
    ],
  },
];
