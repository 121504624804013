import type { FC } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { InvoiceItemListResponse } from "src/app/services/api.generated";
import { priceToPersian } from "src/utils/priceToPersian";

type SalesInvoiceTablePropsType = {
  data: InvoiceItemListResponse[];
};

const SalesInvoiceTable: FC<SalesInvoiceTablePropsType> = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead sx={{ bgcolor: "#eee" }}>
          <TableRow>
            <TableCell align="left">کالا</TableCell>
            <TableCell align="left">تعداد</TableCell>
            <TableCell align="left">مبلغ</TableCell>
            <TableCell align="left">جمع کل</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ product, quantity, price, totalPrice }, index) => (
            <TableRow key={index}>
              <TableCell align="left">{product}</TableCell>
              <TableCell align="left">{priceToPersian(quantity)}</TableCell>
              <TableCell align="left">{priceToPersian(price)}</TableCell>
              <TableCell align="left">{priceToPersian(totalPrice)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SalesInvoiceTable;
