import { FC, useEffect, useState } from "react";
import { Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import GenericForm from "../../organization/GenericForm";
import { FieldType, FormButtonType } from "src/types/genericFrom.types";
import {
  EditPrice,
  usePutApiProductPriceEditMutation,
  useGetApiProductPriceListByProductIdQuery,
} from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { BaseDialogType } from "src/types/dialog.types";

type ProductPriceManagementPropsType = {
  id: number;
};

export const ProductPriceManagement: FC<
  ProductPriceManagementPropsType & BaseDialogType
> = ({ id, open, handleClose }) => {
  const [state, setState] = useState<any>({});
  const [fields, setFields] = useState<FieldType[]>([]);

  const [editProductPrice, { isLoading }] = usePutApiProductPriceEditMutation();

  const { data: priceList, refetch } =
    useGetApiProductPriceListByProductIdQuery({
      productId: id,
    });

  useEffect(() => {
    !isLoading && refetch();
  }, [id, isLoading, refetch]);

  useEffect(() => {
    if (priceList) {
      const newFields: any[] = [];
      const newState: any = {};
      priceList.forEach(({ persianName, isPercent, value }, index) => {
        newFields.push({
          component: "Text",
          name: `price-${index}`,
          props: {
            label: persianName,
            required: false,
            width: "half",
            endAdornment: isPercent ? "درصد" : "ریال",
          },
        });
        newState[`price-${index}`] = value;
      });
      setState(newState);
      setFields(newFields);
    }
  }, [priceList]);

  const onSubmit = () => {
    const newEditPrices: EditPrice[] = [];
    priceList?.forEach((price, index) => {
      if (price.id) {
        newEditPrices.push({
          productPriceId: price.id,
          value: state[`price-${index}`],
        });
      }
    });

    editProductPrice({
      editPriceModel: {
        productId: id,
        editPrices: newEditPrices,
      },
    })
      .unwrap()
      .then(() => {
        toast.success("قیمت محصولات با موفقیت به‌روز شد.");
        handleClose();
      });
  };

  const buttons: FormButtonType[] = [
    {
      onClick: handleClose,
      text: "انصراف",
      variant: "outlined",
      color: "primary",
      checkRequiredField: false,
    },
    {
      onClick: onSubmit,
      text: "اعمال ویرایش",
      variant: "contained",
      color: "primary",
      checkRequiredField: true,
      isLoading: isLoading,
    },
  ];

  return (
    <Dialog
      maxWidth="md"
      PaperProps={{
        sx: { width: "100%" },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>آیتم قیمت محصول</DialogTitle>
      <DialogContent>
        <Box sx={{ paddingTop: 1 }}>
          <GenericForm
            state={state}
            setState={setState}
            fields={fields}
            buttons={buttons}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
