import type { FC, MouseEvent } from "react";
import { useState } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { usePostApiAccountLoginMutation } from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { formikOnSubmitType } from "src/types/formTypes";
import { useNavigate } from "react-router";
import { LoadingButton } from "@mui/lab";

const SignIn: FC = () => {
  const navigate = useNavigate();

  const [loginUser, { isLoading }] = usePostApiAccountLoginMutation();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const showPasswordHandler = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const frequentValidation = yup
    .string()
    .min(3, "مقدار وارد شده بیش از حد کوتاه است")
    .max(50, "مقدار وارده شده بیش از حد بلند است")
    .required("این بخش الزامی است");

  const formInitialValues = {
    email: "",
    password: "",
  };
  const formValidation = yup.object().shape({
    email: frequentValidation,
    password: frequentValidation,
  });
  const submitHandler: formikOnSubmitType<typeof formInitialValues> = (
    { email, password },
    { setSubmitting }
  ) => {
    loginUser({ loginModel: { email, password } })
      .unwrap()
      .then((res) => {
        if (res) {
          toast.success("شما با موفقیت وارد شدید");
          navigate("/");
        }
      })
      .catch(({ status }: { status: number }) => {
        status === 401 &&
          toast.error("نام‌کاربری یا گذرواژه وارده شده اشتباه است");
      });
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidation}
      onSubmit={submitHandler}
    >
      {({ errors, touched, getFieldProps }) => (
        <Form autoComplete="on">
          <Stack
            rowGap={{ xs: 4, sm: 6 }}
            sx={{ maxWidth: "400px", mx: "auto" }}
          >
            <Typography fontSize={20} fontWeight={700}>
              ورود به داشبورد مدیریت کارگزاری آنلاین
            </Typography>
            <TextField
              size="small"
              fullWidth
              inputProps={{
                enterKeyHint: "next",
                autoComplete: "on",
                name: "email",
              }}
              label="نام کاربری *"
              autoFocus
              error={Boolean(errors.email && touched.email)}
              helperText={errors.email}
              {...getFieldProps("email")}
            />
            <TextField
              size="small"
              fullWidth
              label="گذرواژه *"
              type={showPassword ? "text" : "password"}
              inputProps={{
                enterKeyHint: "done",
                autoComplete: "on",
                name: "password",
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={showPasswordHandler}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.password && touched.password)}
              helperText={errors.password}
              {...getFieldProps("password")}
            />
            <Stack justifyContent="center" alignItems="center">
              <LoadingButton
                loading={isLoading}
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                ورود
              </LoadingButton>
            </Stack>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default SignIn;
