import {
  Box,
  Grid,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  usePostApiReportListMutation,
  PostApiReportListApiResponse,
} from "src/app/services/api.generated";
import { dateFormatter } from "src/utils/DateFormatter";
import { priceToPersian } from "src/utils/priceToPersian";
import AdapterJalali from "@date-io/date-fns-jalali";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";

const box = ({
  isLoading,
  title,
  count,
  amount,
}: {
  isLoading?: boolean;
  title: string;
  count?: number;
  amount: boolean;
}) => (
  <Grid item xs={12} sm={5.8} md={3.8} lg={2.8}>
    <Paper
      elevation={1}
      sx={{
        borderRadius: 5,
        overflow: "hidden",
        height: { xs: "130px", sm: "150px", md: "180px" },
      }}
    >
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          sx={{ height: "100%", width: "100%" }}
        />
      ) : (
        <Stack
          alignItems="center"
          justifyContent="space-evenly"
          sx={{
            height: "100%",
            width: "100%",
            p: { xs: 1, sm: 1.5, md: 2 },
          }}
        >
          <Typography align="center">
            {amount ? "مبلغ" : "تعداد"} کل {title}
          </Typography>
          <Typography align="center" variant="h5" fontWeight={700}>
            {priceToPersian(count || 0)}
          </Typography>
          <Typography
            sx={{ color: ({ palette }) => palette.grey[500] }}
            variant="caption"
            align="center"
          >
            {`${amount ? "مبلغ" : "تعداد"} کل ${title} تاکنون ${priceToPersian(
              count || 0
            )} می‌باشد`}
          </Typography>
        </Stack>
      )}
    </Paper>
  </Grid>
);

const Home: FC = () => {
  const [getReport, { isLoading }] = usePostApiReportListMutation();
  const [data, setData] = useState<PostApiReportListApiResponse | undefined>();
  const [date, setDate] = useState();

  useEffect(() => {
    getReport({ filterReportModel: { today: dateFormatter({ date }) } })
      .unwrap()
      .then(setData);
  }, [getReport, date]);

  return (
    <Stack spacing={2}>
      <Box>
        <LocalizationProvider dateAdapter={AdapterJalali}>
          <DatePicker
            label="تاریخ گزارشات"
            mask="____/__/__"
            value={date}
            onChange={(newValue: any) => setDate(newValue)}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
        </LocalizationProvider>
      </Box>
      <Grid container justifyContent="space-between" rowGap={3}>
        {box({
          isLoading: isLoading,
          title: "سفارشات",
          count: data?.orderPrice,
          amount: true,
        })}
        {box({
          isLoading: isLoading,
          title: "حق بیمه",
          count: data?.insurancePrice,
          amount: true,
        })}
        {box({
          isLoading: isLoading,
          title: "مارکاپ",
          count: data?.markupPrice,
          amount: true,
        })}
        {box({
          isLoading: isLoading,
          title: "کارمزدها",
          count: data?.marketerPrice,
          amount: true,
        })}
        {box({
          isLoading: isLoading,
          title: "اعتبارات",
          count: data?.walletBalance,
          amount: true,
        })}
        {box({
          isLoading: isLoading,
          title: "سفارشات",
          count: data?.order,
          amount: false,
        })}
        {box({
          isLoading: isLoading,
          title: "کاربران",
          count: data?.user,
          amount: false,
        })}
        {box({
          isLoading: isLoading,
          title: "استعلام همتا",
          count: data?.hamta,
          amount: false,
        })}
        {box({
          isLoading: isLoading,
          title: "استعلام ثبت احوال",
          count: data?.identity,
          amount: false,
        })}
        {box({
          isLoading: isLoading,
          title: "پیامک‌های ارسالی تبلیغ",
          count: data?.promotionSend,
          amount: false,
        })}
        {box({
          isLoading: isLoading,
          title: "کلیک‌ها روی پیامک تبلیغ",
          count: data?.promotionClick,
          amount: false,
        })}
        {box({
          isLoading: isLoading,
          title: "شماره‌های گرفته شده از همتا",
          count: data?.hamtaPromotion,
          amount: false,
        })}
        {box({
          isLoading: isLoading,
          title: "خطا‌ها",
          count: data?.errors,
          amount: false,
        })}
      </Grid>
    </Stack>
  );
};

export default Home;
