import { GetApiNotificationListApiResponse } from "src/app/services/api.generated";
import { ColumnModes, ColumnType } from "src/types/genericTable.types";

export const notificationColumns: ColumnType<
  GetApiNotificationListApiResponse[number]
>[] = [
  {
    type: ColumnModes.DATA,
    label: "تاریخ",
    id: "notificationDate",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "موضوع",
    id: "notificationSubject",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "محتوا",
    id: "content",
    chip: false,
    color: "",
  },
];
