import type { FC } from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { BaseDialogType } from "src/types/dialog.types";
import { grey, orange } from "@mui/material/colors";
import { useDeleteApiUserDeleteByIdMutation } from "src/app/services/api.generated";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

export type DeleteUserDialogPropsType = { id: number };

export const DeleteUserDialog: FC<
  DeleteUserDialogPropsType & BaseDialogType
> = ({ open, handleClose, id }) => {
  const [deleteUser, { isLoading }] = useDeleteApiUserDeleteByIdMutation();

  const deleteOrder = () => {
    deleteUser({ id: id.toString() })
      .unwrap()
      .then(() => {
        toast.success("کاربر مورد نظر با موفقیت حذف شد");
      });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        color="white"
        align="center"
        sx={{ mb: { xs: 2, md: 4 }, backgroundColor: orange[300], p: 2 }}
        fontWeight={900}
      >
        هشدار
      </DialogTitle>
      <DialogContent>
        <DialogContentText align="center" fontWeight={600} sx={{ px: 2 }}>
          آیا از حذف این کاربر اطمینان دارید؟
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <LoadingButton
          loading={isLoading}
          color="error"
          variant="contained"
          sx={{ width: "100px" }}
          onClick={deleteOrder}
        >
          بله
        </LoadingButton>
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            width: "100px",
            color: grey[500],
            borderColor: grey[500],
            "&:hover": { borderColor: grey[700], color: grey[700] },
          }}
        >
          خیر
        </Button>
      </DialogActions>
    </Dialog>
  );
};
