import type { FC } from "react";
import { Fragment } from "react";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import {
  useGetApiOrderGetByOrderIdQuery,
  useGetApiOrderGetOrderDetailByOrderIdQuery,
} from "src/app/services/api.generated";
import { priceToPersian } from "src/utils/priceToPersian";
import { GetApiOrderGetOrderDetailByOrderIdApiResponse_type1 } from "src/app/services/responses.type";
import { box2 } from "src/pages/OrderDetails";
import Loading from "src/components/atoms/Loading";

type PhoneInsuranceOrderDetailsPropsType = {
  orderId: number;
};

export const PhoneInsuranceOrderDetails: FC<
  PhoneInsuranceOrderDetailsPropsType
> = ({ orderId }) => {
  const { data: orderById, isLoading: orderByIdLoading } =
    useGetApiOrderGetByOrderIdQuery({
      orderId: orderId!,
    });

  const { data: orderDetails, isLoading: orderDetailsLoading } =
    useGetApiOrderGetOrderDetailByOrderIdQuery({
      orderId: orderId!,
    });

  const orderDetailsEnhance = orderDetails as
    | GetApiOrderGetOrderDetailByOrderIdApiResponse_type1
    | undefined;

  const insurancePolicyDetailsSection = [
    {
      name: "نام برند",
      value: orderDetailsEnhance?.brandName,
    },
    {
      name: "نام مدل",
      value: orderDetailsEnhance?.modelName,
    },
    {
      name: "IMEI",
      value: orderDetailsEnhance?.imei,
    },
  ];

  return (
    <>
      {(orderByIdLoading || orderDetailsLoading) && <Loading />}
      <Stack rowGap={5} sx={{ p: { xs: 2, sm: 4 } }}>
        <Typography variant="h6" align="center" fontWeight={600}>
          مشخصات بیمه نامه
        </Typography>
        <Grid container rowGap={{ xs: 0, sx: 2 }}>
          {insurancePolicyDetailsSection.map(({ name, value }, index) => (
            <Fragment key={index}>{box2({ name, value })}</Fragment>
          ))}
        </Grid>
        <Divider />
        <Typography variant="h6" align="center" fontWeight={600}>
          مشخصات کالای بیمه شده
        </Typography>
        <Grid container rowGap={{ xs: 0, sx: 2 }}>
          {box2({
            name: "ارزش کالای بیمه شده",
            value: orderById && priceToPersian(orderById.netPrice),
          })}
        </Grid>
        <Divider />
        <Typography variant="h6" align="center" fontWeight={600}>
          پرداخت
        </Typography>
        <Grid container rowGap={{ xs: 0, sx: 2 }}>
          {box2({
            name: "جمع کل",
            value: orderById && priceToPersian(orderById.totalPrice),
          })}
          {box2({
            name: "تخفیف",
            value: orderById && priceToPersian(orderById.discount),
          })}
          {box2({
            name: "مالیات بر ارزش افزوده",
            value: orderById && priceToPersian(orderById.vat),
          })}
          {box2({
            name: "مبلغ قابل پرداخت",
            value: orderById && priceToPersian(orderById.orderPrice),
          })}
        </Grid>
      </Stack>
    </>
  );
};
