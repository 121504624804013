import { FC, useState } from "react";
import { Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import GenericForm from "../../organization/GenericForm";
import { FormButtonType } from "src/types/genericFrom.types";
import { BaseDialogType } from "src/types/dialog.types";

type CommissionDialogPropsType = {
  id: number;
};

export const CommissionDialog: FC<
  CommissionDialogPropsType & BaseDialogType
> = ({ id, open, handleClose }) => {
  const [state, setState] = useState({});

  const buttons: FormButtonType[] = [];

  return (
    <Dialog
      maxWidth="md"
      PaperProps={{
        sx: { width: "100%" },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>کمیسیون</DialogTitle>
      <DialogContent>
        <Box sx={{ paddingTop: 1 }}>
          <GenericForm
            state={state}
            setState={setState}
            fields={[]}
            buttons={buttons}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
