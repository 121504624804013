import { FC } from "react";
import { Button, Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import notFoundSvg from "src/assets/images/404.svg";

const NotFound: FC = () => {
  const navigate = useNavigate();

  const homeClickHandler = () => navigate("/");
  const returnClickHandler = () => navigate(-1);

  return (
    <Box
      component={Stack}
      sx={{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        position: "fixed",
        width: "100vw",
        height: "100vh",
      }}
      textAlign='center'
      justifyContent='center'
      alignItems='center'>
      <Box sx={{ width: "100%", maxWidth: "1000px" }}>
        <Stack direction='column' rowGap={5}>
          <Box>
            <Typography fontWeight='bold' fontSize={30} align='center'>
              صفحه مورد نظر پیدا نشد
            </Typography>
            <Typography fontSize={20} align='center'>
              به نظر میرسد صفحه مورد نظر شما وجود ندارد
            </Typography>
          </Box>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            columnGap={3}>
            <Button
              size='large'
              sx={{
                minWidth: "150px",
              }}
              onClick={returnClickHandler}
              variant='contained'>
              <Typography variant='button'>بازگشت</Typography>
            </Button>
            <Button
              size='large'
              sx={{
                minWidth: "150px",
              }}
              onClick={homeClickHandler}
              variant='contained'>
              <Typography variant='button'>خانه</Typography>
            </Button>
          </Stack>
        </Stack>
        <Box
          sx={{
            width: "100%",
            margin: "0 auto",
          }}>
          <img src={notFoundSvg} alt='page not found' />
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
