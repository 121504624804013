import type { FC } from "react";
import {
  Container,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useGetApiProfileGetQuery } from "src/app/services/api.generated";
import BreadCrumbs from "../components/organization/layout/breadcrumbs/BreadCrumbs";

type ProfilePropsType = {};

const Profile: FC<ProfilePropsType> = () => {
  const { data, isLoading } = useGetApiProfileGetQuery();

  const boxArray = [
    {
      name: "ایمیل",
      value: data?.email,
    },
    {
      name: "شماره موبایل",
      value: data?.phoneNumber,
    },
    {
      name: "نام",
      value: data?.firstName,
    },
    {
      name: "نام خانوادگی",
      value: data?.lastName,
    },
  ];

  return (
    <>
      <BreadCrumbs />
      <Container maxWidth="sm" sx={{ padding: "0 !important" }}>
        <Paper sx={{ overflow: "hidden" }}>
          <Typography
            variant="h6"
            align="center"
            fontWeight={500}
            sx={{
              backgroundColor: "#7986cb",
              color: "white",
              py: 1,
            }}
          >
            حساب کاربری
          </Typography>
          <Stack
            rowGap={4}
            justifyContent="space-between"
            sx={{ p: { xs: 2, sm: 3, md: 4, lg: 5 } }}
          >
            {boxArray.map(({ name, value }, index) =>
              isLoading ? (
                <Skeleton
                  key={index}
                  sx={{ minHeight: "45px" }}
                  variant="rectangular"
                  animation="wave"
                />
              ) : (
                <TextField
                  key={index}
                  size="small"
                  disabled
                  label={name}
                  value={value}
                  fullWidth
                />
              )
            )}
          </Stack>
        </Paper>
      </Container>
    </>
  );
};

export default Profile;
