import type { FC } from "react";
import { useEffect, Fragment } from "react";
import {
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  useGetApiOrderGetByOrderIdQuery,
  useGetApiOrderGetOrderDetailByOrderIdQuery,
} from "src/app/services/api.generated";
import {
  useLazyGetApiInsuranceDurationsListQuery,
  useLazyGetApiOrderIndividualEventsJobsQuery,
} from "src/app/services/api";
import { priceToPersian } from "src/utils/priceToPersian";
import { GetApiOrderGetOrderDetailByOrderIdApiResponse_type4 } from "src/app/services/responses.type";
import { box2 } from "src/pages/OrderDetails";

type IndividualAccidentInsuranceOrderDetailsPropsType = {
  orderId: number;
};

export const IndividualAccidentInsuranceOrderDetails: FC<
  IndividualAccidentInsuranceOrderDetailsPropsType
> = ({ orderId }) => {
  const { data: orderById, isLoading: orderByIdLoading } =
    useGetApiOrderGetByOrderIdQuery({
      orderId: orderId!,
    });

  const { data: orderDetails, isLoading: orderDetailsLoading } =
    useGetApiOrderGetOrderDetailByOrderIdQuery({
      orderId: orderId!,
    });

  const orderDetailsEnhance = orderDetails as
    | GetApiOrderGetOrderDetailByOrderIdApiResponse_type4
    | undefined;

  const [getJobList, { data: jobsList, isFetching: jobsListLoading }] =
    useLazyGetApiOrderIndividualEventsJobsQuery();

  const [
    getInsuranceDurationsList,
    { data: insuranceDurationsList, isFetching: insuranceDurationsListLoading },
  ] = useLazyGetApiInsuranceDurationsListQuery();

  useEffect(() => {
    getInsuranceDurationsList();
    getJobList();
  }, [getInsuranceDurationsList, getJobList]);

  const insurancePolicyDetailsSection = [
    {
      name: "تعداد افراد",
      value: orderDetailsEnhance?.personCounts + " نفر",
    },
    {
      name: "مدت زمان بیمه",
      value:
        insuranceDurationsList &&
        insuranceDurationsList.find(
          ({ id }) => id === orderDetailsEnhance?.insuranceDurationId
        )?.text,
    },
    {
      name: "زمینه شغلی",
      value:
        jobsList?.find(({ id }) => id === orderDetailsEnhance?.jobId)?.text ||
        "",
    },
  ];

  return (
    <>
      {(orderByIdLoading ||
        orderDetailsLoading ||
        jobsListLoading ||
        insuranceDurationsListLoading) && (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      <Stack rowGap={5} sx={{ p: { xs: 2, sm: 4 } }}>
        <Typography variant="h6" align="center" fontWeight={600}>
          مشخصات بیمه نامه
        </Typography>
        <Grid container rowGap={{ xs: 0, sx: 2 }}>
          {insurancePolicyDetailsSection.map(({ name, value }, index) => (
            <Fragment key={index}>{box2({ name, value })}</Fragment>
          ))}
        </Grid>
        <Divider />
        <Typography variant="h6" align="center" fontWeight={600}>
          مشخصات کالای بیمه شده
        </Typography>
        <Grid container rowGap={{ xs: 0, sx: 2 }}>
          {box2({
            name: "ارزش کالای بیمه شده",
            value: orderById && priceToPersian(orderById.netPrice),
          })}
        </Grid>
        <Divider />
        <Typography variant="h6" align="center" fontWeight={600}>
          پرداخت
        </Typography>
        <Grid container rowGap={{ xs: 0, sx: 2 }}>
          {box2({
            name: "جمع کل",
            value: orderById && priceToPersian(orderById.totalPrice),
          })}
          {box2({
            name: "تخفیف",
            value: orderById && priceToPersian(orderById.discount),
          })}
          {box2({
            name: "مالیات بر ارزش افزوده",
            value: orderById && priceToPersian(orderById.vat),
          })}
          {box2({
            name: "مبلغ قابل پرداخت",
            value: orderById && priceToPersian(orderById.orderPrice),
          })}
        </Grid>
      </Stack>
    </>
  );
};
