import { FC, useState } from "react";
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Avatar,
  Box,
  Typography,
} from "@mui/material";
import {
  useGetApiSupportItemListBySupportIdQuery,
  usePostApiSupportItemCreateMutation,
} from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { BaseDialogType } from "src/types/dialog.types";
import { LoadingButton } from "@mui/lab";

type ShowTicketsDialogPropsType = {
  id: number;
};

export const ShowTicketsDialog: FC<
  ShowTicketsDialogPropsType & BaseDialogType
> = ({ id, open, handleClose }) => {
  const [message, setMessage] = useState("");

  const { data: messages } = useGetApiSupportItemListBySupportIdQuery({
    supportId: id,
  });

  const [sendMessage, { isLoading }] = usePostApiSupportItemCreateMutation();

  const submitHandler = () => {
    if (!message) return;
    sendMessage({
      createSupportItemModel: {
        supportId: id,
        content: message,
      },
    })
      .unwrap()
      .then(() => {
        toast.success("پیام شما با موفقیت دریافت شد");
        handleClose();
      });
  };

  return (
    <Dialog
      maxWidth="md"
      PaperProps={{
        sx: { width: "100%" },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>پشتیبانی</DialogTitle>
      <DialogContent>
        <Stack sx={{ paddingTop: 1 }} rowGap={2}>
          {messages &&
            messages.map(({ id, supportId, content, supportDate, user }) => (
              <Stack direction="row" key={id} columnGap={1}>
                <Avatar />
                <Box
                  sx={{ bgcolor: "#eee", borderRadius: "8px", py: 1, px: 2 }}
                >
                  <Typography sx={{ mb: 2 }}>{content}</Typography>
                  <Typography fontSize={12} color="rgba(0,0,0,0.5)">
                    {user} - {supportDate}
                  </Typography>
                </Box>
              </Stack>
            ))}
          <Divider orientation="horizontal" />
          <TextField
            multiline
            rows={6}
            fullWidth
            required
            label="متن پیام"
            value={message}
            onChange={({ target: { value } }) => setMessage(value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          انصراف
        </Button>
        <LoadingButton
          disabled={!message}
          loading={isLoading}
          variant="contained"
          onClick={submitHandler}
        >
          ارسال
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
