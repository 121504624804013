import { FC, useState } from "react";
import { Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import GenericForm from "../../organization/GenericForm";
import { FieldType, FormButtonType } from "src/types/genericFrom.types";
import { BaseDialogType } from "src/types/dialog.types";
import {
  useLazyGetApiMarketerConfirmByMarketerIdQuery,
  useLazyGetApiMarketerRejectByMarketerIdAndReasonQuery,
} from "src/app/services/api";

const fields: FieldType[] = [
  {
    name: "reason",
    component: "Text",
    props: {
      multiline: true,
      rows: 4,
      label: "علت رد نماینده",
      required: true,
      width: "full",
    },
  },
];

type RejectConfirmDialogPropsType = {
  id: number;
};

export const RejectConfirmDialog: FC<
  RejectConfirmDialogPropsType & BaseDialogType
> = ({ id, open, handleClose }) => {
  const [state, setState] = useState({
    reason: "",
  });

  const [confirmMarketer, { isLoading: confirmMarketerLoading }] =
    useLazyGetApiMarketerConfirmByMarketerIdQuery();
  const [rejectMarketer, { isLoading: rejectMarketerLoading }] =
    useLazyGetApiMarketerRejectByMarketerIdAndReasonQuery();

  const buttons: FormButtonType[] = [
    {
      onClick: handleClose,
      text: "انصراف",
      variant: "text",
      color: "primary",
      checkRequiredField: false,
    },
    {
      onClick: () => rejectMarketer({ marketerId: id, reason: state.reason }),
      text: "رد",
      variant: "contained",
      color: "error",
      checkRequiredField: true,
      isLoading: rejectMarketerLoading,
    },
    {
      onClick: () => confirmMarketer({ marketerId: id }),
      text: "تایید",
      variant: "contained",
      color: "primary",
      checkRequiredField: true,
      isLoading: confirmMarketerLoading,
    },
  ];

  return (
    <Dialog
      maxWidth="md"
      PaperProps={{
        sx: { width: "100%" },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>تایید یا رد نماینده</DialogTitle>
      <DialogContent>
        <Box sx={{ paddingTop: 1 }}>
          <GenericForm
            state={state}
            setState={setState}
            fields={fields}
            buttons={buttons}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
