import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { PostApiOrderListApiResponse } from "src/app/services/api.generated";
import { DeleteOrderDialog } from "src/components/molecule/dialogs/DeleteOrderDialog";
import theme from "src/configs/theme/theme";
import { ActionButtonModes } from "src/types/actionButton.types";
import { ActionModes, DownloadApisEnum } from "src/types/actionItems.types";
import { ColumnModes, ColumnType } from "src/types/genericTable.types";

const colors = new Map<string, string>([
  ["صادر شده", theme.palette.success.light],
  ["ابطال شده", theme.palette.error.main],
  ["پرداخت شده", theme.palette.error.main],
  ["سفارش", theme.palette.error.main],
  ["نیاز به اصلاح", theme.palette.error.main],
  ["در انتظار تایید", theme.palette.error.main],
]);

export const orderColumns: ColumnType<PostApiOrderListApiResponse[number]>[] = [
  {
    type: ColumnModes.DATA,
    label: "شماره سفارش",
    id: "id",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "کاربر",
    id: "user",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "نوع بیمه",
    id: "productCategory",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "تاریخ",
    id: "orderDate",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "ارزش کالا",
    id: "netPrice",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    label: "مبلغ",
    id: "totalPrice",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    label: "مارکاپ",
    id: "markupPrice",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    label: "تخفیف",
    id: "discount",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    label: "ارزش افزوده",
    id: "vat",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    label: "مبلغ سفارش",
    id: "orderPrice",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    label: "نیازمند تایید",
    id: "confirmNeeded",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "وضعیت",
    id: "orderStatus",
    chip: true,
    color: colors,
  },
  {
    type: ColumnModes.ACTION,
    label: "عملیات",
    actions: [
      {
        icon: MoreVertIcon,
        type: ActionButtonModes.DROPDOWN,
        items: [
          {
            label: "مشاهده سفارش",
            type: ActionModes.REDIRECT,
            props: ["id"],
            to: ({ id }) => "/orders/" + id,
          },
          {
            label: "فاکتور فروش",
            type: ActionModes.REDIRECT,
            props: ["id"],
            to: ({ id }) => "/invoice/" + id,
          },
          {
            label: "دانلود بیمه‌نامه",
            type: ActionModes.DOWNLOAD,
            props: ["id"],
            api: DownloadApisEnum.DOWNLOAD_INSURANCE_POLICY,
          },
          {
            label: "ابطال سفارش",
            type: ActionModes.DIALOG,
            props: ["id"],
            dialogComponent: DeleteOrderDialog,
          },
        ],
      },
    ],
  },
];
