import type { FC, MouseEventHandler } from "react";
import { useMemo } from "react";
import { Button, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { useGetApiOrderGetByOrderIdQuery } from "src/app/services/api.generated";
import { useLocation, useNavigate } from "react-router";
import BreadCrumbs from "src/components/organization/layout/breadcrumbs/BreadCrumbs";
import { FireInsuranceOrderDetails } from "src/components/organization/orderDetails/FireInsuranceOrderDetails";
import { IndividualAccidentInsuranceOrderDetails } from "src/components/organization/orderDetails/IndividualAccidentInsuranceOrderDetails";
import { PhoneInsuranceOrderDetails } from "src/components/organization/orderDetails/PhoneInsuranceOrderDetails";
import { ThirdPartyInsuranceOrderDetails } from "src/components/organization/orderDetails/ThirdPartyInsuranceOrderDetails";
import { SupplementaryHealthInsuranceOrderDetails } from "src/components/organization/orderDetails/SupplementaryHealthInsuranceOrderDetails";
import Loading from "src/components/atoms/Loading";
import { OrderDetailsUploadFile } from "src/components/organization/orderDetails/OrderDetailsUploadFile";

export type boxType = {
  name?: string;
  value?: string | number | null;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const box1 = ({ name, value }: boxType) => (
  <Grid item xs={12} sm={5.8} md={2.8}>
    <Stack direction={{ xs: "row", sm: "column" }} columnGap={{ xs: 2, sm: 0 }}>
      <Typography color="white" align="center" sx={{ mb: 1 }}>
        {name}:
      </Typography>
      <Typography color="white" align="center">
        {value}
      </Typography>
    </Stack>
  </Grid>
);

export const box2 = ({ name, value, onClick }: boxType) => (
  <Grid item xs={12} md={6} xl={4} sx={{ p: 1 }} onClick={onClick}>
    <Stack direction="row" alignItems="center" columnGap={1}>
      <Typography sx={{ color: "rgba(0,0,0,.6)" }}>{name}:</Typography>
      <Typography fontWeight={500}>{value}</Typography>
    </Stack>
  </Grid>
);

const OrderDetails: FC = () => {
  const { pathname } = useLocation(),
    navigate = useNavigate();

  const orderId = useMemo(() => {
    const id = pathname.replace("/orders/", "");
    if (id === "") return null;
    return Number(id);
  }, [pathname]);

  if (!orderId) navigate("/");

  const { data: orderById, isLoading: orderByIdLoading } =
    useGetApiOrderGetByOrderIdQuery({
      orderId: orderId!,
    });

  const insurancePolicyDetailsSection = useMemo(() => {
    if (!orderId) return <></>;
    let result = <></>;
    switch (orderById?.productCategoryId) {
      case 1:
        result = <PhoneInsuranceOrderDetails orderId={orderId} />;
        break;
      case 2:
        result = <FireInsuranceOrderDetails orderId={orderId} />;
        break;
      case 3:
        result = <ThirdPartyInsuranceOrderDetails />;
        break;
      case 4:
        result = <IndividualAccidentInsuranceOrderDetails orderId={orderId} />;
        break;
      case 5:
        result = <SupplementaryHealthInsuranceOrderDetails orderId={orderId} />;
        break;
      default:
        break;
    }
    return result;
  }, [orderById?.productCategoryId, orderId]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
      >
        <BreadCrumbs spaceBottom={false} />
        <Button variant="contained" onClick={() => navigate(-1)}>
          بازگشت
        </Button>
      </Stack>
      <br />
      <Paper sx={{ overflow: "hidden", position: "relative" }}>
        {orderByIdLoading && <Loading />}
        <Grid
          container
          sx={{ backgroundColor: "#7986cb", p: 2 }}
          justifyContent="space-between"
          rowGap={{ xs: 2, md: 2 }}
        >
          {box1({ name: "شماره سفارش", value: orderById?.id })}
          {box1({ name: "نام طرح", value: orderById?.productName })}
          {box1({ name: "تاریخ", value: orderById?.orderDate })}
          {box1({ name: "وضعیت", value: orderById?.orderStatus })}
        </Grid>
        <Typography variant="h6" align="center" fontWeight={600} sx={{ my: 2 }}>
          مشخصات بیمه کننده
        </Typography>
        <Grid container rowGap={{ xs: 0, sx: 2 }} sx={{ mb: 2 }}>
          {box2({ name: "نام و نام خانوادگی", value: orderById?.userName })}
          {box2({ name: "تاریخ تولد", value: orderById?.birthDate })}
          {box2({ name: "کد ملی", value: orderById?.nationalId })}
          {box2({ name: "آدرس", value: orderById?.address })}
          {box2({ name: "کد پستی", value: orderById?.postalCode })}
          {box2({ name: "تلفن", value: orderById?.phone + "+" })}
          {box2({ name: "شهر", value: orderById?.cityName })}
          {box2({ name: "استان", value: orderById?.stateName })}
        </Grid>
        <Divider />
        {insurancePolicyDetailsSection}
        {orderById && <OrderDetailsUploadFile orderById={orderById} />}
      </Paper>
    </>
  );
};

export default OrderDetails;
