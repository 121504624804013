import { FC, useMemo, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
} from "@mui/material";
import GenericForm from "../../organization/GenericForm";
import fields from "src/sampleData/dialogs/AddSupportSubjectDialog";
import { FormButtonType } from "src/types/genericFrom.types";
import {
  useGetApiBusinessUnitListQuery,
  usePostApiSupportSubjectCreateMutation,
} from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { listItemsEnhancer } from "src/utils/ListItemsEnhancer";

export const AddSupportSubjectDialog: FC<DialogProps> = (props) => {
  const [state, setState] = useState<any>({
    name: "",
    businessUnitId: "",
  });

  const { data: businessUnitList } = useGetApiBusinessUnitListQuery();

  const [createSupportSubject, { isLoading }] =
    usePostApiSupportSubjectCreateMutation();

  const onSubmit = () => {
    const { name, businessUnitId } = state;

    createSupportSubject({
      createSupportSubjectModel: {
        name,
        businessUnitId: Number(businessUnitId),
      },
    })
      .unwrap()
      .then(() => {
        toast.success("اطلاعات با موفقیت ذخیره شدند.");
        props.onClose!({}, "escapeKeyDown");
      })
  };

  const buttons: FormButtonType[] = [
    {
      onClick: () => props.onClose!({}, "escapeKeyDown"),
      text: "انصراف",
      variant: "outlined",
      color: "primary",
      checkRequiredField: false,
    },
    {
      onClick: onSubmit,
      text: "ثبت",
      variant: "contained",
      color: "primary",
      checkRequiredField: true,
      isLoading: isLoading,
    },
  ];

  const enhancedFields = useMemo(() => {
    let result = [...fields];
    result.forEach((item) => {
      if (item.name === "businessUnitId" && businessUnitList) {
        item.props.items = listItemsEnhancer(businessUnitList);
      }
    });
    return result;
  }, [businessUnitList]);

  return (
    <Dialog {...props}>
      <DialogTitle>ایجاد موضوع پشتیبانی</DialogTitle>
      <DialogContent>
        <Box sx={{ paddingTop: 1 }}>
          <GenericForm
            state={state}
            setState={setState}
            fields={enhancedFields}
            buttons={buttons}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
