import { FieldType } from "src/types/genericFrom.types";
import KeyIcon from "@mui/icons-material/Key";

const data: FieldType[] = [
  {
    component: "Text",
    name: "password",
    props: {
      label: "رمز عبور جدید",
      required: true,
      width: "full",
      type: "password",
      startAdornment: KeyIcon,
    },
  },
  {
    component: "Text",
    name: "confirmPassword",
    props: {
      label: "تکرار رمز عبور جدید",
      required: true,
      width: "full",
      type: "password",
      startAdornment: KeyIcon,
    },
  },
];

export default data;
