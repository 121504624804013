import type { ChangeEvent, FC, MouseEvent } from "react";
import { Fragment, useEffect, useState } from "react";
import {
  Paper,
  Stack,
  Typography,
  TableRow,
  TablePagination,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Chip,
  Box,
  Divider,
} from "@mui/material";
import EnhancedTableHead from "../molecule/EnhancedTableHead";
import { getComparator, Order, stableSort } from "src/utils/table";
import {
  ActionsColumnType,
  ColumnModes,
  ColumnType,
} from "src/types/genericTable.types";
import { ActionButton } from "../atoms/ActionButton";
import { Close, Done } from "@mui/icons-material";
import { priceToPersian } from "src/utils/priceToPersian";

type DataTablePropsType<RowType> = {
  rows: RowType[];
  columns: ColumnType<RowType>[];
  title: string;
  searchBar?: any;
};

const DataTable: FC<DataTablePropsType<any>> = ({
  rows,
  columns,
  title,
  searchBar,
}) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleRequestSort = (_: MouseEvent<unknown>, property: keyof any) => {
    const isAsc = orderBy === property && order === "asc";
    const isDesc = orderBy === property && order === "desc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(isDesc ? "" : property);
  };

  const [windowDimenion, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);

    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Paper
      sx={{
        mb: 2,
        borderRadius: "4px",
        boxShadow:
          "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        sx={{
          width: "100%",
          backgroundColor: "#7986cb",
          height: "48px",
          borderColor: "#7986cb",
          borderRadius: "4px 4px 0 0",
        }}
      >
        <Typography
          sx={{
            margin: "auto 0",
            fontSize: "1.25rem",
            lineHeight: "1.5",
            color: "#fff",
            fontFamily: "iransansxv",
          }}
        >
          {title}
        </Typography>
      </Stack>

      {searchBar}

      {windowDimenion.winWidth > 1000 ? (
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy as any}
              onRequestSort={handleRequestSort}
              columns={columns}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy as any))
                .slice(
                  page * rowsPerPage,
                  rowsPerPage === -1
                    ? rows.length
                    : page * rowsPerPage + rowsPerPage
                )
                .map((row, index) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    {columns.map((column, index) => (
                      <TableCell key={index} align="left">
                        {column.type === ColumnModes.DATA ? (
                          column.chip && column.color instanceof Map ? (
                            <Chip
                              label={row[column.id as any]}
                              variant="outlined"
                              sx={{
                                color: column.color.get(row[column.id as any]),
                                borderColor: column.color.get(
                                  row[column.id as any]
                                ),
                              }}
                            />
                          ) : (
                            <>
                              {(row[column.id as any] as any) === true ? (
                                <Done fontSize="small"></Done>
                              ) : (row[column.id as any] as any) === false ? (
                                <Close fontSize="small"></Close>
                              ) : column.isPrice ? (
                                priceToPersian(row[column.id as any])
                              ) : (
                                row[column.id as any]
                              )}
                            </>
                          )
                        ) : (
                          (column as ActionsColumnType<any>).actions.map(
                            (action, index) => (
                              <ActionButton
                                key={index}
                                action={action}
                                row={row}
                              />
                            )
                          )
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell align="left" colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Stack divider={<Divider flexItem />}>
          {stableSort(rows, getComparator(order, orderBy as any))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <Fragment key={index}>
                {columns.map((column, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%", p: 1 }}
                  >
                    <Box
                      sx={{
                        color: "rgba(0,0,0,.6)",
                        fontSize: "0.75rem",
                        "&:hover": { color: "black" },
                        width: "100%",
                      }}
                    >
                      {column.label}
                    </Box>
                    {column.type === ColumnModes.DATA ? (
                      column.chip && column.color instanceof Map ? (
                        <Chip
                          label={row[column.id as any]}
                          variant="outlined"
                          sx={{
                            color: column.color.get(row[column.id as any]),
                            borderColor: column.color.get(
                              row[column.id as any]
                            ),
                            width: "100%",
                            textAlign: "left",
                          }}
                        />
                      ) : (
                        <Box sx={{ width: "100%", textAlign: "left" }}>
                          {(row[column.id as any] as any) === true ? (
                            <Done fontSize="small" />
                          ) : (row[column.id as any] as any) === false ? (
                            <Close fontSize="small" />
                          ) : (
                            row[column.id as any]
                          )}
                        </Box>
                      )
                    ) : (
                      (column as ActionsColumnType<any>).actions.map(
                        (action, index) => (
                          <ActionButton key={index} action={action} row={row} />
                        )
                      )
                    )}
                  </Stack>
                ))}
              </Fragment>
            ))}
        </Stack>
      )}

      <TablePagination
        rowsPerPageOptions={
          windowDimenion.winWidth > 1000
            ? [5, 10, 15, { value: -1, label: "همه" }]
            : []
        }
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="ردیف در صفحه:"
        labelDisplayedRows={({ count, from, to }) => {
          return `${from} تا ${to} از ${count}`;
        }}
        showFirstButton={true}
        showLastButton={true}
      />
    </Paper>
  );
};

export default DataTable;
