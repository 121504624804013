import { FieldType } from "src/types/genericFrom.types";

const data: FieldType[] = [
  {
    component: "Text",
    name: "name",
    props: {
      label: "عنوان",
      required: true,
      width: "full",
    },
  },
  {
    component: "DropDown",
    name: "businessUnitId",
    props: {
      label: "واحد سازمانی",
      required: true,
      items: [
        { value: "1", text: "مالی و فروش" },
        { value: "2", text: "فنی" },
      ],
      width: "full",
      value: "1",
    },
  },
];

export default data;
