import type { FC, MouseEventHandler } from "react";
import { useState } from "react";
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Person as PersonIcon,
  VpnKey as VpnKeyIcon,
} from "@mui/icons-material";
import {
  Button,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { usePostApiAccountLogoutMutation } from "src/app/services/api.generated";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { logoutAction } from "src/app/slices/authSlice";
import { toast } from "react-toastify";

type ProfileMenuPropsType = {};

const ProfileMenu: FC<ProfileMenuPropsType> = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >();

  const closeMenuHandler = () => setAnchorEl(null);
  const openMenuHandler: MouseEventHandler<HTMLButtonElement> | undefined = (
    event?: any
  ) => setAnchorEl(event?.currentTarget);

  const itemClickHandler = (path: string) => {
    navigate(path);
    closeMenuHandler();
  };

  const [logoutUser] = usePostApiAccountLogoutMutation();

  const logoutHandler = (path: string) => {
    navigate(path);
    closeMenuHandler();

    logoutUser()
      .unwrap()
      .then(() => {
        toast.success("خارج شدید");
        navigate("/");
      });

    logoutAction();
    localStorage.removeItem("loginInfo");
    window.location.reload();
  };

  const open = Boolean(anchorEl);
  const id = open ? "profile section" : undefined;

  return (
    <>
      <Tooltip title="ناحیه کاربری" arrow>
        <Button
          startIcon={<KeyboardArrowDownIcon />}
          onClick={openMenuHandler}
          sx={{ color: "white" }}
        >
          تستی تستی
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenuHandler}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem onClick={() => itemClickHandler("/profile")}>
          <ListItemIcon sx={{ minWidth: 40 }}>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography>مشخصات</Typography>} />
        </MenuItem>
        <MenuItem onClick={() => itemClickHandler("/changePassword")}>
          <ListItemIcon sx={{ minWidth: 40 }}>
            <VpnKeyIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography>تغییر گذرواژه</Typography>} />
        </MenuItem>
        <MenuItem onClick={() => logoutHandler("/login")}>
          <ListItemIcon sx={{ minWidth: 40 }}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography>خروج</Typography>} />
        </MenuItem>
      </Popover>
    </>
  );
};

export default ProfileMenu;
