import { ColumnModes, ColumnType } from "src/types/genericTable.types";
import { MoreVert as MoreVertIcon } from "@mui/icons-material";
import { DeleteUserDialog } from "src/components/molecule/dialogs/DeleteUserDialog";
import { ActionButtonModes } from "src/types/actionButton.types";
import { ActionModes } from "src/types/actionItems.types";
import EditUserInformation from "src/components/molecule/dialogs/EditUserInformation";
import ResetPassword from "src/components/molecule/dialogs/ResetPassword";
import { GetApiUserListApiResponse } from "src/app/services/api.generated";
import { DeActiveUserDialog } from "src/components/molecule/dialogs/DeActiveUserDialog";
import { ActiveUserDialog } from "src/components/molecule/dialogs/ActiveUserDialog";
import theme from "src/configs/theme/theme";

const colors = new Map<string, string>([
  ["فعال", theme.palette.success.light],
  ["غیر فعال", theme.palette.error.main],
]);

export const usersColumns: ColumnType<GetApiUserListApiResponse[number]>[] = [
  {
    type: ColumnModes.DATA,
    id: "phoneNumber",
    label: "موبایل",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "fullName",
    label: "نام",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "email",
    label: "ایمیل",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "isCompanyAccount",
    label: "نوع شرکت",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "role",
    label: "سطح دسترسی",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "isActive",
    label: "وضعیت",
    chip: true,
    color: colors,
  },
  {
    type: ColumnModes.DATA,
    id: "lastLoginDate",
    label: "آخرین ورود",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "createDate",
    label: "ایجاد",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.ACTION,
    label: "عملیات",
    actions: [
      {
        icon: MoreVertIcon,
        type: ActionButtonModes.DROPDOWN,
        items: [
          {
            label: "ویرایش",
            type: ActionModes.DIALOG,
            props: ["id"],
            dialogComponent: EditUserInformation,
          },
          {
            label: "بازیابی رمز عبور",
            type: ActionModes.DIALOG,
            props: ["id"],
            dialogComponent: ResetPassword,
          },
          {
            label: "غیرفعال‌سازی کاربر",
            type: ActionModes.DIALOG,
            props: ["id"],
            dialogComponent: DeActiveUserDialog,
          },
          {
            label: "فعالسازی کاربر",
            type: ActionModes.DIALOG,
            props: ["id"],
            dialogComponent: ActiveUserDialog,
          },
          {
            label: "حذف",
            type: ActionModes.DIALOG,
            props: ["id"],
            dialogComponent: DeleteUserDialog,
          },
        ],
      },
    ],
  },
];
