import { FC, useState } from "react";
import { Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import GenericForm from "../../organization/GenericForm";
import fields from "src/sampleData/dialogs/SupportSubject";
import { FormButtonType } from "src/types/genericFrom.types";
import { usePutApiSupportSubjectEditMutation } from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { BaseDialogType } from "src/types/dialog.types";

// todo: error handler

type ProductPriceManagementPropsType = {
  id: number;
  businessUnitId: number;
  name: string;
};

const ProductPriceManagement: FC<
  ProductPriceManagementPropsType & BaseDialogType
> = ({ id, businessUnitId, name, open, handleClose }) => {
  const [state, setState] = useState<any>({
    businessUnitId,
    name,
  });
  const [editSupportSubject, { isLoading }] =
    usePutApiSupportSubjectEditMutation();

  const onSubmit = () => {
    editSupportSubject({
      editSupportSubjectModel: {
        id,
        ...state,
      },
    })
      .unwrap()
      .then((res) => {
        toast.success("اطلاعات با موفقیت ذخیره شدند.");
        handleClose();
      });
  };

  const buttons: FormButtonType[] = [
    {
      onClick: handleClose,
      text: "انصراف",
      variant: "outlined",
      color: "primary",
      checkRequiredField: false,
    },
    {
      onClick: onSubmit,
      text: "ذخیره اطلاعات",
      variant: "contained",
      color: "primary",
      checkRequiredField: true,
      isLoading: isLoading,
    },
  ];

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle>موضوعات پشتیبانی</DialogTitle>
      <DialogContent>
        <Box sx={{ paddingTop: 1 }}>
          <GenericForm
            state={state}
            setState={setState}
            fields={fields}
            buttons={buttons}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProductPriceManagement;
