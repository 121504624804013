import { textValueType } from "src/types/genericFrom.types";

export type entryListItemsType = {
  id?: number | string;
  name?: string | null;
};

export const listItemsEnhancer = (listItems?: entryListItemsType[]) => {
  let result: textValueType[] = [];
  if (listItems) {
    listItems.forEach(({ id, name }) => {
      if (id && name) {
        result.push({ value: id?.toString(), text: name });
      }
    });
  }
  return result;
};
