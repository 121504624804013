import { ColumnModes, ColumnType } from "src/types/genericTable.types";
import { ActionButtonModes } from "src/types/actionButton.types";
import { ActionModes } from "src/types/actionItems.types";
import { GetApiSupportListBySupportStatusIdAndUserIdApiResponse } from "src/app/services/api.generated";
import theme from "src/configs/theme/theme";
import { ShowTicketsDialog } from "src/components/molecule/dialogs/ShowTicketsDialog";
import { CloseTicketDialog } from "src/components/molecule/dialogs/CloseTicketDialog";

const colors = new Map<string, string>([
  ["ارسال شده", theme.palette.info.main],
  ["بسته شده", theme.palette.success.light],
  ["پاسخ داده شده", theme.palette.warning.light],
]);

export const supportsColumns: ColumnType<
  GetApiSupportListBySupportStatusIdAndUserIdApiResponse[number]
>[] = [
  {
    type: ColumnModes.DATA,
    id: "id",
    label: "شماره پیگیری",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "supportDate",
    label: "تاریخ",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "user",
    label: "کاربر",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "businessUnit",
    label: "واحد",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "supportSubject",
    label: "موضوع",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "supportStatus",
    label: "وضعیت",
    chip: true,
    color: colors,
  },
  {
    type: ColumnModes.ACTION,
    label: "پاسخ",
    actions: [
      {
        type: ActionButtonModes.SINGLE,
        color: theme.palette.error.main,
        label: "بستن",
        action: {
          type: ActionModes.DIALOG,
          props: ["id"],
          dialogComponent: CloseTicketDialog,
        },
      },
      {
        type: ActionButtonModes.SINGLE,
        color: theme.palette.info.main,
        label: "مشاهده",
        action: {
          type: ActionModes.DIALOG,
          props: ["id"],
          dialogComponent: ShowTicketsDialog,
        },
      },
    ],
  },
];
