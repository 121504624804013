import { FC, useMemo, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
} from "@mui/material";
import GenericForm from "../../organization/GenericForm";
import fields from "src/sampleData/dialogs/AddProduct";
import { FormButtonType } from "src/types/genericFrom.types";
import {
  useGetApiInsuranceCompanyListQuery,
  useGetApiProductCategoryListQuery,
  usePostApiProductCreateMutation,
} from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { listItemsEnhancer } from "src/utils/ListItemsEnhancer";

export const AddProductDialog: FC<DialogProps> = (props) => {
  const [state, setState] = useState({
    name: "",
    productCategoryId: "",
    insuranceCompanyId: "",
    description: "",
    wagePercent: "",
    markupPrice: "",
    planId: "",
    isDisabled: true,
    needConfirm: false,
    productCommissionIsPercent: false,
    productCommissionValue: "",
  });

  const [createProduct, { isLoading: createProductLoading }] =
    usePostApiProductCreateMutation();

  const onSubmit = () => {
    const {
      name,
      productCategoryId,
      insuranceCompanyId,
      description,
      wagePercent,
      markupPrice,
      planId,
      isDisabled,
      needConfirm,
      productCommissionIsPercent,
      productCommissionValue,
    } = state;

    createProduct({
      createProductModel: {
        name,
        productCategoryId: Number(productCategoryId),
        insuranceCompanyId: Number(insuranceCompanyId),
        description,
        wagePercent: Number(wagePercent),
        markupPrice: Number(markupPrice),
        planId: Number(planId),
        isDisabled: isDisabled,
        needConfirm: needConfirm,
        productCommissionIsPercent: productCommissionIsPercent,
        productCommissionValue: Number(productCommissionValue),
      },
    })
      .unwrap()
      .then(() => {
        toast.success("محصول جدید با موفقیت ایجاد شد");
        props.onClose!({}, "escapeKeyDown");
      });
  };

  const buttons: FormButtonType[] = [
    {
      onClick: () => props.onClose!({}, "escapeKeyDown"),
      text: "انصراف",
      variant: "outlined",
      color: "primary",
      checkRequiredField: false,
    },
    {
      onClick: onSubmit,
      text: "تایید",
      variant: "contained",
      color: "primary",
      checkRequiredField: true,
      isLoading: createProductLoading,
    },
  ];

  const { data: insuranceCompanyList } = useGetApiInsuranceCompanyListQuery();
  const { data: productCategoryList } = useGetApiProductCategoryListQuery();

  const enhancedFields = useMemo(() => {
    let result = [...fields];
    result.forEach((item) => {
      if (item.name === "insuranceCompanyId" && insuranceCompanyList) {
        item.props.items = listItemsEnhancer(insuranceCompanyList);
      }
    });
    result.forEach((item) => {
      if (item.name === "productCategoryId" && productCategoryList) {
        item.props.items = listItemsEnhancer(productCategoryList);
        item.props.disabled = false;
      }
    });
    return result;
  }, [insuranceCompanyList, productCategoryList]);

  return (
    <Dialog {...props}>
      <DialogTitle>افزودن محصول جدید</DialogTitle>
      <DialogContent>
        <Box sx={{ paddingTop: 1 }}>
          <GenericForm
            state={state}
            setState={setState}
            fields={enhancedFields}
            buttons={buttons}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
