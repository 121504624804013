import { FieldType } from "src/types/genericFrom.types";

const data: FieldType[] = [
  {
    component: "Text",
    name: "firstName",
    props: {
      label: "نام",
      required: true,
      width: "half",
    },
  },
  {
    component: "Text",
    name: "lastName",
    props: {
      label: "نام خانوادگی",
      required: true,
      width: "half",
    },
  },
  {
    component: "Text",
    name: "email",
    props: {
      label: "ایمیل",
      required: true,
      width: "half",
      type: "email",
    },
  },
  {
    component: "Text",
    name: "phoneNumber",
    props: {
      label: "شماره موبایل",
      required: true,
      width: "half",
      maxLength: 11,
      type: "number",
    },
  },
  {
    component: "Text",
    name: "nationalId",
    props: {
      label: "کد ملی",
      required: true,
      width: "half",
      maxLength: 10,
      type: "number",
    },
  },
  {
    component: "DropDown",
    name: "roleId",
    props: {
      label: "سطح دسترسی",
      required: true,
      items: [],
      width: "half",
    },
  },
  {
    component: "Text",
    name: "allowedIp",
    props: {
      label: "محدود کردن IP",
      required: false,
      width: "full",
      helperText: "برای جداکردن IP ها از , استفاده کنید.",
    },
  },
];

export default data;
