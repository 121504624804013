import type { SvgIconComponent } from "@mui/icons-material";
import type {
  ActionItemType,
  DropdownActionItemType,
} from "./actionItems.types";

export enum ActionButtonModes {
  DROPDOWN,
  SINGLE,
}

export type BaseActionButtonType = {
  label?: string;
  icon?: SvgIconComponent;
  color?: string;
};

export type SINGLEActionButtonType<RowDataType> = {
  type: ActionButtonModes.SINGLE;
  action: ActionItemType<RowDataType>;
};

export type DropdownActionButtonType<RowDataType> = {
  type: ActionButtonModes.DROPDOWN;
  items: DropdownActionItemType<RowDataType>[];
};

export type ActionButtonType<RowDataType> = BaseActionButtonType &
  (DropdownActionButtonType<RowDataType> | SINGLEActionButtonType<RowDataType>);
