import { FC, useMemo, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
} from "@mui/material";
import GenericForm from "../../organization/GenericForm";
import fields from "src/sampleData/dialogs/AddDepositDialog";
import { FormButtonType } from "src/types/genericFrom.types";
import {
  useGetApiUserShortListQuery,
  usePostApiWalletDepositCreateMutation,
} from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { listItemsEnhancer } from "src/utils/ListItemsEnhancer";

export const AddDepositDialog: FC<DialogProps> = (props) => {
  const [state, setState] = useState<any>({
    userId: "",
    walletDepositTypeId: "",
    price: "",
  });

  const { data: usersList } = useGetApiUserShortListQuery();

  const [createDeposit, { isLoading }] =
    usePostApiWalletDepositCreateMutation();

  const onSubmit = () => {
    const { userId, price, walletDepositTypeId } = state;
    if (!userId || !walletDepositTypeId || !price) return;

    createDeposit({
      createWalletDepositModel: {
        userId,
        price: Number(price.replaceAll(",", "")),
        walletDepositTypeId: Number(walletDepositTypeId),
      },
    })
      .unwrap()
      .then(() => {
        toast.success("اطلاعات با موفقیت ذخیره شدند.");
        props.onClose!({}, "escapeKeyDown");
      });
  };

  const buttons: FormButtonType[] = [
    {
      onClick: () => props.onClose!({}, "escapeKeyDown"),
      text: "انصراف",
      variant: "outlined",
      color: "primary",
      checkRequiredField: false,
    },
    {
      onClick: onSubmit,
      text: "ثبت",
      variant: "contained",
      color: "primary",
      checkRequiredField: true,
      isLoading: isLoading,
    },
  ];

  const enhancedFields = useMemo(() => {
    let result = [...fields];
    result.forEach((item) => {
      if (item.name === "userId" && usersList) {
        item.props.items = listItemsEnhancer(usersList);
      }
    });
    return result;
  }, [usersList]);

  return (
    <Dialog {...props}>
      <DialogTitle>افزایش موجودی</DialogTitle>
      <DialogContent>
        <Box sx={{ paddingTop: 1 }}>
          <GenericForm
            state={state}
            setState={setState}
            fields={enhancedFields}
            buttons={buttons}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
