import { FieldType } from "src/types/genericFrom.types";

const data: FieldType[] = [
  {
    component: "Text",
    name: "name",
    props: {
      label: "نام محصول",
      required: true,
      width: "half",
    },
  },
  {
    component: "DropDown",
    name: "insuranceCompanyId",
    props: {
      label: "شرکت بیمه",
      required: true,
      items: [],
      width: "half",
    },
  },
  {
    component: "DropDown",
    name: "productCategoryId",
    props: {
      label: "دسته‌بندی",
      required: true,
      items: [],
      width: "half",
    },
  },
  {
    name: "planId",
    component: "Text",
    props: {
      label: "شماره پلن",
      required: true,
      width: "half",
      type: "number",
    },
  },
  {
    name: "wagePercent",
    component: "Text",
    props: {
      label: "درصد کارمزد",
      required: true,
      width: "half",
      isPrice: true,
      InputProps: {
        endAdornment: "درصد",
      },
    },
  },
  {
    name: "markupPrice",
    component: "Text",
    props: {
      label: "مبلغ مارکاپ",
      required: true,
      width: "half",
      isPrice: true,
      InputProps: {
        endAdornment: "ریال",
      },
    },
  },
  {
    name: "productCommissionValue",
    component: "Text",
    props: {
      label: "کمیسیون محصول",
      required: true,
      width: "half",
      isPrice: true,
    },
  },
  {
    name: "productCommissionIsPercent",
    component: "Switch",
    props: {
      label: "درصدی است",
      required: false,
      width: "half",
      value: true,
    },
  },
  {
    name: "isDisabled",
    component: "Switch",
    props: {
      label: "غیرفعال",
      required: false,
      width: "half",
      value: true,
    },
  },
  {
    name: "needConfirm",
    component: "Switch",
    props: {
      label: "نیازمند تایید",
      required: false,
      width: "half",
      value: true,
    },
  },
  {
    name: "description",
    component: "Text",
    props: {
      multiline: true,
      rows: 4,
      label: "توضیحات",
      required: false,
      width: "full",
    },
  },
];

export default data;
