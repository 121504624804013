import { FieldType } from "src/types/genericFrom.types";

const data: FieldType[] = [
  {
    component: "AutoComplete",
    name: "userId",
    props: {
      label: "کاربر",
      required: true,
      items: [],
      width: "full",
    },
  },
  {
    component: "DropDown",
    name: "businessUnitId",
    props: {
      label: "واحد",
      required: true,
      items: [],
      width: "full",
    },
  },
  {
    component: "DropDown",
    name: "supportSubjectId",
    props: {
      label: "موضوع",
      required: true,
      items: [],
      width: "full",
    },
  },
  {
    name: "content",
    component: "Text",
    props: {
      multiline: true,
      rows: 4,
      label: "متن درخواست",
      required: true,
      width: "full",
    },
  },
];

export default data;
