import {
  api as generatedApi,
  GetApiSupportListBySupportStatusIdAndUserIdApiResponse,
  PostApiOrderListApiArg,
  PostApiOrderListApiResponse,
  GetApiProductListApiResponse,
  GetApiUserListApiResponse,
  GetApiMarketerListApiResponse,
  GetApiInvoiceListByUserIdAndInvoiceStatusIdApiResponse,
  GetApiWalletWithdrawListApiResponse,
  GetApiWalletDepositListByUserIdApiResponse,
  GetApiWalletTransactionListByUserIdApiResponse,
  GetApiWalletListApiResponse,
  GetApiPaymentListByUserIdApiResponse,
  GetApiSupportSubjectListApiResponse,
  PostApiSupportCreateApiResponse,
} from "./api.generated";

export const api = generatedApi
  .injectEndpoints({
    endpoints: (build) => ({
      getApiOrderList: build.query<
        PostApiOrderListApiResponse,
        PostApiOrderListApiArg
      >({
        query: (queryArg) => ({
          url: `/api/order/list`,
          method: "POST",
          body: queryArg.orderListModel,
        }),
      }),
    }),
  })
  .enhanceEndpoints({
    addTagTypes: [
      "Support",
      "Order",
      "Product",
      "User",
      "Marketer",
      "Invoice",
      "Withdraw",
      "Deposit",
      "Transaction",
      "Wallet",
      "Payment",
      "SupportSubject",
    ],
    endpoints: {
      getApiSupportListBySupportStatusIdAndUserId: {
        providesTags: (
          result?: GetApiSupportListBySupportStatusIdAndUserIdApiResponse
        ) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Support" as const,
                  id,
                })),
                "Support",
              ]
            : ["Support"],
      },
      putApiSupportCloseById: {
        invalidatesTags: (result, error, { id }) => [{ type: "Support", id }],
      },

      getApiOrderList: {
        providesTags: (result?: PostApiOrderListApiResponse) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Order" as const,
                  id,
                })),
                "Order",
              ]
            : ["Order"],
      },
      putApiOrderCancelByOrderId: {
        invalidatesTags: (result, error, { orderId }) => [
          { type: "Order", id: orderId },
        ],
      },

      getApiProductList: {
        providesTags: (result?: GetApiProductListApiResponse) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Product" as const,
                  id,
                })),
                "Product",
              ]
            : ["Product"],
      },
      deleteApiProductDeleteById: {
        invalidatesTags: (result, error, { id }) => [{ type: "Product", id }],
      },
      postApiProductCreate: {
        invalidatesTags: () => ["Product"],
      },
      putApiProductEdit: {
        invalidatesTags: (result, error, { editProductModel }) => [
          { type: "Product", id: editProductModel.id },
        ],
      },

      getApiUserList: {
        providesTags: (result?: GetApiUserListApiResponse) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "User" as const,
                  id,
                })),
                "User",
              ]
            : ["User"],
      },
      deleteApiUserDeleteById: {
        invalidatesTags: (result, error, { id }) => [{ type: "User", id }],
      },
      postApiUserCreate: {
        invalidatesTags: () => ["User"],
      },
      putApiUserEdit: {
        invalidatesTags: (result, error, { editUserModel: { id } }) => [
          { type: "User", id },
        ],
      },
      putApiUserEnableById: {
        invalidatesTags: (result, error, { id }) => [{ type: "User", id }],
      },
      putApiUserDisableById: {
        invalidatesTags: (result, error, { id }) => [{ type: "User", id }],
      },
      putApiUserResetPassword: {
        invalidatesTags: (result, error, { resetPasswordModel: { id } }) => [
          { type: "User", id },
        ],
      },

      getApiMarketerList: {
        providesTags: (result?: GetApiMarketerListApiResponse) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Marketer" as const,
                  id,
                })),
                "Marketer",
              ]
            : ["Marketer"],
      },

      getApiInvoiceListByUserIdAndInvoiceStatusId: {
        providesTags: (
          result?: GetApiInvoiceListByUserIdAndInvoiceStatusIdApiResponse
        ) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Invoice" as const,
                  id,
                })),
                "Invoice",
              ]
            : ["Invoice"],
      },

      getApiWalletWithdrawList: {
        providesTags: (result?: GetApiWalletWithdrawListApiResponse) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Withdraw" as const,
                  id,
                })),
                "Withdraw",
              ]
            : ["Withdraw"],
      },

      getApiWalletDepositListByUserId: {
        providesTags: (result?: GetApiWalletDepositListByUserIdApiResponse) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Deposit" as const,
                  id,
                })),
                "Deposit",
              ]
            : ["Deposit"],
      },

      getApiWalletTransactionListByUserId: {
        providesTags: (
          result?: GetApiWalletTransactionListByUserIdApiResponse
        ) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Transaction" as const,
                  id,
                })),
                "Transaction",
              ]
            : ["Transaction"],
      },

      getApiWalletList: {
        providesTags: (result?: GetApiWalletListApiResponse) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Wallet" as const,
                  id,
                })),
                "Wallet",
              ]
            : ["Wallet"],
      },

      getApiPaymentListByUserId: {
        providesTags: (result?: GetApiPaymentListByUserIdApiResponse) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "Payment" as const,
                  id,
                })),
                "Payment",
              ]
            : ["Payment"],
      },

      getApiSupportSubjectList: {
        providesTags: (result?: GetApiSupportSubjectListApiResponse) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: "SupportSubject" as const,
                  id,
                })),
                "SupportSubject",
              ]
            : ["SupportSubject"],
      },
      postApiSupportCreate: {
        invalidatesTags: () => ["Support"],
      },
      postApiSupportSubjectCreate: {
        invalidatesTags: () => ["SupportSubject"],
      },
      putApiSupportSubjectEdit: {
        invalidatesTags: (
          result,
          error,
          { editSupportSubjectModel: { id } }
        ) => [{ type: "SupportSubject", id }],
      },
      deleteApiSupportSubjectDeleteById: {
        invalidatesTags: (result, error, { id }) => [
          { type: "SupportSubject", id },
        ],
      },
      postApiSupportItemCreate: {
        invalidatesTags: (
          result,
          error,
          { createSupportItemModel: { supportId } }
        ) => [{ type: "Support", id: supportId }],
      },
      putApiProductPriceEdit: {
        invalidatesTags: (result, error, { editPriceModel: { productId } }) => [
          { type: "Product", id: productId },
        ],
      },
    },
  });

export const {
  useGetApiOrderListQuery,
  useLazyGetApiInsuranceDurationsListQuery,
  useLazyGetApiOrderIndividualEventsJobsQuery,
  useLazyGetApiCityByStateIdListQuery,
  useLazyGetApiStateListQuery,
  useLazyGetApiOrderDownloadByOrderIdQuery,
  useLazyGetApiOrderDownloadOrderAttachmentByAttachmentIdQuery,
  useLazyGetApiProductGetByIdQuery,
  useLazyGetApiSupportSubjectSelectListByBusinessUnitIdQuery,
  useLazyGetApiMarketerConfirmByMarketerIdQuery,
  useLazyGetApiMarketerRejectByMarketerIdAndReasonQuery,
} = api;
