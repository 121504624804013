import { FC, useMemo } from "react";
import { useEffect, useState } from "react";
import { Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import GenericForm from "../../organization/GenericForm";
import { FormButtonType, FieldType } from "src/types/genericFrom.types";
import {
  useGetApiProductItemListByProductIdQuery,
  usePostApiProductItemEditMutation,
} from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { BaseDialogType } from "src/types/dialog.types";

export type GetProductItemListResponseEnhanced = {
  ProductAttributeId?: number;
  ProductCategoryId?: number;
  ProductAttributeTypeId?: number;
  Name?: string | null;
  PersianName?: string | null;
  Value?: number;
};

const getFieldObject = (props: GetProductItemListResponseEnhanced): any => ({
  1: {
    component: "DropDown",
    name: props.Name,
    props: {
      label: props.PersianName,
      required: true,
      items: [
        { value: true, text: "دارد" },
        { value: false, text: "ندارد" },
      ],
      width: "half",
    },
  },
  2: {
    component: "Text",
    name: props.Name,
    props: {
      label: props.PersianName,
      required: true,
      width: "half",
      endAdornment: "ریال",
    },
  },
  3: {
    component: "Text",
    name: props.Name,
    props: {
      label: props.PersianName,
      required: true,
      width: "half",
      endAdornment: "درصد",
    },
  },
});

type ProductPriceManagementPropsType = {
  id: number;
};

const ProductAttributesManagement: FC<
  ProductPriceManagementPropsType & BaseDialogType
> = ({ id, open, handleClose }) => {
  const [state, setState] = useState<any>();
  const [fields, setFields] = useState<FieldType[]>([]);

  const [editProductItem, { isLoading }] = usePostApiProductItemEditMutation();

  const {
    data: productAttributes,
    refetch,
    isLoading: getProductItemListByProductIdLoading,
  } = useGetApiProductItemListByProductIdQuery({
    productId: id,
  });

  const parsedProductAttributes = useMemo(() => {
    let result: GetProductItemListResponseEnhanced[] = [];
    if (productAttributes) {
      result = JSON.parse((productAttributes as any).content);
    }
    return result;
  }, [productAttributes]);

  useEffect(() => {
    if (parsedProductAttributes && Array.isArray(parsedProductAttributes)) {
      setFields(
        parsedProductAttributes.map(
          (attribute) =>
            attribute.ProductAttributeTypeId &&
            getFieldObject(attribute)[attribute.ProductAttributeTypeId]
        )
      );
      const newState: any = {};
      parsedProductAttributes.forEach((attribute) => {
        if (attribute.Name) {
          newState[attribute.Name] = attribute.Value;
        }
      });
      setState(newState);
    }
  }, [parsedProductAttributes]);

  const onSubmit = () => {
    console.log("first");
    const newProductAttributes = [...parsedProductAttributes];
    if (!newProductAttributes) return;

    newProductAttributes.forEach((attribute) => {
      if (attribute.Name) {
        attribute.Value = state[attribute.Name];
      }
    });

    editProductItem({
      editProductItemsModel: {
        productId: id,
        jsonProductAttributeStr: JSON.stringify(newProductAttributes),
      },
    })
      .unwrap()
      .then(() => {
        toast.success("ویژگی‌های محصول مورد‌نظر با موفقیت ویرایش شد.");
        handleClose();
      });
  };

  const buttons: FormButtonType[] = [
    {
      onClick: handleClose,
      text: "انصراف",
      variant: "outlined",
      color: "primary",
      checkRequiredField: false,
    },
    {
      onClick: onSubmit,
      text: "اعمال ویرایش",
      variant: "contained",
      color: "primary",
      checkRequiredField: true,
      isLoading: isLoading,
    },
  ];

  useEffect(() => {
    !getProductItemListByProductIdLoading && refetch();
  }, [getProductItemListByProductIdLoading, refetch]);

  return (
    <Dialog
      maxWidth="md"
      PaperProps={{
        sx: { width: "100%" },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>ویژگی‌های محصول</DialogTitle>
      <DialogContent>
        <Box sx={{ paddingTop: 1 }}>
          <GenericForm
            state={state}
            setState={setState}
            fields={fields}
            buttons={buttons}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProductAttributesManagement;
