import { GetApiPaymentListByUserIdApiResponse } from "src/app/services/api.generated";
import theme from "src/configs/theme/theme";
import { ColumnModes, ColumnType } from "src/types/genericTable.types";

const colors = new Map<string, string>([
  ["پرداخت شده", theme.palette.success.main],
  ["ناموفق", theme.palette.error.light],
]);

export const paymentsColumns: ColumnType<
  GetApiPaymentListByUserIdApiResponse[number]
>[] = [
  {
    type: ColumnModes.DATA,
    label: "شماره پیگیری",
    id: "id",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "نام کاربر",
    id: "user",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "تاریخ",
    id: "paymentDate",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "مبلغ",
    id: "amount",
    chip: false,
    color: "",
    isPrice: true,
  },
  {
    type: ColumnModes.DATA,
    label: "شماره پیگیری بانک",
    id: "rrn",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "شماره کارت",
    id: "cardNumber",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    label: "وضعیت",
    id: "paymentStatus",
    chip: true,
    color: colors,
  },
  {
    type: ColumnModes.DATA,
    label: "نام درگاه",
    id: "paymentProvider",
    chip: false,
    color: "",
  },
];
