import type { FC } from "react";
import { useMemo, useState } from "react";
import { Button, Divider, Stack, TextField, Typography } from "@mui/material";
import {
  GetOrdersByIdResponse,
  usePostApiInsurerIssueStatusOrderMutation,
} from "src/app/services/api.generated";
import { useLocation } from "react-router";
import Dropzone from "src/components/organization/dropzone/Dropzone";
import { toast } from "react-toastify";
import Loading from "src/components/atoms/Loading";

type OrderDetailsUploadFilePropsType = {
  orderById: GetOrdersByIdResponse;
};

export const OrderDetailsUploadFile: FC<OrderDetailsUploadFilePropsType> = ({
  orderById,
}) => {
  const [insuranceCertificateCode, setInsuranceCertificateCode] = useState("");
  const [nationalCardImage, setNationalCardImage] = useState<File | null>(null);

  const { pathname } = useLocation();

  const orderId = useMemo(() => {
    const id = pathname.replace("/orders/", "");
    if (id === "") return null;
    return Number(id);
  }, [pathname]);

  const [uploadFile, { isLoading }] =
    usePostApiInsurerIssueStatusOrderMutation();

  const submitClickHandler = () => {
    if (!insuranceCertificateCode || !nationalCardImage || !orderId) return;

    const formData = new FormData();
    formData.append("OrderId", orderId.toString());
    formData.append("CertificateFile", nationalCardImage);
    formData.append("CertificateCode", insuranceCertificateCode);

    uploadFile({
      body: formData as any,
    })
      .unwrap()
      .then(() => toast.success("فایل ارسالی با موفقیت دریافت شد"));
  };

  return (
    <>
      {isLoading && <Loading />}
      <Stack rowGap={5} sx={{ p: { xs: 2, sm: 4 } }}>
        <Divider />
        <Typography variant="h6" align="center" fontWeight={600}>
          بارگذاری مدارک
        </Typography>
        <Stack rowGap={4}>
          <TextField
            disabled={!!orderById.certificateCode}
            required
            label="کد گواهی بیمه"
            sx={{ width: 200 }}
            size="small"
            value={orderById.certificateCode || insuranceCertificateCode}
            onChange={({ target: { value } }) =>
              setInsuranceCertificateCode(value)
            }
          />
          {!orderById.certificateCode && (
            <>
              <Dropzone onChange={setNationalCardImage} />
              <Button
                variant="contained"
                sx={{ width: 200 }}
                onClick={submitClickHandler}
              >
                تایید
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </>
  );
};
