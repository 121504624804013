import { ColumnModes, ColumnType } from "src/types/genericTable.types";
import { DeleteSupportTopicDialog } from "src/components/molecule/dialogs/DeleteSupportTopicDialog";
import SupportSubject from "src/components/molecule/dialogs/SupportSubject";
import { ActionButtonModes } from "src/types/actionButton.types";
import { ActionModes } from "src/types/actionItems.types";
import {
  Edit as EditIcon,
  DeleteForever as DeleteForeverIcon,
} from "@mui/icons-material";
import { GetApiSupportSubjectListApiResponse } from "src/app/services/api.generated";
import theme from "src/configs/theme/theme";

export const supportSubjectsColumns: ColumnType<
  GetApiSupportSubjectListApiResponse[number]
>[] = [
  {
    type: ColumnModes.DATA,
    id: "id",
    label: "کد",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "businessUnit",
    label: "واحد سازمانی",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.DATA,
    id: "name",
    label: "عنوان",
    chip: false,
    color: "",
  },
  {
    type: ColumnModes.ACTION,
    label: "عملیات",
    actions: [
      {
        icon: EditIcon,
        type: ActionButtonModes.SINGLE,
        action: {
          type: ActionModes.DIALOG,
          props: ["id", "name", "businessUnitId"],
          dialogComponent: SupportSubject,
        },
      },
      {
        icon: DeleteForeverIcon,
        type: ActionButtonModes.SINGLE,
        color: theme.palette.error.main,
        action: {
          type: ActionModes.DIALOG,
          props: ["id"],
          dialogComponent: DeleteSupportTopicDialog,
        },
      },
    ],
  },
];
