import type { FC, MouseEventHandler } from "react";
import { Fragment } from "react";
import { Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import {
  useGetApiAgeRangeListQuery,
  useGetApiBaseInsurerListQuery,
  useGetApiInsuranceDurationsListQuery,
  useGetApiOrderGetByOrderIdQuery,
  useGetApiOrderGetOrderDetailByOrderIdQuery,
} from "src/app/services/api.generated";
import { priceToPersian } from "src/utils/priceToPersian";
import { GetApiOrderGetOrderDetailByOrderIdApiResponse_type5 } from "src/app/services/responses.type";
import { box2 } from "src/pages/OrderDetails";
import Loading from "src/components/atoms/Loading";
import { useLazyGetApiOrderDownloadOrderAttachmentByAttachmentIdQuery } from "src/app/services/api";
import { FilePresent as FilePresentIcon } from "@mui/icons-material";
import { downloadType } from "src/components/atoms/ActionItem";

const box3 = ({
  name,
  onClick,
}: {
  name: string;
  onClick: MouseEventHandler<SVGSVGElement>;
}) => (
  <Grid item xs={12} md={6} xl={4} sx={{ p: 1 }}>
    <Stack direction="row" alignItems="center" columnGap={1}>
      <Typography sx={{ color: "rgba(0,0,0,.6)" }}>{name}: </Typography>
      <FilePresentIcon
        color="info"
        onClick={onClick}
        sx={{ cursor: "pointer" }}
      />
    </Stack>
  </Grid>
);

type SupplementaryHealthInsuranceOrderDetailsPropsType = {
  orderId: number;
};

export const SupplementaryHealthInsuranceOrderDetails: FC<
  SupplementaryHealthInsuranceOrderDetailsPropsType
> = ({ orderId }) => {
  const { data: orderById, isLoading: orderByIdLoading } =
    useGetApiOrderGetByOrderIdQuery({
      orderId: orderId!,
    });

  const { data: orderDetails, isLoading: orderDetailsLoading } =
    useGetApiOrderGetOrderDetailByOrderIdQuery({
      orderId: orderId!,
    });

  const {
    data: insuranceDurationsList,
    isLoading: insuranceDurationsListLoading,
  } = useGetApiInsuranceDurationsListQuery();

  const { data: ageRangeList, isLoading: ageRangeListLoading } =
    useGetApiAgeRangeListQuery();

  const { data: baseInsurerList, isLoading: baseInsurerListLoading } =
    useGetApiBaseInsurerListQuery();

  const [getFile, { isLoading }] =
    useLazyGetApiOrderDownloadOrderAttachmentByAttachmentIdQuery();

  const downloadFile = ({ file, extension }: downloadType) => {
    const linkSource = `data:application/${extension};base64,${file}`;
    const downloadLink = document.createElement("a");
    const fileName = `myCertificate.${extension}`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadDocHandler = (number?: number | null) => {
    if (!number) return;
    getFile({ attachmentId: number })
      .unwrap()
      .then((res) => {
        if (res && res.file) {
          downloadFile(res as downloadType);
        }
      });
  };

  const orderDetailsEnhance = orderDetails as
    | GetApiOrderGetOrderDetailByOrderIdApiResponse_type5
    | undefined;

  const insurancePolicyDetailsSection = [
    {
      name: "خانوادگی",
      value: orderDetailsEnhance?.isFamily ? "بله" : "خیر",
    },
    {
      name: "تعداد نفرات",
      value: orderDetailsEnhance?.personCounts,
    },
    {
      name: "مدت زمان بیمه",
      value:
        insuranceDurationsList &&
        insuranceDurationsList.find(
          ({ id }) => id === orderDetailsEnhance?.insuranceDurationId
        )?.text,
    },
  ];

  return (
    <>
      {(orderByIdLoading ||
        orderDetailsLoading ||
        insuranceDurationsListLoading ||
        ageRangeListLoading ||
        baseInsurerListLoading ||
        isLoading) && <Loading />}
      <Stack rowGap={5} sx={{ p: { xs: 2, sm: 4 } }}>
        <Typography variant="h6" align="center" fontWeight={600}>
          مشخصات بیمه نامه
        </Typography>
        <Grid container rowGap={{ xs: 0, sx: 2 }}>
          {insurancePolicyDetailsSection.map(({ name, value }, index) => (
            <Fragment key={index}>{box2({ name, value })}</Fragment>
          ))}
        </Grid>
        <Stack rowGap={2}>
          {orderDetailsEnhance?.personsInfo?.map(
            ({
              address,
              arrangementNumber,
              baseHealthInsuranceCard,
              birthCertificateChildrenPage,
              birthCertificateFirstPage,
              identityCard,
              name,
              orderHealthAgeRangeId,
              orderHealthBaseInsurerId,
              phoneNumber,
            }) => (
              <Paper key={arrangementNumber}>
                <Grid container rowGap={{ xs: 0, sx: 2 }} sx={{ p: 1 }}>
                  <Grid item xs={12}>
                    <Typography
                      fontWeight={600}
                      fontSize={17}
                      sx={{ mb: 1 }}
                      align="center"
                    >
                      مشخصات فرد شماره {arrangementNumber}
                    </Typography>
                  </Grid>
                  {box2({
                    name: "نام و نام خانوادگی",
                    value: name,
                  })}
                  {box2({
                    name: "آدرس",
                    value: address,
                  })}
                  {box2({
                    name: "شماره تلفن",
                    value: phoneNumber,
                  })}
                  {box2({
                    name: "بازه سنی",
                    value:
                      (ageRangeList &&
                        ageRangeList.find(
                          (range) => range.id === orderHealthAgeRangeId
                        )?.text) ||
                      "-",
                  })}
                  {box2({
                    name: "بیمه گر پایه",
                    value:
                      (baseInsurerList &&
                        baseInsurerList.find(
                          (range) => range.id === orderHealthBaseInsurerId
                        )?.name) ||
                      "",
                  })}
                  {box3({
                    name: "تصویر دفترچه بیمه پایه",
                    onClick: () => downloadDocHandler(baseHealthInsuranceCard),
                  })}
                  {box3({
                    name: "تصویر کارت ملی",
                    onClick: () => downloadDocHandler(identityCard),
                  })}
                  {box3({
                    name: "تصویر صفحه اول شناسنامه",
                    onClick: () =>
                      downloadDocHandler(birthCertificateFirstPage),
                  })}
                  {box3({
                    name: "تصویر صفحه اول شناسنامه فرزند",
                    onClick: () =>
                      downloadDocHandler(birthCertificateChildrenPage),
                  })}
                </Grid>
              </Paper>
            )
          )}
        </Stack>
        <Divider />
        <Typography variant="h6" align="center" fontWeight={600}>
          مشخصات کالای بیمه شده
        </Typography>
        <Grid container rowGap={{ xs: 0, sx: 2 }}>
          {box2({
            name: "ارزش کالای بیمه شده",
            value: orderById && priceToPersian(orderById.netPrice),
          })}
        </Grid>
        <Divider />
        <Typography variant="h6" align="center" fontWeight={600}>
          پرداخت
        </Typography>
        <Grid container rowGap={{ xs: 0, sx: 2 }}>
          {box2({
            name: "جمع کل",
            value: orderById && priceToPersian(orderById.totalPrice),
          })}
          {box2({
            name: "تخفیف",
            value: orderById && priceToPersian(orderById.discount),
          })}
          {box2({
            name: "مالیات بر ارزش افزوده",
            value: orderById && priceToPersian(orderById.vat),
          })}
          {box2({
            name: "مبلغ قابل پرداخت",
            value: orderById && priceToPersian(orderById.orderPrice),
          })}
        </Grid>
      </Stack>
    </>
  );
};
