import type { FC, MouseEvent } from "react";
import { useState } from "react";
import { NotificationsNone as NotificationsNoneIcon } from "@mui/icons-material";
import {
  Badge,
  Button,
  Divider,
  IconButton,
  Popover,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useGetApiNotificationShortListQuery } from "src/app/services/api.generated";

type NotificationMenuPropsType = {};

const NotificationMenu: FC<NotificationMenuPropsType> = () => {
  const { data, isLoading } = useGetApiNotificationShortListQuery();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const navigate = useNavigate();

  const showAllClickHandler = () => {
    navigate("/notification");
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Badge
        color='secondary'
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        overlap='circular'
        badgeContent=' '
        variant='dot'
        onClick={handleClick}
        invisible={true}>
        <Tooltip title='اعلان‌ها' arrow>
          <IconButton color='inherit'>
            <NotificationsNoneIcon />
          </IconButton>
        </Tooltip>
      </Badge>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: { p: 2 },
        }}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'>
          <Typography variant='caption'>۵ اعلان اخیر</Typography>
          <Button onClick={showAllClickHandler}>نمایش تمام اعلان‌ها</Button>
        </Stack>
        <Divider
          sx={{
            mb: 2,
            width: ({ spacing }) => `calc(100% + ${spacing(4)})`,
            mx: -2,
          }}
        />
        <Stack rowGap={1} divider={<Divider flexItem />}>
          {(data || []).map(({ content }, index) => (
            <Typography variant='caption' align='center' key={index}>
              {isLoading ? <Skeleton /> : content || ""}
            </Typography>
          ))}
        </Stack>
      </Popover>
    </>
  );
};

export default NotificationMenu;
