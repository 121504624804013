import { FC, useEffect, useState } from "react";
import { useGetApiSupportListBySupportStatusIdAndUserIdQuery } from "src/app/services/api.generated";
import TableSkeletonRow from "src/components/molecule/TableSkeletonRow";
import DataTable from "src/components/organization/DataTable";
import { supportsColumns } from "src/sampleData/supports";
import { useGetApiUserShortListQuery } from "src/app/services/api.generated";
import { Autocomplete, Button, Stack, TextField } from "@mui/material";
import BreadCrumbs from "../components/organization/layout/breadcrumbs/BreadCrumbs";
import { Add } from "@mui/icons-material";
import { AddTicketDialog } from "src/components/molecule/dialogs/AddTicketDialog";
import { nameIdType } from "src/types/general.types";

const statusLists = [
  { id: "0", name: "همه" },
  { id: "1", name: "ارسال شده" },
  { id: "2", name: "پاسخ داده شده" },
  { id: "3", name: "بسته شده" },
];

const Support: FC = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<nameIdType>({
    name: "همه",
    id: "0",
  });
  const [selectedUser, setSelectedUser] = useState<nameIdType>({
    name: "همه",
    id: "00000000-0000-0000-0000-000000000000",
  });

  const handleReset = () => {
    setSelectedStatus({
      name: "همه",
      id: "0",
    });
    setSelectedUser({
      name: "همه",
      id: "00000000-0000-0000-0000-000000000000",
    });
  };

  const { data, isLoading, refetch } =
    useGetApiSupportListBySupportStatusIdAndUserIdQuery({
      supportStatusId: Number(selectedStatus.id),
      userId: selectedUser.id,
    });

  useEffect(() => {
    !isLoading && refetch();
  }, [isLoading, refetch]);

  const { data: userList } = useGetApiUserShortListQuery();

  const SupportSearchBar = (
    <Stack
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-around"
      alignItems="center"
      gap={1}
      sx={{ p: 2 }}
    >
      {userList && (
        <Autocomplete
          disableClearable
          fullWidth
          size="small"
          disablePortal
          value={selectedUser}
          getOptionLabel={(option: nameIdType) => option.name}
          options={[
            { name: "همه", id: "00000000-0000-0000-0000-000000000000" },
            ...userList?.map((user) => user as nameIdType),
          ]}
          onChange={(_, value: nameIdType | null) =>
            setSelectedUser((prevState) => (value ? value : prevState))
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField {...params} label="کاربر" />}
        />
      )}
      <Autocomplete
        disableClearable
        fullWidth
        size="small"
        disablePortal
        value={selectedStatus}
        getOptionLabel={(option: nameIdType) => option.name}
        options={statusLists}
        onChange={(_, value: nameIdType | null) =>
          setSelectedStatus((prevState) => (value ? value : prevState))
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} label="وضعیت" />}
      />
      <Stack direction="row" sx={{ width: "100%" }} justifyContent="end">
        <Button
          variant="contained"
          onClick={handleReset}
          sx={{ width: { xs: "100%", sm: 200 }, fontSize: 16 }}
        >
          ریست
        </Button>
      </Stack>
    </Stack>
  );

  return (
    <>
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <BreadCrumbs spaceBottom={false} />
        <Button
          variant="contained"
          onClick={() => setShowDialog(true)}
          startIcon={<Add />}
        >
          ثبت تیکت
        </Button>
      </Stack>
      <br />
      {isLoading ? (
        <TableSkeletonRow title="ثبت تیکت" cellCount={7} />
      ) : (
        <DataTable
          searchBar={SupportSearchBar}
          rows={data || []}
          columns={supportsColumns}
          title="ثبت تیکت"
        />
      )}
      {showDialog && (
        <AddTicketDialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          maxWidth="sm"
          PaperProps={{
            sx: { width: "100%" },
          }}
        />
      )}
    </>
  );
};

export default Support;
