import type { FC } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { Paper, Stack } from "@mui/material";
import { useAppSelector } from "src/app/reduxCustomHooks";
import SignIn from "src/components/organization/auth/SignIn";
import LoginTemplate from "src/components/template/LoginTemplate";

const Login: FC = () => {
  const navigate = useNavigate();

  const auth = useAppSelector(({ auth }) => auth);

  useEffect(() => {
    if (auth?.accessToken) {
      navigate("/");
  } 
  }, [auth?.accessToken, navigate]);

  return (
    <LoginTemplate>
      <Stack
        direction='row'
        justifyContent='space-evenly'
        alignItems='center'
        sx={{
          width: { xs: "100%", sm: "auto" },
          height: { xs: "100%", sm: "auto" },
        }}>
        <Paper
          elevation={10}
          sx={{
            minWidth: { xs: "100%", sm: "450px" },
            minHeight: { xs: "100%", sm: "auto" },
            borderRadius: {
              xs: 0,
              sm: 0.8,
            },
            textAlign: "center",
            p: 4,
          }}>
          <SignIn />
        </Paper>
      </Stack>
    </LoginTemplate>
  );
};

export default Login;
