import { FC, useEffect, useMemo, useState } from "react";
import { Box, Dialog, DialogTitle, DialogContent } from "@mui/material";
import GenericForm from "../../organization/GenericForm";
import fields from "src/sampleData/dialogs/EditUserInformation";
import { FormButtonType } from "src/types/genericFrom.types";
import { BaseDialogType } from "src/types/dialog.types";
import {
  usePutApiUserEditMutation,
  useGetApiUserGetByIdQuery,
  useGetApiRoleListQuery,
  useGetApiInsuranceCompanyListQuery,
} from "src/app/services/api.generated";
import { toast } from "react-toastify";
import { listItemsEnhancer } from "src/utils/ListItemsEnhancer";

type EditUserInformationPropsType = {
  id: string;
};

const EditUserInformation: FC<
  EditUserInformationPropsType & BaseDialogType
> = ({ id, open, handleClose }) => {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    nationalId: "",
    roleId: "",
    insuranceCompanyId: "",
    allowedIp: "",
  });

  const [editUserInformation, { isLoading }] = usePutApiUserEditMutation();

  const { data: userInformation } = useGetApiUserGetByIdQuery({
    id,
  });

  const { data: roleList } = useGetApiRoleListQuery();

  const { data: insuranceCompanyList } = useGetApiInsuranceCompanyListQuery();

  useEffect(() => {
    if (userInformation) {
      const newState: any = {};
      fields.forEach((field) => {
        newState[field.name] =
          (userInformation as any)[field.name]?.toString() || "";
      });
      setState(newState);
    }
  }, [userInformation]);

  const onSubmit = () => {
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      nationalId,
      roleId,
      allowedIp,
    } = state;

    editUserInformation({
      editUserModel: {
        id,
        firstName,
        lastName,
        phoneNumber,
        email,
        nationalId,
        roleId: Number(roleId),
        allowedIp,
      },
    })
      .unwrap()
      .then(() => {
        toast.success("اطلاعات کاربر با موفقیت به‌روز شد.");
        handleClose();
      })
  };

  const buttons: FormButtonType[] = [
    {
      onClick: handleClose,
      text: "انصراف",
      variant: "outlined",
      color: "primary",
      checkRequiredField: false,
    },
    {
      onClick: onSubmit,
      text: "ذخیره",
      variant: "contained",
      color: "primary",
      checkRequiredField: true,
      isLoading: isLoading,
    },
  ];

  const enhancedFields = useMemo(() => {
    let result = [...fields];
    result.forEach((item) => {
      if (item.name === "roleId" && roleList) {
        item.props.items = listItemsEnhancer(roleList);
      }
      if (item.name === "insuranceCompanyId" && insuranceCompanyList) {
        item.props.items = listItemsEnhancer(insuranceCompanyList);
      }
    });
    if ((userInformation as any)?.roleId !== 4) {
      result = result.filter((item) => item.name !== "insuranceCompanyId");
    }
    return result;
  }, [insuranceCompanyList, roleList, userInformation]);

  return (
    <Dialog
      maxWidth="md"
      PaperProps={{
        sx: { width: "100%" },
      }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>اطلاعات کاربران</DialogTitle>
      <DialogContent>
        <Box sx={{ paddingTop: 1 }}>
          <GenericForm
            state={state}
            setState={setState}
            fields={enhancedFields}
            buttons={buttons}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditUserInformation;
