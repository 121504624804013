import type { FC } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Stack, TextField } from "@mui/material";

type SearchDropdownPropsType<ItemType> = {
  items: ItemType[];
  onSelect: (id: string) => void;
  selectedItemId: boolean | number | string | null | undefined;
  label: string;
};

const SearchDropdown: FC<SearchDropdownPropsType<any>> = ({
  items,
  onSelect,
  selectedItemId,
  label,
}) => {
  const handleChange = ({ target: { value } }: any) => onSelect(value);

  return (
    <Stack direction="row" sx={{ width: "100%" }}>
      <FormControl sx={{ m: 2, width: "100%" }} size="small">
        <InputLabel id="demo-select-small"></InputLabel>
        <TextField
          select
          id="demo-select-small"
          label={label}
          value={selectedItemId ? selectedItemId : "any"}
          onChange={handleChange}
          sx={{ textAlign: "center" }}
          size="small"
        >
          <MenuItem value={"any"}>همه</MenuItem>
          {items.map((item, index) => (
            <MenuItem
              key={index}
              selected={item.id === selectedItemId ? true : false}
              value={item.id}
            >
              {item.value}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </Stack>
  );
};

export default SearchDropdown;
